import React from "react";
import "./WeirdSection.css";
import "../elements/TitlePlusButton";

import TitlePlusButton from "../elements/TitlePlusButton";

const TitleSection = ({
	children,
	id,
	noHeight100 = false,
	title,
	titleColorClass,
	titleH3 = false,
	smallTitle = false,
	smallTitleColorClass = false,
	bgColorClass = "",
	customContent = false,
	hasButton = false,
	buttonLink,
	buttonText,
	buttonTextClass = "",
	buttonBgClass = "",
	buttonBgHoverClass = "",
	button1Click = "",
	hasButton2 = false,
	button2Link,
	button2Text,
	button2TextClass = "",
	button2BgClass = "",
	button2BgHoverClass = "",
	button2Click = "",
	aos_settings,
	twoColumns = false,
	noMarginTop = false,
	noMarginBottom = false,
	noPaddingRightCol = false,
	isReverse = false,
	noAOSRight = false,
	titleMarginTop = true,
}) => {
	const twoColumnsHtml = () => {
		return (
			<div
				className={`row ${bgColorClass}`}
				style={{
					margin: "0",
					paddingTop: noMarginTop ? "0" : "15vh",
					paddingBottom: noMarginBottom ? "0" : "15vh",
				}}
			>
				<div
					className={`col-12 col-md-6 pr-md-page pl-page 
					${titleMarginTop ? "mt-xl-5 pt-xl-5" : ""}`}
				>
					{title ? (
						<h1
							className={`${titleColorClass}`}
							data-splitting
							{...aos_settings}
						>
							{title}
						</h1>
					) : (
						""
					)}

					{smallTitle ? (
						<h3
							className={`${smallTitleColorClass}`}
							style={{ maxWidth: "26ch" }}
							{...aos_settings}
						>
							{smallTitle}
						</h3>
					) : (
						""
					)}
					{hasButton ? (
						<button
							className={`btn btn-briskfil small ${buttonTextClass} ${buttonBgClass} mr mb-5`}
							onClick={button1Click}
							{...aos_settings}
						>
							<div
								className={`btn-briskfil-background-effect ${buttonBgHoverClass}`}
							></div>
							<span>{buttonText}</span>
						</button>
					) : (
						""
					)}

					{hasButton2 ? (
						<button
							className={`btn btn-briskfil small ${button2TextClass} ${button2BgClass} mb-5`}
							onClick={button2Click}
							{...aos_settings}
						>
							<div
								className={`btn-briskfil-background-effect ${button2BgHoverClass}`}
							></div>
							<span>{button2Text}</span>
						</button>
					) : (
						""
					)}

					{customContent ? (
						<div className="" style={{ maxWidth: "55ch" }}>
							{customContent}
						</div>
					) : (
						""
					)}
				</div>
				<div
					className={`col-12 col-md-5 ${
						noPaddingRightCol
							? "col-md-6 p-0 m-0"
							: "pl-md-page pr-page"
					}`}
					{...(noAOSRight ? {} : aos_settings)}
				>
					{children}
				</div>
			</div>
		);
	};

	const twoColumnsReverseHtml = () => {
		return (
			<div
				className="row "
				style={{
					margin: "0",
					marginTop: noMarginTop ? "0" : "15vh",
					marginBottom: noMarginBottom ? "0" : "15vh",
				}}
			>
				<div
					className={`col-12 col-md-5 ${
						noPaddingRightCol
							? "col-md-6 p-0 m-0"
							: "pl-md-page pr-page"
					}`}
					{...(noAOSRight ? {} : aos_settings)}
				>
					{children}
				</div>

				<div className="col-12 col-md-6 pl-page pr-page pt-5 ">
					{title ? (
						<h1
							className={`${titleColorClass}`}
							data-splitting
							{...aos_settings}
						>
							{title}
						</h1>
					) : (
						""
					)}

					{smallTitle ? (
						<h3
							className={`${smallTitleColorClass}`}
							style={{ maxWidth: "26ch" }}
							{...aos_settings}
						>
							{smallTitle}
						</h3>
					) : (
						""
					)}
					{hasButton ? (
						<button
							className={`btn btn-briskfil small ${buttonTextClass} ${buttonBgClass} mr mb-5`}
							{...aos_settings}
						>
							<div
								className={`btn-briskfil-background-effect ${buttonBgHoverClass}`}
							></div>
							<span>{buttonText}</span>
						</button>
					) : (
						""
					)}

					{hasButton2 ? (
						<button
							className={`btn btn-briskfil small ${button2TextClass} ${button2BgClass} mb-5`}
							{...aos_settings}
						>
							<div
								className={`btn-briskfil-background-effect ${button2BgHoverClass}`}
							></div>
							<span>{button2Text}</span>
						</button>
					) : (
						""
					)}

					{customContent ? (
						<div className="" style={{ maxWidth: "55ch" }}>
							{customContent}
						</div>
					) : (
						""
					)}
				</div>
			</div>
		);
	};

	return twoColumns ? (
		isReverse ? (
			twoColumnsReverseHtml()
		) : (
			twoColumnsHtml()
		)
	) : (
		<React.Fragment>
			<div
				className={`row pt-4 pt-md-5 pr-3 pr-md-0 pl-3 pl-md-5 pb-4 pb-md-5 ${
					noHeight100 ? "" : "h-100"
				}`}
				id={id}
			>
				<div className="col-12 pt-4 pt-md-5 pb-4 pb-md-5">
					<TitlePlusButton
						title={title}
						titleColorClass={titleColorClass}
						titleH3={titleH3}
						buttonLink={buttonLink}
						buttonText={buttonText}
						isDownload={true}
						smallTitle={smallTitle}
						smallTitleColorClass={smallTitleColorClass}
						customContent={customContent}
						aos_settings={aos_settings}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

export default TitleSection;
