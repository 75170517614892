import React, { useState } from "react";
import "./Contactos.css";

// import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";

// import Files from "react-files";
// import GoogleMapReact from "google-map-react";

const Contactos = () => {
	const [status, setStatus] = useState("Enviar");
	// const [files, setFiles] = useState([]);
	// const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const readUploadedFileAsBase64 = (inputFile) => {
		const temporaryFileReader = new FileReader();

		return new Promise((resolve, reject) => {
			temporaryFileReader.onerror = () => {
				temporaryFileReader.abort();
				reject(new DOMException("Problem parsing input file."));
			};

			temporaryFileReader.onload = () => {
				const base64String = temporaryFileReader.result;
				// .replace("data:", "")
				// .replace(/^.+,/, "");
				resolve(base64String);
			};
			temporaryFileReader.readAsDataURL(inputFile);
		});
	};

	const onSubmit = async (data) => {
		setStatus("A enviar...");
		// Check for the various File API support.
		if (
			window.File &&
			window.FileReader &&
			window.FileList &&
			window.Blob
		) {
			// Great success! All the File APIs are supported.
		} else {
			alert("The File APIs are not fully supported in this browser.");
		}

		// var files = data.files; // FileList object
		// var filesArray = [];

		// // Loop through the FileList and render image files as thumbnails.
		// for (var i = 0, f; (f = files[i]); i++) {
		// 	// Only process image files.
		// 	if (!f.type.match("image.*")) {
		// 		continue;
		// 	}

		// 	var reader = new FileReader();

		// 	// Closure to capture the file information.
		// 	reader.onload = (function (theFile) {
		// 		return function (e) {
		// 			// Render thumbnail.
		// 			var span = document.createElement("span");
		// 			span.innerHTML = [
		// 				'<img class="thumb" src="',
		// 				e.target.result,
		// 				'" title="',
		// 				escape(theFile.name),
		// 				'"/>',
		// 			].join("");
		// 			// document.getElementById("list").insertBefore(span, null);
		// 			filesArray.push({
		// 				name: theFile.name,
		// 				content: e.target.result,
		// 			});
		// 		};
		// 	})(f);

		// 	// Read in the image file as a data URL.
		// 	reader.readAsDataURL(f);
		// 	console.log(filesArray, f);
		// }

		var formDataFiles = new FormData();
		Object.keys(data.files).forEach((key) => {
			const file = data.files[key];
			formDataFiles.append(
				key,
				new Blob([file], { type: file.type }),
				file.name || "file"
			);
		});

		var files = [];

		// get a reference to the file

		// encode the file using the FileReader API
		for (var i = 0; i < data.files.length; i++) {
			let file = {
				lastMod: data.files[i].lastModified,
				lastModDate: data.files[i].lastModifiedDate,
				name: data.files[i].name,
				size: data.files[i].size,
				type: data.files[i].type,
				path: URL.createObjectURL(data.files[i]),
				content: "",
				enconding: "base64",
			};
			try {
				const fileContents = await readUploadedFileAsBase64(
					data.files[i]
				);
				console.log(fileContents);
				file.content = fileContents;
			} catch (e) {
				console.warn(e.message);
			}
			files.push(file);
		}

		console.log(data.files, files, formDataFiles);

		let details = {
			name: data.name,
			email: data.email,
			subject: data.subject,
			message: data.message,
			files: files,
		};
		let response = await fetch("https://briskfil.com/api/contact", {
			method: "POST",
			headers: {
				"Content-Type": "application/json;charset=utf-8",
				// "Content-Type": "multipart/form-data",
			},
			body: JSON.stringify(details),
		});
		setStatus("Enviar");
		let result = await response.json();
		alert(result.status);
	};

	// const onFilesChange = (inputFiles) => {
	// 	console.log(files);
	// 	setFiles(inputFiles);
	// 	console.log(files);
	// };

	// const onFilesError = (error, file) => {
	// 	console.log("error code " + error.code + ": " + error.message);
	// };

	// const filesRemoveAll = () => {
	// 	this.refs.files.removeFiles();
	// };

	// const filesRemoveOne = (file) => {
	// 	this.refs.files.removeFile(file);
	// };

	return (
		<div className="ContactForm">
			<div className="row">
				<div className="col-12 text-right">
					<div className="contactForm">
						<form
							id="contact-form"
							onSubmit={handleSubmit(onSubmit)}
							encType="multipart/form-data"
							noValidate
						>
							{/* Row 1 of form */}
							<div className="row formRow">
								<div className="col">
									<label htmlFor="name">Nome</label>
									<input
										type="text"
										name="name"
										{...register("name", {
											required: {
												value: true,
												message:
													"Please enter your name",
											},
											maxLength: {
												value: 30,
												message:
													"Please use 30 characters or less",
											},
										})}
										className="form-control formInput"
										placeholder="Name"
									></input>
									{errors.name && (
										<span className="errorMessage">
											{errors.name.message}
										</span>
									)}
								</div>
							</div>
							<div className="row formRow">
								<div className="col">
									<label htmlFor="email">Email</label>
									<input
										type="email"
										name="email"
										{...register("email", {
											required: true,
											pattern:
												/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
										})}
										className="form-control formInput"
										placeholder="Email address"
									></input>
									{errors.email && (
										<span className="errorMessage">
											Please enter a valid email address
										</span>
									)}
								</div>
							</div>
							{/* Row 2 of form */}
							<div className="row formRow">
								<div className="col">
									<label htmlFor="subject">Assunto</label>
									<input
										type="text"
										name="subject"
										{...register("subject", {
											required: {
												value: true,
												message:
													"Please enter a subject",
											},
											maxLength: {
												value: 75,
												message:
													"Subject cannot exceed 75 characters",
											},
										})}
										className="form-control formInput"
										placeholder="Subject"
									></input>
									{errors.subject && (
										<span className="errorMessage">
											{errors.subject.message}
										</span>
									)}
								</div>
							</div>
							{/* Row 3 of form */}
							<div className="row formRow">
								<div className="col">
									<label htmlFor="message">Mensagem</label>
									<textarea
										rows={10}
										name="message"
										{...register("message", {
											required: true,
										})}
										className="form-control formInput"
										placeholder="Message"
									></textarea>
									{errors.message && (
										<span className="errorMessage">
											Please enter a message
										</span>
									)}
								</div>
							</div>

							<div className="row formRow">
								<div className="col">
									<label htmlFor="files">Ficheiros</label>
									<input
										type="file"
										name="files"
										multiple
										{...register("files", {
											required: {
												value: false,
												message: "Attach files",
											},
										})}
										className="form-control formInput"
									></input>
									{errors.files && (
										<span className="errorMessage">
											{errors.files.message}
										</span>
									)}
									{/* <div className="files">
										<Files
											className="files-dropzone-list"
											onChange={onFilesChange}
											onError={onFilesError}
											accepts={["image/*", ".pdf"]}
											multiple
											maxFiles={10}
											maxFileSize={10000000}
											minFileSize={0}
											clickable
										>
											{files.length > 0 ? (
												<div className="files-gallery">
													{files.map((file) => (
														<img
															className="files-gallery-item"
															style={{
																maxWidth:
																	"50px",
																height: "auto",
															}}
															src={
																file.preview.url
															}
															key={file.id}
														/>
													))}
												</div>
											) : (
												<div>Drop files here</div>
											)}
										</Files>
										<button onClick={filesRemoveAll}>
											Remover Ficheiros
										</button>
										{files.length > 0 ? (
											<div className="files-list">
												<ul>
													{files.map((file) => (
														<li
															className="files-list-item"
															key={file.id}
														>
															<div className="files-list-item-preview">
																{file.preview
																	.type ===
																"image" ? (
																	<img
																		className="files-list-item-preview-image"
																		style={{
																			maxWidth:
																				"50px",
																			height: "auto",
																		}}
																		src={
																			file
																				.preview
																				.url
																		}
																	/>
																) : (
																	<div className="files-list-item-preview-extension">
																		{
																			file.extension
																		}
																	</div>
																)}
															</div>
															<div className="files-list-item-content">
																<div className="files-list-item-content-item files-list-item-content-item-1">
																	{file.name}
																</div>
																<div className="files-list-item-content-item files-list-item-content-item-2">
																	{
																		file.sizeReadable
																	}
																</div>
															</div>
															<div
																id={file.id}
																className="files-list-item-remove"
																onClick={filesRemoveOne.bind(
																	this,
																	file
																)} // eslint-disable-line
															/>
														</li>
													))}
												</ul>
											</div>
										) : null}
									</div> */}
								</div>
							</div>

							<button
								className="submit-btn btn btn__inner small white"
								type="submit"
							>
								<span className="btn__text">
									<span>{status}</span>
								</span>
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contactos;
