import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
// import { useTranslation } from "react-i18next";

const HeaderItemListItem = ({
	children,
	title,
	description = false,
	disabled = false,
	link,
	onClick,
}) => {
	// const { t } = useTranslation();

	return (
		<div className={`${styles["global-header__item-list-item"]}`}>
			{disabled ? (
				<Link
					className="link"
					to="/"
					target=""
					onClick={(e) => {
						e.preventDefault();
					}}
					style={{ pointerEvents: "none", color: "grey" }}
				>
					<span className="link__text link__text_disabled">
						<span>
							<span>{title}</span>
						</span>
					</span>
					{description ? (
						<span className="link__description">{description}</span>
					) : (
						""
					)}
				</Link>
			) : (
				<Link className="link" to={link} target="" onClick={onClick}>
					<span className="link__text">
						<span>
							<span>{title}</span>
						</span>
					</span>
					{description ? (
						<span className="link__description">{description}</span>
					) : (
						""
					)}
				</Link>
			)}
		</div>
	);
};

export default HeaderItemListItem;
