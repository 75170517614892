import React from "react";
import "./Hero.css";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";
import Footer from "../components/footer/Footer";
// import { ReactComponent as Grafismo } from "../assets/fotos-briskfil/grafismos/analise/analise.svg";
// import { useTranslation } from "react-i18next";

const Privacidade = ({ children, aos_settings, seo }) => {
	// const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas" seo={seo}>
			<Banner
				isVideo={false}
				// bannerImage={bannerImage}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-grey"
				isHalfwidth={true}
				noLogo={true}
				isGrafismo={true}
				grafismoBgColorClass="bg-pink"
			>
				<h1
					data-splitting
					className="text-black pl-page banner-title-home-1"
				>
					Privacidade
				</h1>
			</Banner>
			<div className=" ">
				<div class="mt-5 pt-5 container pl-page pr-page">
					<div class="row ">
						<div class="col-md-12 ">
							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>1. A nossa política</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Na Briskfil Unipessoal Lda. estamos conscientes da
									nossa responsabilidade legal como
									“responsável do tratamento de dados” e
									apoiamos os princípios da proteção de dados
									refletidos no Regulamento (UE) 2016/679 do
									Parlamento Europeu e do Conselho relativo à
									proteção das pessoas singulares no que diz
									respeito ao tratamento de dados pessoais e à
									livre circulação desses dados, aplicável a
									partir de 25 de Maio de 2018.
								</p>

								<p {...aos_settings}>
									A Briskfil, marca registada de Briskfil,
									Unipessoal Lda., garante que os seus dados pessoais são
									tratados em Portugal, mantidos, usados,
									transferidos e tratados de acordo com as
									nossas obrigações legais.
								</p>

								<p {...aos_settings}>
									Esta Política de Privacidade não se aplica a
									sites que o cliente pode aceder através de
									links no site e / ou atividades oferecidas
									por terceiros. Por favor, certifique-se de
									rever quaisquer políticas relevantes em
									sites de terceiros antes de prosseguir. A
									Briskfil Unipessoal Lda. não é responsável pela recolha
									ou uso dos seus dados pessoais nesses sites
									de terceiros. 
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									2. Responsabilidade pelo tratamento
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									A Briskfil, Unipessoal Lda., doravante Briskfil,
									registada na Conservatória do Registo
									Comercial com o número único de matrícula e
									pessoa coletiva PT 514 583 576, e com sede na
									Rua dos Bombeiros 36 4740-291 Esposende -
									Portugal, é a responsável pelo tratamento de
									dados pessoais e, para questões relacionadas
									com proteção e privacidade de dados poderá
									ser contactada através do seguinte email{" "}
									<a href="mailto:geral@briskfil.com">
										geral@briskfil.com
									</a>
									.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									3. Alterações a esta política
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									A Briskfil notificará os seus clientes sobre
									as alterações nesta Política de Privacidade,
									publicando os termos alterados no Site. Se o
									cliente não concordar com os novos termos
									desta Política de Privacidade, deve parar de
									usar o Site, e for cliente registado pode
									cancelar a conta connosco entrando em
									contato connosco. A conta será encerrada e o
									cliente não ficará vinculado aos novos
									termos. Enquanto Cliente também pode pedir
									para excluir as suas informações. Caso
									contrário, o uso continuado do site após a
									data efetiva das alterações constitui
									aceitação dos novos termos desta Política de
									Privacidade. 
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									4. Como armazenamos e protegemos os seus
									dados?
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Os dados que recolhemos são transferidos,
									processados e armazenados pelas seguintes
									entidades: MAILCHIMP.
								</p>
								<p {...aos_settings}>
									Protegemos os seus dados armazenando-os em
									servidores seguros e usando medidas de
									cibersegurança e organizacionais para
									proteger os seus dados pessoais e reduzir o
									risco de perda, uso indevido e acesso não
									autorizado, divulgação e alteração. Algumas
									das salvaguardas que usamos são firewalls e
									criptografia de dados, segurança no acesso
									físico para os nossos centros de dados e
									segurança de acesso a informações. No
									entanto, pedimos que entenda que nenhum
									sistema é perfeito ou pode garantir que o
									acesso não autorizado ou o roubo não
									ocorram. Nos casos em que tenhamos fornecido
									ao nosso cliente uma senha que permita
									aceder áreas restritas do site, o cliente é
									responsável por manter essa senha
									confidencial. Pedimos-lhe que não
									compartilhe a sua senha com ninguém. A
									transmissão de informações pela Internet não
									é completamente segura. Faremos o nosso
									melhor para proteger as suas informações
									pessoais, mas não podemos garantir a
									segurança dos dados transmitidos através do
									site; qualquer transmissão é, portanto, por
									sua conta e risco. Depois de recebermos as
									suas informações pessoais, usaremos
									procedimentos rigorosos e recursos de
									segurança para tentar impedir o acesso não
									autorizado.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									5. Conteúdo de terceiros
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Este Website pode conter links para sites de
									terceiros ("sites vinculados"). Os sites
									vinculados não estão sob o controle da
									Briskfil, não sendo a Briskfil responsável
									pelo conteúdo de qualquer Site Vinculado.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>6. Legislação</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									O tratamento de dados pessoais pela Briskfil
									será realizado em conformidade com o
									Regulamento (UE) 2016/679 do Parlamento
									Europeu e do Conselho relativo à proteção
									das pessoas singulares no que diz respeito
									ao tratamento de dados pessoais e à livre
									circulação desses dados, aplicável a partir
									de 25 de Maio de 2018 conjugado com demais
									legislação portuguesa em vigor. 
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									7. Newsletters de marketing e /ou
									comunicações
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									A Briskfil transmite informações sobre
									produtos, serviços, eventos, vendas e
									marketing (incluindo conteúdo online
									personalizado e publicidade através do site{" "}
									<a href="https://www.briskfil.com">
										www.briskfil.com
									</a>
									, redes sociais ou parceiros on-line). 
								</p>
								<p {...aos_settings}>
									Enviaremos mensagens de marketing quando
									marcar as caixas relevantes ao inscrever-se
									em boletins informativos ou atualizações, ou
									a se registar connosco através do site, por
									telefone ou por email.
								</p>
								<p {...aos_settings}>
									Não partilharemos as suas informações
									pessoais com empresas fora do Grupo Briskfil
									e parceiros de negócios, sem o seu
									consentimento prévio explícito, para que
									elas entrem em contacto para fins de
									marketing.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									8. O que são cookies e por que as usamos
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									A Briskfil utiliza sistemas automáticos de
									recolha de dados como cookies. Uma "cookie"
									é um dispositivo transmitido para o disco
									rígido de um utilizador da Internet. Apesar
									de as cookies não conterem informação
									inteligível, permite-nos vincular um
									utilizador da Internet às suas informações
									pessoais.  
								</p>
								<p {...aos_settings}>
									As cookies são divulgadas pelos nossos
									servidores e ninguém pode ter acesso às
									informações nelas contidas. Apenas a
									Briskfil tem acesso às informações
									recolhidas pelas cookies, de forma coletiva
									e anónima, a fim de otimizar os seus
									serviços e os deste site para as
									necessidades e preferências do utilizador e
									para que a preparação das páginas da Web
									sejam personalizadas.
								</p>
								<p {...aos_settings}>
									A aceitação dos nossos procedimentos
									automáticos de recolha de dados e o uso de
									cookies é necessária para tirar proveito dos
									muitos recursos e serviços oferecidos
									através deste site, incluindo a compra de
									produtos. Se configurar o seu browser para
									bloquear ou apagar cookies, não podemos
									garantir que terá acesso a todos os recursos
									e serviços oferecidos através deste site
									(por exemplo, o computador pode não ser
									capaz de exibir a imagem do produto que está
									a tentar adquirir). 
								</p>
								<p {...aos_settings}>
									Vamos utilizar as cookies para funções como
									a navegação no catálogo, compra de produtos
									on-line e a prestação de serviços reservados
									aos utilizadores registados.
								</p>
								<p {...aos_settings}>
									A Briskfil usa os seguintes tipos de
									cookies: 
								</p>
								<div class="mb-3"></div>
								<ul>
									<li {...aos_settings}>
										COOKIES NECESSÁRIOS: Estes cookies são
										essenciais para que o cliente possa
										navegar pelo site. Sem estes cookies, os
										serviços oferecidos pelo site (como o
										uso do carrinho de compras ou o acesso
										ao histórico de pedidos) não podem ser
										fornecidos. 
									</li>
									<div class="vlt-gap-10"></div>
									<li {...aos_settings}>
										COOKIES ANALÍTICOS: Estes cookies são
										usados para medir e analisar como os
										visitantes usam o Website (por exemplo,
										tráfego do site, vendas totais através
										do site, as páginas visitadas com maior
										frequência e páginas de onde os
										visitantes recebem mensagens de erro)
										para continuar a melhorar o site e as
										experiências de compra oferecidas pelo
										site. Todas as informações recolhidas
										pelos cookies são-no de forma anônima
										(mesmo que passadas a terceiros) e não
										são usadas para identificá-lo.
									</li>
									<div class="vlt-gap-10"></div>
									<li {...aos_settings}>
										COOKIES DE FUNCIONALIDADE: Estes cookies
										não são necessários para o funcionamento
										do Website, mas permitem que o Website
										se lembre das escolhas e / ou das
										preferências (como o seu nome de
										utilizador, idioma ou a sua localização)
										para lhe oferecer algumas informações
										avançadas, recursos simplificados e
										personalizados.
									</li>
									<div class="vlt-gap-10"></div>
									<li {...aos_settings}>
										COOKIES DE TARGETING E PUBLICIDADE: Os
										cookies de segmentação e publicidade
										podem ser usados para fornecer anúncios
										personalizados que sejam mais relevantes
										para o cliente mediante os seus
										interesses. Estes cookies podem recolher
										informações bastante detalhadas sobre os
										seus hábitos de navegação através do
										site, tais como produtos em que o
										cliente clicou ou colocou no seu
										carrinho de compras. Estes cookies
										permitem-nos personalizar qualquer
										comunicação de marketing direto (por
										exemplo, e-mail) ou limitar o número de
										vezes que o Cliente vê um anúncio ou nos
										ajuda a medir a eficácia das nossas
										campanhas de publicidade. Também
										permitem (ou serviços de publicidade de
										terceiros com os quais trabalhamos)
										acompanhar as ações dos clientes on-line
										ao longo do tempo e em diferentes sites
										ou plataformas para medir estatísticas
										de nossos esforços de marketing e
										distribuir anúncios eletrónicos que
										possam ser mais relevantes para os
										consumidores individuais e isso
										melhorará a experiência geral do
										consumidor. 
									</li>
								</ul>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									9. Porque usamos os seus dados?
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									A Briskfil usa os dados pessoais acima
									indicados, sobre si, para os seguintes fins:
								</p>
								<div class="mb-3"></div>
								<ul>
									<li {...aos_settings}>
										para nos ajudar a identificá-lo;
									</li>
									<li {...aos_settings}>
										para nos ajudar a identificá-lo; 
									</li>
									<li {...aos_settings}>
										para gerir e melhorar o site e os
										serviços que fornecemos através do
										site; 
									</li>
									<li {...aos_settings}>
										adequar o conteúdo do nosso site às suas
										necessidades e preferências; 
									</li>
									<li {...aos_settings}>
										gerir a segurança do site e os dados
										recolhidos através do site; 
									</li>
									<li {...aos_settings}>
										fornecer outros serviços solicitados por
										si, conforme descrito quando recolhemos
										os dados; 
									</li>
									<li {...aos_settings}>
										para prevenir, detectar e investigar
										fraudes, violações de segurança,
										violações da lei e outros usos indevidos
										do Site, e para aplicar os nossos Termos
										e Condições Gerais; 
									</li>
									<li {...aos_settings}>
										para resolver quaisquer dúvidas,
										correspondências, preocupações ou
										reclamações que tenha levantado; 
									</li>
									<li {...aos_settings}>
										para operações internas, incluindo
										análise de dados, testes, pesquisa, fins
										estatísticos e solução de problemas; 
									</li>
									<li {...aos_settings}>
										para notificá-lo sobre alterações em
										qualquer elemento do site; 
									</li>
									<li {...aos_settings}>
										fornecer-lhe informações sobre outros
										produtos e serviços que oferecemos
										semelhantes àqueles sobre os quais já
										comprou ou perguntou; 
									</li>
									<li {...aos_settings}>
										avaliar e compreender a eficácia da
										publicidade que fornecemos aos nossos
										clientes; 
									</li>
									<li {...aos_settings}>
										para entregar publicidade relevante, por
										exemplo, newsletter informativa; e 
									</li>
									<li {...aos_settings}>
										fazer sugestões e recomendações sobre
										outros produtos ou serviços que possam
										ser de seu interesse;
									</li>
									<li {...aos_settings}>
										para cumprir leis aplicáveis;
									</li>
									<li {...aos_settings}>
										responder a inquéritos judiciais e
										governamentais;
									</li>
									<li {...aos_settings}>
										estar em conformidade com processos
										legais;
									</li>
									<li {...aos_settings}>
										proteger os direitos ou propriedade da
										Briskfil.
									</li>
								</ul>
								<p {...aos_settings}>
									Também podemos usar os seus dados pessoais
									para decisão de perfis automáticos com o
									objetivo de identificar quais as informações
									ou anúncios que gostaria de ver connosco,
									por exemplo, criando perfis individuais ou
									de grupo. Também podemos compartilhar suas
									informações com os nossos franchisados para
									fins de marketing. Apenas usaremos as
									informações correspondentes para os
									propósitos estabelecidos nesta Política de
									Privacidade. 
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									10. Propriedade intelectual
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Todos conteúdos presentes neste site são
									propriedade exclusiva da Briskfil textos,
									imagens, ou qualquer outro tipo de
									informações que não provenham de terceiros.
									Desta forma estão protegidos pelo Código dos
									Direitos de Autor. A Briskfil reserva-se ao
									direito de, a qualquer momento, introduzir
									alterações nestes conteúdos, não tendo
									obrigação de efetuar qualquer comunicação
									prévia aos utilizadores. 
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									11. Quais as informações pessoais que
									recolhemos e como?
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Os seus dados pessoais são recolhidos e
									tratados pela Briskfil, apenas para fins
									estritamente ligados ao uso deste site.
								</p>
								<p {...aos_settings}>
									1 - Informações que registamos sobre si:
								</p>
								<ul>
									<li {...aos_settings}>
										Se nos pedir para manter contacto;
									</li>
									<li {...aos_settings}>
										Se nos pedir para receber conteúdo
										personalizado, como publicidade
										direcionada;
									</li>
									<li {...aos_settings}>
										Se preencher uma pesquisa ou nos enviar
										feedback sobre os nossos serviços ou
										sites;
									</li>
									<li {...aos_settings}>
										Se entrar em contacto connosco (por
										exemplo, por telefone, e-mail ou outro)
										para saber mais sobre um produto ou
										nossos serviços de atendimento e suporte
										pós-venda, relatar um problema com o
										site ou por qualquer outro motivo.
									</li>
								</ul>
								<p {...aos_settings}>
									As informações pessoais que nos fornecer
									podem incluir seu nome e apelido, endereço
									de e-mail, número de telefone, endereço,
									empresa, ou qualquer outra informação que
									considere necessária fornecer-nos.
								</p>
								<p {...aos_settings}>
									As informações recolhidas através do nosso
									site serão cruzadas com informações pessoais
									que pode ter transmitido. Isso é para nos
									ajudar a garantir que os dados que mantemos
									sobre si sejam mantidos corretos e
									atualizados.
								</p>
								<p {...aos_settings}>
									Os seus dados pessoais podem também ser
									utilizados para outras operações de
									processamento que estejam enquadradas dentro
									dos limites destas utilizações.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									12.Quais são os seus direitos
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									O Cliente tem os seguintes direitos em
									relação às suas informações pessoais: 
								</p>
								<p {...aos_settings}>
									1 - Informações que registamos sobre si:
								</p>
								<ul>
									<li {...aos_settings}>
										Acesso. O Cliente tem o direito a aceder
										aos dados de que dispomos. Vamos
										precisar que o cliente comprove a sua
										identidade antes de informarmos
										quaisquer dados pessoais. 
									</li>
									<li {...aos_settings}>
										Retificação ou eliminação. O Cliente tem
										o direito de solicitar que a Briskfil
										retifique ou exclua quaisquer dados
										pessoais que detenha sobre o cliente (a
										menos que tenhamos o direito legal ou a
										obrigação de retê-lo). 
									</li>
									<li {...aos_settings}>
										Restrição. O Cliente tem o direito de
										restringir o uso de informações pessoais
										se os dados forem imprecisos, se o uso
										das informações for ilegal ou se deixar
										de ser necessário o uso os dados para os
										propósitos para os quais os
										armazenamos. 
									</li>
									<li {...aos_settings}>
										Mudança de preferências. O Cliente pode
										alterar as suas preferências de
										processamento de dados a qualquer
										momento. Por exemplo, se o cliente tiver
										dado o seu consentimento para o
										marketing direto, mas tiver mudado de
										ideia, poderá cancelar o recebimento de
										comunicações de marketing clicando no
										link "Cancelar inscrição" em qualquer
										comunicação recebida. 
									</li>
									<li {...aos_settings}>
										Tomada de decisão automatizada. Se o
										cliente tiver alguma dúvida em relação
										aos nossos processos de tomada de
										decisão automatizada ou a precisão
										deles, deve avisar-nos ou alterar as
										suas preferências. 
									</li>
									<li {...aos_settings}>
										Portabilidade de dados. O Cliente tem o
										direito de obter os dados pessoais que a
										Briskfil detém, em formato eletrónico
										estruturado, e de transmitir tais dados
										para outro controlador de dados, onde
										estão:
										<ul>
											<li {...aos_settings}>
												(a) dados pessoais que o cliente
												nos forneceu; e 
											</li>
											<li {...aos_settings}>
												(b) onde são processados esses
												dados com base (como as
												informações de preferência de
												marketing) ou para executar um
												contrato com o cliente (por
												exemplo, para gerenciar sua
												conta on-line). 
											</li>
										</ul>
									</li>
									<li {...aos_settings}>
										Reclamações. Se por algum motivo o
										Cliente não estiver satisfeito com a
										forma como lidamos com seus dados
										pessoais, pedimos o favor de entrar em
										contato com a Briskfil. 
									</li>
								</ul>

								<p {...aos_settings}>
									Para exercer qualquer um dos direitos
									mencionados acima, por favor envie um email
									para 
									<a href="mailto:geral@briskfil.com">
										geral@briskfil.com
									</a>
									.
								</p>

								<p {...aos_settings}>
									Se o cliente solicitar o cancelamento do
									processamento das suas informações pessoais
									para determinadas ações ou que as suas
									informações pessoais sejam apagadas, sendo
									isso necessário para facilitar o uso do Site
									ou para nos permitir fornecer um serviço
									(como para gerenciar sua conta on-line),
									talvez o Cliente não consiga usar o site ou
									o serviço como antes. Sem prescindir, do seu
									direito enquanto cliente de se opor ao
									marketing direto, que pode ser exercido a
									qualquer momento sem restrição. Por favor,
									aguarde pelo menos 10 dias úteis para o seu
									pedido ser concluído. 
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									13. Quem partilha os seus dados pessoais
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Os seus dados podem ser armazenados numa
									base de dados que nós, assim como os nossos
									franchisados, podemos aceder. Nós somos o
									seu ponto de contacto.
								</p>
								<p {...aos_settings}>
									Também compartilharemos seus dados com a
									MAILCHIMP que fornecem recursos de
									armazenamento de dados para nós.
								</p>
								<p {...aos_settings}>
									Também podemos partilhar os seus dados com
									as seguintes categorias de terceiros
									selecionados de acordo com esta Política de
									Privacidade: 
								</p>
								<ul>
									<li {...aos_settings}>
										Prestadores de serviços (por exemplo,
										serviços de TI), parceiros de negócios,
										fornecedores e subcontratados para a
										execução de qualquer contrato celebrado
										com os nossos clientes (como, mas não
										limitado a, operações do site, serviços
										de pagamento, remessas, investigações de
										fraude, cobrança de contas e programas
										de afiliação e recompensas); 
									</li>
									<li {...aos_settings}>
										Analíticos e provedores de mecanismos de
										busca que nos auxiliam na melhoria e
										otimização do site; 
									</li>
									<li {...aos_settings}>
										Agências de aplicação da lei ou
										reguladoras, ou terceiros autorizados,
										em resposta a uma solicitação verificada
										relacionada a uma investigação criminal
										ou suposta atividade ilegal ou qualquer
										outra atividade através do Site que
										possa expor a Briskfil e / ou o cliente
										ou terceiros a riscos legais ou
										responsabilidade; e 
									</li>
									<li {...aos_settings}>
										Outras entidades de negócios, em caso de
										cisão ou fusão de negócios. Caso tal
										combinação ocorra, exigiremos que a nova
										entidade siga esta Política de
										Privacidade em relação aos seus dados.
										Esses terceiros que podem receber os
										seus dados processarão esses dados para
										os fins especificados acima e de acordo
										com a lei aplicável. A Briskfil manterá
										uma lista dos terceiros com quem os
										dados são partilhados, que estará
										disponível mediante solicitação do
										cliente.
									</li>
								</ul>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									Gostaria de saber mais?
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Questões, comentários e solicitações
									relacionadas com esta Política de
									Privacidade são bem-vindas e devem ser
									encaminhadas, preferencialmente, via e-mail
									para 
									<a href="mailto:geral@briskfil.com">
										geral@briskfil.com
									</a>
									.
								</p>
								<p {...aos_settings}>
									Esta Política de Privacidade foi atualizada
									em 17 de Maio de 2021.
								</p>
							</div>

							<div class="mb-5--sm"></div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</PageWrapper>
	);
};

export default Privacidade;
