import React from "react";
import "./Hero.css";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";
import Footer from "../components/footer/Footer";
import { Link } from "react-router-dom";
// import { ReactComponent as Grafismo } from "../assets/fotos-briskfil/grafismos/analise/analise.svg";
// import { useTranslation } from "react-i18next";

const Termos = ({ children, aos_settings, seo }) => {
	// const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas" seo={seo}>
			<Banner
				isVideo={false}
				// bannerImage={bannerImage}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-grey"
				isHalfwidth={true}
				noLogo={true}
				isGrafismo={true}
				grafismoBgColorClass="bg-pink"
			>
				<h1
					data-splitting
					className="text-black pl-page banner-title-home-1"
				>
					Termos e Condições
				</h1>
			</Banner>
			<div className="">
				<div class="mt-5 pt-5 container">
					<div class="row ">
						<div class="col-md-12 ">
							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>1. Termos</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Ao aceder ao site{" "}
									<a href="https://www.briskfil.com">
										www.briskfil.com
									</a>{" "}
									, concorda em cumprir estes termos de
									serviço, todas as leis e regulamentos
									aplicáveis ​​e concorda que é responsável
									pelo cumprimento de todas as leis locais
									aplicáveis. Os materiais contidos neste site
									são protegidos pelas leis de direitos
									autorais e marcas comerciais aplicáveis.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>2. Uso de Licença</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									É concedida permissão para fazer
									temporariamente uma cópia dos materiais
									(informações ou software) no site{" "}
									<a href="https://www.briskfil.com">
										www.briskfil.com
									</a>
									, apenas para visualização transitória
									pessoal e não comercial. Esta é a concessão
									de uma licença, não uma transferência de
									título e, sob esta licença, você não pode:  
								</p>
								<ul>
									<li {...aos_settings}>
										modificar ou copiar os materiais;{" "}
									</li>
									<li {...aos_settings}>
										usar os materiais para qualquer
										finalidade comercial ou para exibição
										pública (comercial ou não comercial);{" "}
									</li>
									<li {...aos_settings}>
										fazer engenharia reversa de qualquer
										software contido no site Briskfil Unipessoal Lda.;{" "}
									</li>
									<li {...aos_settings}>
										remover quaisquer direitos autorais ou
										outras notações de propriedade dos
										materiais; ou transferir os materiais
										para outra pessoa ou 'espelhe' os
										materiais em qualquer outro servidor.
									</li>
									<li {...aos_settings}>
										Esta licença será automaticamente
										rescindida se violar alguma destas
										restrições e poderá ser rescindida por
										Briskfil Unipessoal Lda. a qualquer momento. Ao
										encerrar a visualização desses materiais
										ou após o término desta licença, deverá
										apagar todos os materiais baixados em
										sua posse, seja em formato eletrónico ou
										impresso.
									</li>
								</ul>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									3. Isenção de responsabilidade
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Os materiais no site da Briskfil Unipessoal Lda. são
									fornecidos 'como estão'. A Briskfil Unipessoal Lda. não
									oferece garantias, expressas ou implícitas,
									e, por este meio, isenta e nega todas as
									outras garantias, incluindo, sem limitação,
									garantias implícitas ou condições de
									comercialização, adequação a um fim
									específico ou não violação de propriedade
									intelectual ou outra violação de direitos.
								</p>
								<p {...aos_settings}>
									Além disso, a Briskfil Unipessoal Lda. não garante ou
									faz qualquer representação relativa à
									precisão, aos resultados prováveis ​​ou à
									confiabilidade do uso dos materiais em seu
									site ou de outra forma relacionado a esses
									materiais ou em sites vinculados a este
									site.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>4. Limitações</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Em nenhum caso a Briskfil Unipessoal Lda. ou seus
									fornecedores serão responsáveis ​​por
									quaisquer danos (incluindo, sem limitação,
									danos por perda de dados ou lucro ou devido
									a interrupção dos negócios) decorrentes do
									uso ou da incapacidade de usar os materiais
									em Briskfil Unipessoal Lda., mesmo que Briskfil Unipessoal Lda. ou
									um representante autorizado da Briskfil Unipessoal Lda.
									tenha sido notificado oralmente ou por
									escrito da possibilidade de tais danos.{" "}
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									5. Precisão dos materiais
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Os materiais exibidos no site da Briskfil
									Unipessoal Lda. podem incluir erros técnicos,
									tipográficos ou fotográficos. A Briskfil
									Unipessoal Lda. não garante que qualquer material em
									seu site seja preciso, completo ou atual. A
									Briskfil Unipessoal Lda. pode fazer alterações nos
									materiais contidos em seu site a qualquer
									momento, sem aviso prévio. No entanto, a
									Briskfil Unipessoal Lda. não se compromete a atualizar
									os materiais.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>6. Links</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									O Briskfil Unipessoal Lda. não analisou todos os sites
									vinculados ao seu site e não é responsável
									pelo conteúdo de nenhum site vinculado. A
									inclusão de qualquer link não implica
									endosso por Briskfil Unipessoal Lda. do site. O uso de
									qualquer site vinculado é por conta e risco
									do usuário.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>
									7. Política de Privacidade e Cookies
								</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									A Briskfil, Unipessoal Lda., utiliza tecnologia de
									pesquisa através de Cookies. A Política no
									uso de cookies está definida na Política de
									Cookies disponibilizada neste website.
									Clique <Link to="/privacidade">aqui</Link>.
									para saber mais.
								</p>
								<p {...aos_settings}>
									Qualquer conteúdo ou dados pessoais que
									sejam enviados para o site{" "}
									<a href="https://www.briskfil.com">
										www.briskfil.com
									</a>{" "}
									estão sujeitos à Política de Privacidade e
									proteção de dados pessoais apresentados na
									Política de Privacidade{" "}
									<Link to="/privacidade">aqui</Link>.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>8. Modificações</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									A Briskfil Unipessoal Lda. pode rever estes termos de
									serviço do site a qualquer momento, sem
									aviso prévio. Ao usar este site, você
									concorda em ficar vinculado à versão atual
									desses termos de serviço.
								</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>9. Lei aplicável</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Estes termos e condições são regidos e
									interpretados de acordo com as leis da
									Briskfil Unipessoal Lda. e você se submete
									irrevogavelmente à jurisdição exclusiva dos
									tribunais em Portugal.
								</p>
							</div>

							<div class="mb-5--sm"></div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</PageWrapper>
	);
};

export default Termos;
