import React, { useRef } from "react";
import { TransitionGroup, Transition } from "react-transition-group";
import { Switch, Route } from "react-router-dom";
import { gsap } from "gsap";
import Header from "../components/header/topMenu/Header";
import Home from "../pages/Home";
import Energia from "../pages/Energia";
import SobreNos from "../pages/SobreNos";

import CookieConsent from "react-cookie-consent";

// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Consultoria from "../pages/Consultoria";
import Analises from "../pages/Analises";
import Investigacao from "../pages/Investigacao";
import Aplicacoes from "../pages/Aplicacoes";
import Termos from "../pages/Termos";
import Privacidade from "../pages/Privacidade";


import shareImage from "../assets/briskfil_low.jpg";
import FichaProjeto from "../pages/FichaProjeto";

//Trying to do lazy loading pages but seems glitchy

// const Home = lazy(() => import("../pages/Home"));
// const Estufas = lazy(() => import("../pages/Estufas"));
// const ConstrucoesMetalicas = lazy(() =>
// 	import("../pages/ConstrucoesMetalicas")
// );
// const Estruturas = lazy(() => import("../pages/Estruturas"));
// const Corte = lazy(() => import("../pages/Corte"));

// const Contactos = lazy(() => import("../pages/Contactos"));
// const Agropecuaria = lazy(() => import("../pages/Agropecuaria"));
// const PerfisMetalicos = lazy(() => import("../pages/PerfisMetalicos"));
// const SobreNos = lazy(() => import("../pages/SobreNos"));

// const getAnchor = () => {
// 	var currentUrl = document.URL,
// 		urlParts = currentUrl.split("#");

// 	return urlParts.length > 1 ? urlParts[1] : null;
// };

const completeCall = (target, parent) => {
	target &&
		gsap.set(target, {
			clearProps: "position, width, transform, top, left",
		});
	parent &&
		gsap.set(parent, {
			clearProps: "transform, overflow, position, min-height",
		});
	target && gsap.set(target, { clearProps: "transform, overflow" });
	// let anchor = getAnchor();
	// if (anchor) {
	// 	var anchor1 = document.getElementById(anchor);
	// 	var posTop = anchor1.offsetTop;

	// 	gsap.to(window, 1, { scrollTo: posTop });
	// }
};

const Routes = (props) => {
	const parentNode = useRef(null);
	// const { t } = useTranslation();
	const [isHeaderWhite, setIsHeaderWhite] = React.useState(false);
	const [isContactActive, setIsContactActive] = React.useState(false);

	const openContact = (e) => {
		e.preventDefault();
		setIsContactActive(true);
	};

	const closeContact = () => {
		setIsContactActive(false);
	};

	React.useEffect(() => {
		if (props.location.pathname !== "/") {
			setIsHeaderWhite(true);
		} else {
			setIsHeaderWhite(false);
		}
		//START
		gsap.set(document.querySelector(".load-icon"), {
			autoAlpha: 0,
		});

		gsap.set(document.querySelector("#loading-icon-top"), {
			rotate: 0,
			translateX: "33%",
			translateY: "75%",
			transformOrigin: "50% 50%",
			// transform: "rotate(0) translate(33%, 75%)",
		});

		gsap.set(document.querySelector("#loading-icon-left"), {
			rotate: 0,
			translateX: "33%",
			translateY: "75%",
			transformOrigin: "50% 50%",
		});

		gsap.set(document.querySelector("#loading-icon-right"), {
			rotate: 0,
			// autoAlpha: 0,
			translateX: "33%",
			translateY: "75%",
			transformOrigin: "50% 50%",
		});
		gsap.set(document.querySelector("#loading-icon-bottom"), {
			rotate: 0,
			// autoAlpha: 0,
			translateX: "33%",
			translateY: "75%",
			transformOrigin: "50% 50%",
		});

		//FIRST MOVEMENT

		gsap.to(document.querySelector(".load-icon"), {
			duration: 0.7,
			delay: 0,
			autoAlpha: 1,
		});

		gsap.to(document.querySelector("#loading-icon-right"), {
			duration: 0.7,
			delay: 1,
			translateX: "66%",
			translateY: "75%",
			ease: "circ.inOut",
		});

		gsap.to(document.querySelector("#loading-icon-left"), {
			duration: 0.7,
			delay: 1,
			translateX: "0%",
			translateY: "75%",
			ease: "circ.inOut",
		});

		gsap.to(document.querySelector("#loading-icon-top"), {
			duration: 0.7,
			delay: 1,
			translateX: "33%",
			translateY: "25%",
			ease: "circ.inOut",
		});

		gsap.to(document.querySelector("#loading-icon-bottom"), {
			duration: 0.7,
			delay: 1,
			translateX: "33%",
			translateY: "125%",
			ease: "circ.inOut",
		});

		//SECOND MOVEMENT

		gsap.to(document.querySelector("#loading-icon-right"), {
			duration: 0.7,
			delay: 2,
			rotation: -90,
			translateX: "83%",
			translateY: "75%",
			ease: "circ.inOut",
		});

		gsap.to(document.querySelector("#loading-icon-left"), {
			duration: 0.7,
			delay: 2,
			rotation: 90,
			translateX: "-17%",
			translateY: "75%",
			ease: "circ.inOut",
		});

		gsap.to(document.querySelector("#loading-icon-top"), {
			duration: 0.7,
			delay: 2,
			rotation: 180,
			translateY: "0%",
			ease: "circ.inOut",
		});

		gsap.to(document.querySelector("#loading-icon-bottom"), {
			duration: 0.7,
			delay: 2,
			translateY: "150%",
			ease: "circ.inOut",
		});

		//THIRD MOVEMENT

		gsap.to(document.querySelector("#loading-icon-top"), {
			duration: 0.7,
			delay: 3,
			autoAlpha: 0,
			translateY: "50%",
			ease: "circ.inOut",
		});

		gsap.to(document.querySelector("#loading-icon-left"), {
			duration: 0.7,
			delay: 3,
			autoAlpha: 0,
			translateX: "17%",
			ease: "circ.inOut",
		});

		gsap.to(document.querySelector("#loading-icon-right"), {
			duration: 0.7,
			delay: 3,
			autoAlpha: 0,
			translateX: "49%",
			ease: "circ.inOut",
		});

		gsap.to(document.querySelector("#loading-icon-bottom"), {
			duration: 0.7,
			delay: 3,
			autoAlpha: 0,
			translateY: "100%",
			ease: "circ.inOut",
		});

		// DISAPPEAR

		gsap.to(document.querySelector("#loading-screen"), {
			duration: 1,
			delay: 4,
			autoAlpha: 0,
		});

		gsap.set(document.querySelector("#loading-screen"), {
			delay: 5,
			zIndex: -99,
		});

		if (window.innerWidth < 1024) {
			gsap.to(document.querySelector("#banner-image-wrapper"), {
				duration: 1.5,
				delay: 4,
				width: "100vw",
				ease: "circ.out",
			});
		} else {
			gsap.to(document.querySelector("#banner-image-wrapper"), {
				duration: 1.5,
				delay: 4,
				width: "50vw",
				ease: "circ.out",
			});
		}

		// titles

		gsap.set(document.querySelector(".banner-title"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-title-home-1"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-title-home-2"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-button-home"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.to(document.querySelector(".banner-title"), {
			duration: 0.5,
			delay: 4,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-title-home-1"), {
			duration: 0.5,
			delay: 4.3,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-title-home-2"), {
			duration: 0.5,
			delay: 4.5,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-button-home"), {
			duration: 0.5,
			delay: 4.5,
			autoAlpha: 1,
			transform: "none",
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onEnterHandler = (node) => {
		if (props.location.pathname !== "/") {
			setIsHeaderWhite(true);
		} else {
			setIsHeaderWhite(false);
		}
		gsap.killTweensOf(node);
		// Set initial position and styles
		gsap.set(node, {
			position: "absolute",
			left: 0,
			top: 0,
			autoAlpha: 0,
		});
		gsap.set(parentNode.current, { overflow: "hidden" });
		// Create the animation for the incoming component

		// gsap.set(node.querySelector(".EM-logo"), {
		// 	autoAlpha: 0,
		// 	transform: "translateY(-20px)",
		// });
		gsap.set(node.querySelector(".banner-title"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-title-home-1"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-title-home-2"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-button-home"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector("#videobutton"), {
			autoAlpha: 0,
			transform: "translateX(10px)",
		});

		gsap.to(node, {
			duration: 0.4,
			autoAlpha: 1,
			x: 0,
			onComplete: completeCall,
			onCompleteParams: [node, parentNode.current],
		});

		// gsap.to(node.querySelector(".EM-logo"), {
		// 	duration: 0.5,
		// 	delay: 0.5,
		// 	autoAlpha: 1,
		// 	transform: "none",
		// });
		gsap.to(node.querySelector(".banner-title"), {
			duration: 0.5,
			delay: 0.7,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-title-home-1"), {
			duration: 0.5,
			delay: 1,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-title-home-2"), {
			duration: 0.5,
			delay: 1.2,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-button-home"), {
			duration: 0.5,
			delay: 1.2,
			autoAlpha: 1,
			transform: "none",
		});

		// gsap.to(document.querySelector("#videobutton"), {
		// 	duration: 1,
		// 	delay: 1.5,
		// 	autoAlpha: 1,
		// 	transform: "none",
		// });

		// new

		if (window.innerWidth < 1024) {
			gsap.to(document.querySelector("#banner-image-wrapper"), {
				duration: 1.5,
				delay: 1,
				width: "100vw",
				ease: "circ.out",
			});
		} else {
			gsap.to(document.querySelector("#banner-image-wrapper"), {
				duration: 1.5,
				delay: 1,
				width: "50vw",
				ease: "circ.out",
			});
		}
	};

	const onExitHandler = (node) => {
		// Set initial position and styles
		// gsap.set(node.querySelector(".EM-logo"), {
		// 	autoAlpha: 1,
		// 	transform: "none",
		// });

		gsap.set(node.querySelector(".banner-title"), {
			autoAlpha: 1,
			transform: "none",
		});

		gsap.set(node.querySelector(".banner-title-home-1"), {
			autoAlpha: 1,
			transform: "none",
		});

		gsap.set(node.querySelector(".banner-title-home-2"), {
			autoAlpha: 1,
			transform: "none",
		});

		// gsap.set(node.querySelector("#videobutton"), {
		// 	autoAlpha: 1,
		// 	transform: "none",
		// });
		// Create the animation for the incoming component
		// gsap.to(node.querySelector(".EM-logo"), {
		// 	duration: 0.4,
		// 	autoAlpha: 0,
		// 	transform: "translateY(-20px)",
		// });

		gsap.to(node.querySelector(".banner-title"), {
			duration: 0.4,
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.to(node.querySelector(".banner-title-home-1"), {
			duration: 0.4,
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.to(node.querySelector(".banner-title-home-2"), {
			duration: 0.4,
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		// gsap.to(node.querySelector("#videobutton"), {
		// 	duration: 0.4,
		// 	autoAlpha: 0,
		// 	transform: "translateY(-30px)",
		// });

		gsap.set(node, {
			position: "absolute",
			left: 0,
			zIndex: -2,
		});

		gsap.to(node, {
			duration: 0.4,
			delay: 0.4,
			autoAlpha: 0,
		});

		gsap.killTweensOf(node);
	};

	const aos_settings = {
		"data-aos": "fade-up",
		"data-aos-duration": "1000",
		"data-aos-delay": "0",
		"data-aos-anchor-placement": "bottom-bottom",
		"data-aos-easing": "ease-out-quad",
	};

	return (
		<React.Fragment>
			<Header
				isHeaderWhite={isHeaderWhite}
				isContactActive={isContactActive}
				openContact={openContact}
				closeContact={closeContact}
			/>
			<div
				id="main-wrapper"
				className="w-100"
				ref={parentNode}
				style={{
					position: "relative",
					minHeight: "100vh",
				}}
			>
				<TransitionGroup component={null}>
					<Transition
						timeout={500}
						key={props.location.pathname}
						onEnter={onEnterHandler}
						onExit={onExitHandler}
					>
						<Switch location={props.location}>
							<Route path="/" exact>
								<Home
									aos_settings={aos_settings}
									openContact={openContact}
									seo={{
										title:"Briskfil - Compressores Ar Comprimido Azoto Oxigénio Painéis Solares",
										description:"Reduzir a pegada de carbono e atingir o carbono neutro é uma missão para a Briskfil. Queremos reduzir custos e emissões de CO2 com o ar comprimido, azoto/nitrogénio e oxigénio.",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>
							<Route path="/energia" exact>
								<Energia
									aos_settings={aos_settings}
									openContact={openContact}
									seo={{
										title:"Consultoria Energética",
										description:"Prestamos consultoria em energia e gás natural. Acompanhamos mercados de energia OMIE OMIP e auxiliamos os clientes na tomada de decisão sobre as melhores opções de compra.",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>

							<Route path="/consultoria" exact>
								<Consultoria
									aos_settings={aos_settings}
									openContact={openContact}
									seo={{
										title:"Consultoria Compressores Secador Adsorção Ar Filtros Ar Comprimido",
										description:"Consultoria é o serviço que disponibilizámos para avaliar o ar comprimido, azoto/oxigénio. Melhorias de qualidade e de eficiência energética são o âmbito da nossa acção. Carbono neutro indústria 4.0",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>

							<Route path="/analises" exact>
								<Analises
									aos_settings={aos_settings}
									openContact={openContact}
									seo={{
										title:"Análises Qualidade Ar Comprimido Azoto oxigénio",
										description:"Microrganismos, germes, ponto de orvalho/água, partículas sólidas, hidrocarbonetos /odores e sabores são contaminantes que podemos encontrar no ar comprimido. Analisamos o ar comprimido.",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>

							<Route path="/investigacao" exact>
								<Investigacao
									aos_settings={aos_settings}
									openContact={openContact}
									seo={{
										title:"Investigação Ar Comprimido Azoto oxigénio Dióxido de Carbono",
										description:"A Briskfil desenvolve investigação sobre gases industriais de produção on-site para contribuir para a redução das emissões de CO2, redução de custos e independência de fornecimento. ",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>

							<Route path="/aplicacoes" exact>
								<Aplicacoes
									aos_settings={aos_settings}
									openContact={openContact}
									seo={{
										title:"Casos de estudo Compressores PSA Azoto Oxigénio",
										description:"Casos de estudo aplicando conhecimento especializado para produção on-site por PSA, membrana ou produção de ar comprimido alimentar, farmacêutico ou medicinal para certificação.",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>

							<Route path="/sobre-nos" exact>
								<SobreNos
									aos_settings={aos_settings}
									openContact={openContact}
									seo={{
										title:"Carbono Neutro Indústria 4.0 Painéis Solares Eficiência Energética",
										description:"Somos especialistas em gases industriais e medicinais, e prestamos consultoria de energia e gás natural. Colocamos ao seu serviço o nosso conhecimento em energia e eficiência energética.",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>

							<Route path="/privacidade" exact>
								<Privacidade aos_settings={aos_settings} 
									seo={{
										title:"Políticas de privacidade - Briskfil",
										description:"Políticas de privacidade sobre o website da Briskfil",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>

							<Route path="/termos-e-condicoes" exact>
								<Termos aos_settings={aos_settings} 
									seo={{
										title:"Termos e condições - Briskfil",
										description:"Termos e condições sobre o website da Briskfil",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>


							<Route path="/ficha-de-projeto" exact>
								<FichaProjeto aos_settings={aos_settings} 
									seo={{
										title:"Ficha de Projeto - Briskfil",
										description:"Ficha de Projeto PRR da Briskfil",
										image_url: "https://www.briskfil.com" + shareImage,
									}}
								/>
							</Route>
						</Switch>
					</Transition>
				</TransitionGroup>
			</div>

			<CookieConsent
				style={{ fontFamily: "acumin-pro, sans-serif" }}
				buttonStyle={{
					fontFamily: "acumin-pro, sans-serif",
					borderRadius: "15px",
					padding: "5px 30px",
					fontSize: "12px",
					backgroundColor: "transparent",
					border: "1px solid white",
					color: "white",
					fontWeight: "700",
					marginTop: "20px",
					marginBottom: "20px",
				}}
				location="bottom"
				buttonText="OK!"
			>
				Este website utiliza cookies.&nbsp;
				<Link to="/privacidade" target="_blank">
					Mais Informação aqui
				</Link>
				.
			</CookieConsent>
		</React.Fragment>
	);
};

export default Routes;
