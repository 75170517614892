import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
// import { useTranslation } from "react-i18next";
import { ReactComponent as ReactLogo } from "../../../assets/images/logo_white.svg";
import HeaderItem from "./HeaderItem";
import HeaderItemListItem from "./HeaderItemListItem";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Contactos from "../../contact/Contactos";

const Header = ({
	isHeaderWhite,
	isContactActive,
	openContact,
	closeContact,
}) => {
	const [isHeaderActive, setIsHeaderActive] = React.useState(false);
	const [isHeaderHidden, setIsHeaderHidden] = React.useState(false);
	const [isBackgroundActive, setIsBackgroundActive] = React.useState(false);
	const [scrollPogress, setScrollPogress] = React.useState(0);
	const [activeItem, setActiveItem] = React.useState(null);
	// const { t } = useTranslation();

	const openHeader = () => {
		setIsHeaderActive(true);
		setIsBackgroundActive(true);
	};

	const closeHeader = () => {
		setIsHeaderActive(false);
		setActiveItem(null);
		if (scrollPogress <= 0) {
			if (window.innerWidth >= 1024) {
				setIsBackgroundActive(false);
			}
		}
	};

	const showHeader = () => {
		setIsHeaderHidden(false);
	};

	const hideHeader = () => {
		setIsHeaderHidden(true);
	};

	// const showBackground = () => {
	// 	setIsBackgroundActive(true);
	// };

	// const hideBackground = () => {
	// 	setIsBackgroundActive(false);
	// };

	// const getIsHeaderHidden = () => {
	// 	return isHeaderHidden;
	// };

	document.querySelector("body").addEventListener("click", (e) => {
		setActiveItem(null);
	});

	const toggleItem = (item) => {
		if (activeItem === item) {
			setActiveItem(null);
		} else {
			setActiveItem(item);
		}
	};

	// SCROLL FIXING ISSUES

	// var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

	// function preventDefault(e) {
	// 	e.preventDefault();
	// }
	// function preventDefaultForScrollKeys(e) {
	// 	if (keys[e.keyCode]) {
	// 		e.preventDefault(e);
	// 		return false;
	// 	}
	// }

	// // modern Chrome requires { passive: false } when adding event
	// var supportsPassive = false;
	// try {
	// 	window.addEventListener(
	// 		"test",
	// 		null,
	// 		Object.defineProperty({}, "passive", {
	// 			get: function () {
	// 				supportsPassive = true;
	// 			},
	// 		})
	// 	);
	// } catch (e) {}

	// var wheelOpt = supportsPassive ? { passive: false } : false;
	// var wheelEvent =
	// 	"onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

	// // call this to Disable
	// function disableScroll() {
	// 	console.log("disable");
	// 	window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
	// 	window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
	// 	window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
	// 	window.addEventListener("keydown", preventDefaultForScrollKeys, false);
	// }

	// // call this to Enable
	// function enableScroll() {
	// 	console.log("enable");
	// 	window.removeEventListener("DOMMouseScroll", preventDefault, false);
	// 	window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
	// 	window.removeEventListener("touchmove", preventDefault, wheelOpt);
	// 	window.removeEventListener(
	// 		"keydown",
	// 		preventDefaultForScrollKeys,
	// 		false
	// 	);
	// }

	React.useEffect((isHeaderActive) => {
		if (window.innerWidth < 1023) {
			setIsBackgroundActive(true);
		}
		gsap.registerPlugin(ScrollTrigger);
		ScrollTrigger.create({
			trigger: "#root",
			start: "0px",
			end: 99999,
			onUpdate: ({ progress, direction, isActive }) => {
				if (direction === -1 /* && isHeaderHidden === true */) {
					showHeader();
					if (window.innerWidth >= 1024) {
						setIsBackgroundActive(true);
					}
				}
				if (direction === 1 /* && isHeaderHidden === false */) {
					if (window.innerWidth >= 1024) {
						hideHeader();
						setActiveItem(null);
						setIsHeaderActive(false);
					} else if (isHeaderActive) {
						console.log("active");
					}
				}

				if (progress <= 0.0) {
					showHeader();
					if (window.innerWidth >= 1024) {
						setIsBackgroundActive(false);
					}
				}
				setScrollPogress(progress);
			},
		});
	}, []);

	return (
		<React.Fragment>
			<header
				id="header"
				className={`${styles["global-header"]}
			${isHeaderWhite ? styles["white"] : ""} 
			${isHeaderHidden ? styles["is-hidden"] : ""} 
			${isHeaderActive ? styles["is-active"] : ""} 
			${isBackgroundActive || activeItem ? styles["is-background-active"] : ""}`}
				data-module-global-header=""
				data-module-headroom-header=""
			>
				<div
					className={`${styles["global-header__logo"]} ${styles["is-visible"]}`}
					data-header-logo-transition="logo-container"
				>
					<div
						className={`${styles["global-header__logo-perm"]} ${styles["is-visible"]}`}
						data-header-logo-transition="header-perm-logo"
						aria-hidden="true"
						data-flip-id="auto-1"
					>
						<Link
							to="/"
							aria-label="Briskfil Home"
							className={`${styles["logo"]}`}
							data-module-logo="header"
							tabIndex="-1"
						>
							<div className={`${styles["logo__inner"]}`}>
								<div className={`${styles["logo__innermost"]}`}>
									<ReactLogo width="150" />
								</div>
							</div>
						</Link>
					</div>
				</div>

				<span
					className={`${styles["global-header__mobile-nav-trigger"]}`}
					onClick={openHeader}
				>
					<svg
						viewBox="0 0 30 30"
						fill="none"
						id="mobile-nav-open"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9 12.364l4.95 4.95a2 2 0 002.828 0l4.95-4.95"
							stroke="currentColor"
							strokeWidth="1.5"
						></path>
					</svg>
				</span>

				<nav
					className={`${styles["global-header__nav"]}  ${
						activeItem ? styles["is-visible"] : ""
					}`}
					aria-label="Global Navigation"
					data-global-header="nav"
				>
					<span
						className={`${styles["global-header__nav-indicator"]}`}
						data-global-header="indicator"
						style={{
							transform:
								"translateX(973.781px) scaleX(0.521875) scaleY(0)",
							opacity: "0",
						}}
					></span>

					<span
						className={`${styles["global-header__mobile-nav-trigger"]}`}
						onClick={closeHeader}
					>
						<svg
							viewBox="0 0 30 30"
							fill="none"
							id="mobile-nav-close"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								stroke="currentColor"
								strokeWidth="1.5"
								d="M20.72 9.531L9.406 20.845"
							></path>
							<path
								stroke="currentColor"
								strokeWidth="1.5"
								d="M20.72 20.845L9.406 9.531"
							></path>
						</svg>
					</span>

					<ul className={`${styles["global-header__nav-list"]}`}>
						<HeaderItem
							index={1}
							title="Sobre Nós"
							link="/sobre-nos"
							activeItem={activeItem}
							toggleItem={toggleItem}
							closeHeader={closeHeader}
							isDropdown={false}
						></HeaderItem>
						<HeaderItem
							index={2}
							title="Energia"
							// link="/energia"
							activeItem={activeItem}
							toggleItem={toggleItem}
							// closeHeader={closeHeader}
							isDropdown={true}
							customPadding={true}
						>
							<HeaderItemListItem
								title="Consultoria e Eficiência Energética"
								link="/energia"
								onClick={closeHeader}
								description="Os recursos de compra de energia mais indicados"
							/>
							<HeaderItemListItem
								title="Case Studies"
								// link="/analises"
								// onClick={closeHeader}
								disabled={true}
								description="Brevemente"
							/>
						</HeaderItem>
						<HeaderItem
							index={3}
							title="Ar Comprimido"
							activeItem={activeItem}
							toggleItem={toggleItem}
							isDropdown={true}
						>
							<HeaderItemListItem
								title="Consultoria"
								link="/consultoria"
								onClick={closeHeader}
								description="Garantimos a melhor exploração energética e qualidade de equipamentos"
							/>
							<HeaderItemListItem
								title="Análises"
								link="/analises"
								onClick={closeHeader}
								description="Conheça as diferentes classes de tratamento do ar comprimido"
							/>
							<HeaderItemListItem
								title="Investigação"
								link="/investigacao"
								onClick={closeHeader}
								description="Em conjunto com os nossos parceiros, analisamos as melhores estratégias"
							/>
							<HeaderItemListItem
								title="Aplicações e Case Studies"
								link="/aplicacoes"
								onClick={closeHeader}
								description="Projetos beneficiados pelo nosso apoio especializado"
							/>
						</HeaderItem>
						<HeaderItem
							index={4}
							title="Construção"
							link="https://prsengenharia.pt"
							target_blank={true}
							activeItem={activeItem}
							toggleItem={toggleItem}
							closeHeader={closeHeader}
							isDropdown={false}
						/>
					</ul>

					<div
						className={`${styles["global-header-nav__buttons"]} hide-desktop`}
					>
						<div className="btn">
							<div
								className="btn__inner"
								href="#"
								onClick={openContact}
							>
								<span className="btn__text">
									<span>Contacte-nos</span>
								</span>
							</div>
						</div>
					</div>
				</nav>

				<nav className={`${styles["global-header__cta-nav"]}`}>
					<div className="btn">
						<div className="btn__inner" onClick={openContact}>
							<span className="btn__text">
								<span>Contacte-nos</span>
							</span>
						</div>
					</div>
				</nav>
			</header>

			<div
				className="contact-bar-modal"
				style={{
					position: "fixed",
					top: "0",
					right: "0",
					backgroundColor: isContactActive
						? "#000000aa"
						: "transparent",
					zIndex: "9999",
					height: "100%",
					width: "100vw",
					overflowX: "hidden",
					transition: "background-color 0.5s",
					pointerEvents: isContactActive ? "auto" : "none",
				}}
			>
				<div
					className={`contact-bar-wrapper text-light-grey bg-black ${
						isContactActive ? "active" : ""
					}`}
					data-modal="content-main"
					style={{
						position: "absolute",
						top: "0",
						right: "0",
						// width: isContactActive ? "100vw" : "0",
						height: "100%",
						transition: "width 1s",
						overflowX: "hidden",
						// pointerEvents: isContactActive ? "auto" : "none",
					}}
				>
					<div
						className="contact-bar pt-page pr-page pl-page pb-page"
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							// width: "100vw",
							height: "100%",
							transition: "width 0.75s ease-out",
						}}
					>
						<div className="contact-bar-close">
							<button
								className="link link--small "
								aria-label="Close modal"
								onClick={closeContact}
							>
								<span
									className="link__text"
									style={{ padding: "0 0" }}
								>
									<span>
										<span>Fechar</span>
									</span>
									<i
										className="link__icon"
										aria-hidden="true"
										style={{
											display: "inline-block",
											position: "relative",
											marginLeft: "1.5rem",
											top: "0rem",
										}}
									>
										<svg
											viewBox="0 0 15 15"
											fill="none"
											id="close-x"
											xmlns="http://www.w3.org/2000/svg"
											style={{
												width: "1.5rem",
												height: "1.5rem",
											}}
										>
											<path
												stroke="currentColor"
												strokeWidth="1.5"
												d="M1.53 1.47l12 12"
											></path>
											<path
												stroke="currentColor"
												strokeWidth="1.5"
												d="M13.53 1.53l-12 12"
											></path>
										</svg>
									</i>
								</span>
							</button>
						</div>

						<div className="mb-5 pb-5 pt-md-5">
							<h2 className="mb-4">
								Precisa de mais informação?
							</h2>
							<p>
								Preencha o formulário e nós entraremos em
								contacto assim que possível
							</p>
							<Contactos></Contactos>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Header;
