import React from "react";
import { ReactComponent as ReactLogo } from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import SimpleSlider from "../elements/SimpleSlider";

// import VideoJs from "../videojs/VideoJs";

const Banner = React.forwardRef(({
	children,
	isVideo,
	bannerImage,
	videoSrc = null,
	logoFill,
	bgColorClass = "bg-black",
	rightBgColorClass = null,
	noLogo = false,
	isHalfwidth = false,
	isGrafismo = false,
	Grafismo = null,
	grafismoBgColorClass = "",
	extraText = null,
	isSlider = false,
	isSliderText = false,
	overlayWhite = false,
	// refToVideoSlider = "",
	refToTextSlider = "",
	isFade=false,
	hasVideo = false
}, ref) => {
	// const {refToVideoSlider, refToTextSlider} = refs;
	const renderVideoBanner = () => {
		// <VideoJs
		// 			className="m-0 p-0"
		// 			autoPlay
		// 			loop
		// 			muted
		// 			style={{
		// 				position: "absolute",
		// 				objectFit: "cover",
		// 				objectPosition: "center",
		// 				height: "100vh",
		// 				width: "100vw",
		// 				position: "fix",
		// 				top: "0",
		// 				left: "0",
		// 				padding: "0",
		// 				minWidth: "100%",
		// 				minHeight: "100%",
		// 			}}
		// 			poster={bannerImage}
		// 			videoSrc={videoSrc}
		// 		></VideoJs>
		return (
			<div
				className="m-0 p-0"
				style={{
					position: "relative",
					overflow: "hidden",
					width: "100%",
					height: "100vh",
				}}
			>
				<video
					className="m-0 p-0"
					autoPlay
					loop
					muted
					style={{
						position: "absolute",
						objectFit: "cover",
						objectPosition: "center",
						height: "100vh",
						width: isHalfwidth ? "50vw" : "100vw",
						top: "0",
						right: isHalfwidth ? "0" : "unset",
						padding: "0",
						minWidth: "100%",
						minHeight: "100%",
					}}
					poster={bannerImage}
				>
					<source src={videoSrc} type="video/mp4" />
				</video>
				<div
					className="w-100 h-100 m-0 p-0"
					style={{ position: "absolute", left: "0", top: "0" }}
				>
					<div
						className="container-fluid h-100"
						style={{ backgroundColor: "#00000055" }}
					>
						<div className="h-100 d-flex flex-column align-items-start justify-content-center banner-padding">
							{noLogo ? (
								""
							) : (
								<ReactLogo
									fill={logoFill}
									style={{
										alignSelf: "flex-start",
										maxWidth: "80px",
										height: "auto",
									}}
									className="EM-logo"
								/>
							)}

							<div
								style={{
									margin: "auto 0",
								}}
							>
								{children}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderImageBanner = () => {
		return (
			<div
				className="w-100 m-0 p-0 banner banner-effect"
				style={{
					backgroundImage: `url("${bannerImage}")`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center center",
					height: "70vh",
				}}
			>
				<div
					className="container-fluid h-100"
					style={{ backgroundColor: "#0000004A" }}
				>
					<div className="h-100 d-flex flex-column align-items-center justify-content-center banner-padding">
						<Link
							to="/"
							style={{
								alignSelf: "flex-start",
							}}
						>
							{noLogo ? (
								""
							) : (
								<ReactLogo
									fill={logoFill}
									style={{
										alignSelf: "flex-start",
										maxWidth: "80px",
										height: "auto",
									}}
									className="EM-logo"
								/>
							)}
						</Link>
						<h1
							data-splitting
							className="c-white banner-title"
							style={{
								margin: "auto",
							}}
						>
							{children}
						</h1>
					</div>
				</div>
			</div>
		);
	};

	const renderImageHalfBanner = () => {
		return (
			<div
				className={`m-0 p-0  ${bgColorClass}`}
				style={{
					position: "relative",
					overflow: "hidden",
					width: "100%",
					height: "100vh",
				}}
			>
				<div
					id="banner-image-wrapper"
					className="m-0 p-0 h-100"
					style={{
						zIndex: "1",
					}}
				>
					{isGrafismo ? (
						<div
							className={`banner-image m-0 p-0 ${grafismoBgColorClass}`}
						>
							<div className="banner-grafismo w-100 h-100 d-flex align-items-center justify-content-lg-center text-black hide-mobile">
								{Grafismo ? (
									<Grafismo
										className={`banner-image-grafismo hide-mobile`}
										style={{
											width: "50%",
											maxWidth: "50%",
											height: "auto",
											// height: "50%",
											maxHeight: "50%",
										}}
									/>
								) : (
									""
								)}
							</div>
						</div>
					) : (
						<div
							className="banner-image m-0 p-0"
							style={{
								backgroundImage: `url("${bannerImage}")`,
							}}
						>
							{isSlider ? (
								<div
									className="h-100 w-100"
									style={{
										position: "absolute",
										left: 0,
										top: 0,
										zIndex: 1,
									}}
								>
									<SimpleSlider
										hasVideo={hasVideo}
										images={bannerImage}
										refToOtherSlider={refToTextSlider}
										ref={ref}
										isFade={isFade}
									/>
								</div>
							) : (
								""
							)}

							<div
								className={`banner-image-overlay ${
									overlayWhite
										? "banner-image-overlay-white"
										: ""
								}`}
							></div>
						</div>
					)}
				</div>
				<div
					className="w-100 h-100 m-0 p-0"
					style={{
						position: "absolute",
						left: "0",
						top: "0",
						zIndex: "3",
					}}
				>
					<div className="container-fluid h-100">
						<div
							className="d-flex flex-column align-items-start justify-content-center banner-padding"
							style={{
								height: "100%",
							}}
						>
							{noLogo ? (
								""
							) : (
								<ReactLogo
									fill={logoFill}
									style={{
										alignSelf: "flex-start",
										maxWidth: "80px",
										height: "auto",
									}}
									className="EM-logo"
								/>
							)}

							{isGrafismo && Grafismo ? (
								<Grafismo
									className={`banner-image-grafismo pl-page hide-desktop`}
									style={{
										marginTop: "120px",
										maxWidth: "150px",
										height: "auto",
										maxHeight: "150px",
									}}
								/>
							) : (
								""
							)}
							<div
								style={{
									margin: "auto 0",
									maxWidth: "100%",
								}}
							>
								{isSliderText ? "" : children}
							</div>
							<div className="pl-page pb-5 mb-5 mb-md-0  justify-self-end">
								{extraText}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderHtml = () => {
		if (isHalfwidth) {
			return renderImageHalfBanner();
		} else if (isVideo) {
			return renderVideoBanner();
		}
		return renderImageBanner();
	};

	return renderHtml();
});

export default Banner;
