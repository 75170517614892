// import React, { useState } from "react";
import React from "react";
// import { ReactComponent as ReactLogo } from "../assets/images/logo_white.svg";

import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";

// import { useTranslation } from "react-i18next";
import TitleSection from "../components/sections/TitleSection";

// import { Link } from "react-router-dom";

import { ReactComponent as Grafismo } from "../assets/fotos-briskfil/grafismos/investigacao/investigacao.svg";
import Footer from "../components/footer/Footer";

const Investigacao = ({ children, openContact, aos_settings, seo }) => {
	// const { t } = useTranslation();

	return (
		<PageWrapper bgColorClass="bc-green" seo={seo}>
			<Banner
				isVideo={false}
				// bannerImage={bannerImage}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-grey"
				isHalfwidth={true}
				noLogo={true}
				isGrafismo={true}
				Grafismo={Grafismo}
				grafismoBgColorClass="bg-orange"
				extraText={
					<h3 className="text-black banner-title-home-3">
						Ar comprimido e
						<br />
						Gases industriais
					</h3>
				}
			>
				<h1
					data-splitting
					className="text-black pl-page banner-title-home-1"
				>
					Investigação
				</h1>

				<h3 className="text-black pl-page banner-title-home-2">
					Para empresas que antecipam o futuro.
				</h3>
			</Banner>

			<div
				className=""
				style={{
					width: "100%",
					minHeight: "100vh",
					background: "#FFFFFF",
				}}
			>
				<TitleSection
					title={
						<React.Fragment>
							Investigação e
							<br />
							desenvolvimento (I&D)
						</React.Fragment>
					}
					titleColorClass="text-black"
					twoColumns={true}
					hasButton={true}
					buttonText="Contacte-nos"
					buttonTextClass="text-light-grey"
					buttonBgClass="bg-black"
					buttonBgHoverClass="bg-orange"
					button1Click={openContact}
					aos_settings={aos_settings}
				>
					<p {...aos_settings}>
						A Briskfil opera na área da I&D de sistemas de ar
						comprimido e gases e na procura de métodos inovadores de
						produção destes fluidos. A nossa equipa desenvolve
						projetos de investigação adaptados às diversas
						solicitações do mercado, especificamente das áreas
						alimentar, farmacêutica, hospitalar, química e
						eletrónica, reconhecidas pela sua exigência qualitativa.
					</p>
					<p {...aos_settings}>
						Inspirada nos sistemas de produção de gases através da
						tecnologia PSA (Pressure Swing Adsortion), a Briskfil
						dispõe de projetos para otimizar estas tecnologias, de
						modo a trazer melhorias substanciais ao mercado.
					</p>
				</TitleSection>

				<Footer />
			</div>
		</PageWrapper>
	);
};

export default Investigacao;
