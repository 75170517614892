import React, { Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Routes from "./routes/Routes";

import { ReactComponent as ReactLogo } from "./assets/images/forma.svg";

import "./App.scss";

const Loader = () => (
	<div
		id="loading-screen"
		className="d-flex bc-grey-estruturas justify-content-center align-items-center"
		style={{
			height: "100%",
			width: "100%",
			zIndex: "99999999",
			position: "fixed",
			right: "0",
			bottom: "0",
			backgroundColor: "black",
		}}
	>
		<div className="p-2 p-md-5" style={{ zIndex: "2", color: "white" }}>
			<ReactLogo
				className="load-icon"
				style={{ opacity: "0", color: "white", maxWidth: "150px" }}
				fill="#fff"
			/>
		</div>
	</div>
);

const App = () => {
	return (
		<React.Fragment>
			<React.Fragment>
				<Loader />
				<Suspense fallback={<Loader />}>
					<Router>
						<Route path="/" component={Routes} />
					</Router>
				</Suspense>
			</React.Fragment>
		</React.Fragment>
	);
};

export default App;
