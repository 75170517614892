import React from "react";
import { ReactComponent as ReactLogo } from "../../assets/images/logo_white.svg";
import { Link } from "react-router-dom";

// import PRR from "../../assets/briskfil-prr.png";
import PRR from "../../assets/briskfil-prr-2024.png";

const Footer = ({ marginTop = true }) => {
	return (
		<>
		<div
			className="row bg-black text-light-grey pl-page pr-page pt-page pb-page"
			style={{ marginTop: marginTop ? "15vh" : "0vh" }}
		>
			<div className="col-12 col-md-3 mb-5 pb-5">
				<ReactLogo style={{ maxWidth: "150px" }}></ReactLogo>
			</div>
			<div className="col-12 col-md-3 mb-5">
				<h2 className="pb-5 text-light-grey mb-0">Mapa do site</h2>
				<div className="pb-0 pb-md-4  small">
					<Link className="mb-2 link  small" to="/" target="">
						<span className="link__text">Home</span>
					</Link>
				</div>
				<div className="pb-0 pb-md-4  small">
					<Link className="link mb-2 small" to="/sobre-nos" target="">
						<span className="link__text">Sobre Nós</span>
					</Link>
				</div>
				<div className="pb-0 pb-md-4  small">
					<Link className="link mb-2 small" to="/energia" target="">
						<span className="link__text">Consultoria e Eficiência Energética</span>
					</Link>
				</div>
				<div className="pb-0 pb-md-4 small">
					<Link
						className="link mb-2 small"
						to="/consultoria"
						target=""
					>
						<span className="link__text">Consultoria de Ar Comprimido</span>
					</Link>
				</div>
				<div className="pb-0 pb-md-4  small">
					<Link className="link mb-2 small" to="/analises" target="">
						<span className="link__text">Análises</span>
					</Link>
				</div>
				<div className="pb-0 pb-md-4  small">
					<Link
						className="link mb-2 small"
						to="/investigacao"
						target=""
					>
						<span className="link__text">Investigação</span>
					</Link>
				</div>
				<div className="pb-0 pb-md-4  small">
					<Link
						className="link mb-2 small"
						to="/aplicacoes"
						target=""
					>
						<span className="link__text">
							Aplicações e case studies
						</span>
					</Link>
				</div>
			</div>
			<div className="col-12 col-md-3 mb-5">
				<h2 className="pb-5 text-light-grey mb-0 mt-0">Políticas</h2>
				<div className="pb-0 pb-md-4 small">
					<Link
						className="link mb-2 small"
						to="/privacidade"
						target=""
					>
						<span className="link__text">
							Política de Privacidade
						</span>
					</Link>
				</div>

				<div className="pb-0 pb-md-4 small">
					<Link
						className="link mb-2 small"
						to="/termos-e-condicoes"
						target=""
					>
						<span className="link__text">Termos e Condições</span>
					</Link>
				</div>

				<div className="pb-0 pb-md-4 small">
					<Link
						className="link mb-2 small"
						to="/ficha-de-projeto"
						target=""
					>
						<span className="link__text">Ficha de Projeto (PRR)</span>
					</Link>
				</div>
			</div>
			<div className="col-12 col-md-3 mb-5">
				<h2 className="pb-5 text-light-grey mb-0 mt-0">Contactos</h2>


				<div className="pb-0 pb-md-4 small">
					<a
						className="link mb-2 small"
						target="_blank"
						rel="noreferrer"
						href="https://www.linkedin.com/company/briskfil/"
					>
						LinkedIn
					</a>
				</div>
				<div className="pb-0 pb-md-4 small">
					<a
						className="link mb-2 small"
						href="tel:+351253071494"
					>
						+351 253 071 494 <sup>*</sup>
					</a>
				</div>
				<div className="pb-0 pb-md-4 small">
					<a
						className="link mb-2 small"
						href="mailto:geral@briskfil.com"
					>
						geral@briskfil.com
					</a>
				</div>
				<div className="pb-0 pb-md-4 small">
					<a
						className="link mb-2 small"
						target="_blank"
						rel="noreferrer"
						href="https://www.google.com/maps/place/R.+dos+Bombeiros+36,+4740-291+Esposende/@41.5334751,-8.7808553,17z/data=!3m1!4b1!4m5!3m4!1s0xd244bbade3d8c67:0xde35b15cf3a01a1!8m2!3d41.5334751!4d-8.7786666"
					>
						<address>
							Rua dos Bombeiros 36
							<br />
							4740-291 Esposende
						</address>
					</a>
				</div>
				<div className="pt-4 small">
					<p
						className="small"
						style={{fontSize:"12px"}}
					>
						<sup>*</sup> Chamada para rede fixa nacional
					</p>
				</div>
			</div>
		</div>

		<div className="col-12 py-2 pl-page pr-page pb-4 pb-md-2">
			<div className="row align-items-center">
				<div className="col-3 col-md-3 order-2 order-md-1">
					<p className="mb-0" style={{fontSize:"13px"}}>@ Briskfil</p>
				</div>
				<div className=" mb-5 pt-5 mb-md-0 pt-md-0 col-12 col-md-6 order-1 order-md-2">
					<Link to="/ficha-de-projeto" target="">
						<img
							style={{maxWidth:"100%", height:"auto", maxHeight:"80px"}}
							height="70px"
							src={PRR}
							alt="Logo PRR"
							// style={{ mixBlendMode: "lighten" }}
						/>
					</Link>
				</div>
				<div className="col-9 col-md-3 order-3 order-md-3">
					<p className="text-end text-md-start mb-0" style={{fontSize:"13px"}}>
					made with &#10084;&#65039; by{' '}
						<a
							className=""
							href="https://www.antecamarastudio.com"
							rel="noreferrer"
							target={'_blank'}
						>
							Antecâmara Studio
						</a>
					</p>
				</div>
			</div>
		</div>
		</>
	);
};

export default Footer;
