import React from "react";
import styles from "./SquareSectionMobile.module.scss";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SquareSectionMobile = ({
	id,
	fromRight = false,
	title1,
	text1,
	bgColorClass1,
	textColorClass1,
	title2,
	text2,
	bgColorClass2,
	textColorClass2,
	children,
}) => {
	React.useEffect(() => {
		gsap.registerPlugin(ScrollToPlugin);
		gsap.registerPlugin(ScrollTrigger);

		const panelsContainer = document.getElementById(id);

		if (fromRight) {
			gsap.set(panelsContainer, {
				scrollTo: { x: window.innerWidth },
			});
			gsap.to(panelsContainer, {
				duration: 1.5, // IDK why
				ease: "none",
				scrollTrigger: {
					trigger: `#${id}`,
				},
				scrollTo: { x: 0 },
			});
		} else {
			gsap.set(panelsContainer, {
				scrollTo: { x: 0 },
			});
			gsap.to(panelsContainer, {
				duration: 1.5, // IDK WHy
				ease: "none",
				scrollTrigger: {
					trigger: `#${id}`,
				},
				scrollTo: { x: window.innerWidth },
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<React.Fragment>
			<div
				className="w-100 hide-desktop"
				style={{
					minHeight: "25vw",
					maxWidth: "100%",
				}}
			>
				<div
					id={id}
					className={`${styles["squares-horizontal-scroll"]}`}
				>
					<div
						className={`${styles["squares-horizontal-scroll-item"]} ${bgColorClass1} ${textColorClass1}`}
					>
						<h3 className="">{title1}</h3>
						<p className="light small">{text1}</p>
					</div>
					<div
						className={`${styles["squares-horizontal-scroll-item"]} ${bgColorClass2} ${textColorClass2}`}
					>
						<h3 className="">{title2}</h3>
						<p className="light small">{text2}</p>
					</div>
				</div>
				{children}
			</div>
		</React.Fragment>
	);
};

export default SquareSectionMobile;
