import React from "react";
import Doughnut from "../../components/elements/chart/Doughnut";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

const GraphSection = ({
	children,
	id,
	title,
	titleColorClass,
	description,
	constructionsTitle,
	constructions,
	imageSmall,
	imageBig,
	fichaTecnicaFile,
	fichaTecnicaFileText,
}) => {
	const [firstPercentage, setFirstPercentage] = React.useState(0.2);
	const [percentageText, setPercentageText] = React.useState("15 a 20%");
	const [activeItem, setActiveItem] = React.useState(1);

	// const [scrollPogress, setScrollPogress] = React.useState(0);

	const chooseItem = (item, percentage, percentageText) => {
		setActiveItem(item);
		setFirstPercentage(percentage);
		setPercentageText(percentageText);
	};

	React.useEffect((id) => {
		let loopCount = 1;
		// gsap.registerPlugin(ScrollTrigger);
		// ScrollTrigger.create({
		// 	trigger: `.graph-consultoria`,
		// 	scrub: true,
		// 	pin: true,
		// 	pinType: "fixed",
		// 	anticipatePin: 1,
		// 	start: "top top",
		// 	end: "+=100%",
		// 	onUpdate: ({ progress, direction, isActive }) => {
		// 		// console.log(progress);
		// 		if (progress < 0.33) {
		// 			chooseItem(1, 0.2, "15 a 20%");
		// 		} else if (progress < 0.66) {
		// 			chooseItem(2, 0.05, "4 a 5%");
		// 		} else {
		// 			chooseItem(3, 0.85, "85%");
		// 		}
		// 	},
		// });

		setInterval(() => {
			if (loopCount % 3 === 0) {
				chooseItem(1, 0.2, "15 a 20%");
			} else if (loopCount % 3 === 1) {
				chooseItem(2, 0.05, "4 a 5%");
			} else if (loopCount % 3 === 2) {
				chooseItem(3, 0.85, "85%");
			}
			loopCount++;
		}, 3000);
	}, []);

	return (
		<React.Fragment>
			<div
				className="graph-consultoria row pl-page pr-page bg-grey text-light-grey flex-column-reverse flex-md-row"
				style={{
					margin: "0",
					paddingBottom: "10vh",
					// height: "100vh",
					// maxHeight: "100vh",
					// // eslint-disable-next-line
					// maxHeight: "-webkit-fill-available",
				}}
			>
				<div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
					<Doughnut
						textBgClass="bg-black text-black"
						lineBgClass="bg-light-blue"
						percentageText={percentageText}
						firstPercentage={firstPercentage}
						secondPercentage={0}
					/>
				</div>
				<div className="col-12 col-md-6 d-flex flex-column justify-content-center">
					<div
						className="list-container pt-5 pb-5 border-dark-grey"
						style={{
							borderLeftWidth: "1px",
							borderLeftStyle: "solid",
						}}
					>
						<p
							className="light border-orange text-black"
							style={{
								fontSize: activeItem === 1 ? "18px" : "18px",
								borderLeftWidth:
									activeItem === 1 ? "3px" : "0px",
								paddingLeft: activeItem === 1 ? "50px" : "50px",
								borderLeftStyle: "solid",
								marginLeft: "-2px",
								maxWidth: "38ch",
								transition: "font-size 0.5s, padding-left 0.5s",
							}}
							onClick={() => chooseItem(1, 0.2, "15 a 20%")}
						>
							O ar comprimido representa 15 a 20% do consumo de
							energia total de uma fábrica.
						</p>
						<p
							className="light border-orange text-black"
							style={{
								fontSize: activeItem === 2 ? "18px" : "18px",
								borderLeftWidth:
									activeItem === 2 ? "3px" : "0px",
								paddingLeft: activeItem === 2 ? "50px" : "50px",
								borderLeftStyle: "solid",
								marginLeft: "-2px",
								maxWidth: "38ch",
								transition: "font-size 0.5s, padding-left 0.5s",
							}}
							onClick={() => chooseItem(2, 0.05, "4 a 5%")}
						>
							Apenas 4-5% dessa energia é utilizada para produzir
							ar comprimido.
						</p>
						<p
							className="light border-orange text-black"
							style={{
								fontSize: activeItem === 3 ? "18px" : "18px",
								borderLeftWidth:
									activeItem === 3 ? "3px" : "0px",
								paddingLeft: activeItem === 3 ? "50px" : "50px",
								borderLeftStyle: "solid",
								marginLeft: "-2px",
								maxWidth: "38ch",
								marginBottom: "0",
								transition: "font-size 0.5s, padding-left 0.5s",
							}}
							onClick={() => chooseItem(3, 0.85, "85%")}
						>
							85% do calor dissipado pelos compressores pode e
							DEVE ser reaproveitado.
						</p>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default GraphSection;
