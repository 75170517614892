// import React, { useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
// import { ReactComponent as ReactLogo } from "../assets/images/logo_white.svg";
// import videoSrc from "../assets/teste4K.mp4";
// import videoBigSrc from "../assets/beta_2.mp4";
// import bannerImage from "../assets/images/DSC06537_2.jpg";
// import ilustracao from "../assets/images/ilustracao.svg";
// import image1 from "../assets/images/image1.jpg";
// import image2 from "../assets/fotos-briskfil/image2.jpg";
// import image2 from "../assets/fotos-briskfil/image004.jpeg";
// import image2 from "../assets/fotos-briskfil/image004.jpeg";
// import image2 from "../assets/fotos-briskfil/693.JPG";
import image2 from "../assets/fotos-briskfil/compressedAirLine.jpg";

import briskfil1 from "../assets/Briskfil1.jpg";
import briskfil2 from "../assets/Briskfil2.jpg";
import briskfil3 from "../assets/Briskfil3.jpg";

// import pipes from "../assets/fotos-briskfil/image004.jpeg";
// import bannerImageAirLine from "../assets/fotos-briskfil/compressed_air_stations.jpg";
import bannerImageAirLine from "../assets/images/DSC06566-2.jpg";
import image4 from "../assets/fotos-briskfil/image4.jpg";
// import image4 from "../assets/fotos-briskfil/image004.jpg";

import solarpanels3Image from "../assets/fotos-briskfil/solarpanels3.jpg";
// import solarpanels4Image from "../assets/fotos-briskfil/solarpanels2.jpg";
// import trees2Image from "../assets/fotos-briskfil/trees2.jpg";
import trees2Image from "../assets/fotos-briskfil/vaccines.jpg";
// import facility3Image from "../assets/fotos-briskfil/facility3.jpg";
// import facility1Image from "../assets/fotos-briskfil/facility1.jpg";
import abstract1Image from "../assets/fotos-briskfil/woman1.jpg";



// import IlustracaoPerfilConsumo from "../assets/fotos-briskfil/grafismos/animacao/perfil_ilustr.svg";

// import video1 from "../assets/fotos-briskfil/DJI_0041.mp4";
// import video2 from "../assets/fotos-briskfil/fotovoltaicas.mp4";

// import { ReactComponent as Ilustracao } from "../assets/fotos-briskfil/grafismos/animacao/esquema_final2.svg";
import { ReactComponent as Ilustracao } from "../assets/fotos-briskfil/grafismos/animacao/27.09.Briskfil_animação_2.svg";

import { ReactComponent as ConsultoriaIcon } from "../assets/fotos-briskfil/grafismos/consultoria/consultoria.svg";
import { ReactComponent as AnaliseIcon } from "../assets/fotos-briskfil/grafismos/analise/analise.svg";
import { ReactComponent as InvestigacaoIcon } from "../assets/fotos-briskfil/grafismos/investigacao/investigacao.svg";
import { ReactComponent as AplicacoesIcon } from "../assets/fotos-briskfil/grafismos/aplicacoes/aplicacoes.svg";
// import { ReactComponent as Icon1 } from "../assets/images/icon1.svg";
// import { ReactComponent as Icon2 } from "../assets/images/icon2.svg";
// import { ReactComponent as Icon3 } from "../assets/images/icon3.svg";
// import { ReactComponent as Formas4 } from "../assets/images/formas4-2.svg";
import { ReactComponent as Formas4 } from "../assets/fotos-briskfil/grafismos/formas/svg_anim_1.svg";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";

import SquareSection from "../components/sections/squares/SquareSection";
import SquareElement from "../components/elements/squares/SquareElement";

// import "../../node_modules/react-modal-video/css/modal-video.min.css";

// import ModalVideo from "react-modal-video";

// import { useTranslation } from "react-i18next";
// import TitleSection from "../components/sections/TitleSection";
import SquareTitleSection from "../components/sections/squares/TitleSection";
import SquareSectionMobile from "../components/sections/squares/SquareSectionMobile";
// import { Link } from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import Footer from "../components/footer/Footer";
// import { MotionPathHelper } from "gsap/MotionPathHelper";

// import Slider from "react-slick";

import SimpleSlider from "../components/elements/SimpleSlider";

const Home = ({ children, openContact, aos_settings, seo }) => {
	// const { t } = useTranslation();

	let sliderTextRef = React.useRef(null);
	let sliderVideoRef = React.useRef(null);

	const rand = (min, max) => {
		return Math.random() * (max - min + 1) + min;
	};

	React.useEffect(() => {

		gsap.registerPlugin(MotionPathPlugin);
		// gsap.registerPlugin(MotionPathHelper);
		gsap.registerPlugin(ScrollTrigger);

		MotionPathPlugin.convertToPath("#ligacao1");
		// MotionPathPlugin.convertToPath("#ligacao2");
		// MotionPathPlugin.convertToPath("#ligacao3");
		// gsap.set("#run1", { rotation: -5 });
		let timeline = new gsap.timeline({ repeat: -1 });
		// let timeline2 = new gsap.timeline({ repeat: -1 });
		// let timeline3 = new gsap.timeline({ repeat: -1 });
		//INITIAL VALUES
		let gParticles1 = document.querySelectorAll("#poeira1 > circle");
		let gParticles2 = document.querySelectorAll("#poeira2 > circle");
		// let gParticles3 = document.querySelectorAll("#poeira3 > circle");
		gParticles1.forEach((item) => {
			timeline.set(item, {
				translateX: `${rand(-4000, -2500)}px`, //"-700px",
				translateY: `${rand(-1500, -1000)}px`, //"-200px",
				scale: rand(0.01, 0.2),
			});
		});

		gParticles2.forEach((item) => {
			timeline.set(item, {
				// translateX: `${rand(-4000, -2500)}px`, //"-700px",
				// translateY: `${rand(-1500, -1000)}px`, //"-200px",
				scale: rand(0.01, 0.1),
			});
		});

		// gParticles3.forEach((item) => {
		// 	timeline3.set(item, {
		// 		translateX: `${rand(-4000, -2500)}px`, //"-700px",
		// 		translateY: `${rand(-1500, -1000)}px`, //"-200px",
		// 		scale: rand(0.01, 0.2),
		// 	});
		// });
		timeline.set("#run1", {
			strokeWidth: 20,
			opacity: 0,
		});
		// timeline2.set("#run2", {
		// 	strokeWidth: 20,
		// 	opacity: 0,
		// });
		// timeline3.set("#run3", {
		// 	strokeWidth: 20,
		// 	opacity: 0,
		// });

		// START ANIMATION
		timeline.to("#poeira1 > circle", {
			duration: 2,
			translateX: `${rand(0, 250)}px`,
			translateY: `${rand(-50, 100)}px`,
			stagger: 0.006,
		});

		// timeline3.to("#poeira3 > circle", {
		// 	delay: 1,
		// 	duration: 2.5,
		// 	translateX: `${rand(0, 250)}px`,
		// 	translateY: `${rand(-50, 100)}px`,
		// 	stagger: 0.006,
		// });

		timeline.set("#run1", {
			delay: -0.5,
			opacity: 1,
		});
		// timeline2.set("#run2", {
		// 	delay: -0.5,
		// 	opacity: 1,
		// });
		// timeline3.set("#run3", {
		// 	delay: -0.5,
		// 	opacity: 1,
		// });

		timeline.to("#run1", {
			delay: -0.5,
			duration: 3,
			motionPath: {
				path: "#ligacao1",
				start: 1,
				end: 0,
				align: "#ligacao1",
				alignOrigin: [0.5, 0.5],
				// autoRotate: true,
			},
			stroke: "#B2CBD8",
		});
		timeline.to("#run1", {
			delay: -0.5,
			duration: 0.5,
			opacity: 0,
		});

		// timeline.to("#poeira2 > circle", {
		// 	delay: 0.6,
		// 	duration: 2.5,
		// 	translateX: `${rand(0, 250)}px`,
		// 	translateY: `${rand(-50, 100)}px`,
		// 	stagger: 0.006,
		// });

		gParticles2.forEach((item, index) => {
			timeline.to(item, {
				delay: index === 0 ? 0 : -2.9,
				duration: 2.5,
				translateX: `${rand(500, 800)}px`,
				translateY: `${rand(-100, 300)}px`,
				opacity: 0,
			});
		});

		// timeline2.to("#run2", {
		// 	delay: -0.5,
		// 	duration: 4,
		// 	motionPath: {
		// 		path: "#ligacao2",
		// 		start: 1,
		// 		end: 0,
		// 		align: "#ligacao2",
		// 		alignOrigin: [0.5, 0.5],
		// 		// autoRotate: true,
		// 	},
		// });

		// timeline3.to("#run3", {
		// 	delay: -0.5,
		// 	duration: 4,
		// 	motionPath: {
		// 		path: "#ligacao3",
		// 		start: 1,
		// 		end: 0,
		// 		align: "#ligacao3",
		// 		alignOrigin: [0.5, 0.5],
		// 		// autoRotate: true,
		// 	},
		// });
	}, []);

	return (
		<PageWrapper bgColorClass="bc-green" seo={seo}>
			<Banner
				isVideo={false}
				isSlider={true}
				hasVideo={true}
				bannerImage={[
					{ src: briskfil1, type: "img" },
					{ src: briskfil2, type: "img" },
					{ src: briskfil3, type: "img" },
					// { src: video1, type: "video" },
					// { src: video2, type: "video" },
					// { src: video1, type: "video" },
					// { src: video2, type: "video" },
				]}
				isFade={true}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-black"
				isHalfwidth={true}
				noLogo={true}
				// refToVideoSlider={sliderVideoRef}
				refToTextSlider={sliderTextRef}
				ref = {sliderVideoRef}
			>
				<div className="banner-text-slider">
					<SimpleSlider isFade={true} refToOtherSlider={sliderVideoRef} ref={sliderTextRef}>
						<div id="banner-text-slider-slide-0">
							<h1
								data-splitting
								className="text-white pl-page banner-title-home-1"
							>
								Pelo futuro da
								<br />
								indústria eficiente
							</h1>
							<h3 className="text-light-grey pl-page banner-title-home-2 mb-0">
								Sabia que a classe 0 não significa
								<br />
								ar comprimido 100% estéril <br /> e livre de
								contaminantes?
							</h3>
							<div className="banner-button-home pl-page mt-5">
								<Link to="/consultoria">
									<button className="btn btn-briskfil btn-dark bg-grey text-black">
										<div className="btn-briskfil-background-effect bg-orange"></div>
										<span>Saiba Mais</span>
									</button>
								</Link>
							</div>
						</div>
						<div id="banner-text-slider-slide-1">
							<h1
								data-splitting
								className="text-white pl-page banner-title-home-1"
							>
								Energia
								para 
								<br />empresas inteligentes
							</h1>
							<h3 className="text-light-grey pl-page banner-title-home-2 mb-0">
								Soluções de financiamento à sua medida.
							</h3>
							<div className="banner-button-home pl-page mt-5">
								<Link to="/energia">
									<button className="btn btn-briskfil btn-dark bg-grey text-black">
										<div className="btn-briskfil-background-effect bg-orange"></div>
										<span>Saiba Mais</span>
									</button>
								</Link>
							</div>
						</div>
						<div id="banner-text-slider-slide-2">
							<h1
								data-splitting
								className="text-white pl-page banner-title-home-1"
							>
								À velocidade da
								<br />
								Indústria do futuro
							</h1>
							<h3 className="text-light-grey pl-page banner-title-home-2 mb-0">
								Sabia que com a deteção de fugas
								<br /> de ar comprimido poupará <br />
								milhares de euros anualmente?
							</h3>
							<div className="banner-button-home pl-page mt-5">
								<Link to="/analises">
									<button className="btn btn-briskfil btn-dark bg-grey text-black">
										<div className="btn-briskfil-background-effect bg-orange"></div>
										<span>Saiba Mais</span>
									</button>
								</Link>
							</div>
						</div>
						{/* <div id="banner-text-slider-slide-3">
							<h1
								data-splitting
								className="text-white pl-page banner-title-home-1"
							>
								Energia
								para 
								<br />empresas inteligentes
							</h1>
							<h3 className="text-light-grey pl-page banner-title-home-2 mb-0">
								Soluções de financiamento à sua medida.
							</h3>
							<div className="banner-button-home pl-page mt-5">
								<Link to="/energia">
									<button className="btn btn-briskfil btn-dark bg-grey text-black">
										<div className="btn-briskfil-background-effect bg-orange"></div>
										<span>Saiba Mais</span>
									</button>
								</Link>
							</div>
						</div> */}
					</SimpleSlider>
				</div>
			</Banner>

			<div
				className=""
				style={{
					width: "100%",
					minHeight: "100vh",
					background: "#FFFFFF",
				}}
			>
				<div
					className="row"
					style={{
						minHeight: "25vw",
						marginTop: "15vh",
					}}
				>
					<div
						className="col-12 col-md-6 pr-page pl-page d-flex flex-column justify-content-center"
						style={{
							backgroundColor: "#FFFFFF",
						}}
					>
						<h2 className="text-grey" {...aos_settings}>
							Ar comprimido livre de incertezas
						</h2>
						<p {...aos_settings} style={{ maxWidth: "60ch" }}>
							Beneficie de uma opinião técnica relativa à
							qualidade do ar e exploração energética da sua
							empresa, e ainda sobre a qualidade dos equipamentos
							selecionados e custos associados para melhor
							laboração.
						</p>
					</div>
					<SquareElement
						hideMobile={true}
						alignRight={true}
						wrapperBgColorClass={false}
						bgColorClass="bg-black"
						textColorClass="text-light-grey"
						// backgroundImage={abstract1Image}
						backgroundImage={bannerImageAirLine}
						hasOverlay={true}
						text={
							<React.Fragment>
								Reduza a pegada de carbono da sua empresa com a
								produção on-site de nitrogénio e oxigénio.
							</React.Fragment>
						}
						hasButton={true}
						btnText="Saiba Mais"
						buttonLink="/consultoria"
						btnBgHoverClass="bg-orange"
						aos_settings={aos_settings}
					/>
				</div>

				<SquareSectionMobile
					id="aasdasd-1"
					fromRight={false}
					text1="Reduza a pegada de carbono da sua empresa com a produção on-site de nitrogénio e oxigénio."
					bgColorClass1="bg-black"
					textColorClass1="text-light-grey"
					text2="Precisa de Ar Limpo e Seco para a sua linha de processo farmacêutico ou alimentar?"
					bgColorClass2="bg-orange"
					textColorClass2="text-black"
				></SquareSectionMobile>
				<SquareSectionMobile
					id="aasdasd-2"
					fromRight={true}
					text1="Conheça o seu perfil de consumo energético e como reduzi-lo."
					bgColorClass1="bg-green"
					textColorClass1="text-light-grey"
					text2={
						<React.Fragment>
							Garanta ar comprimido livre de contaminantes
							biológicos tais como a Covid-19.
							<br />
							Adquira o nosso selo{" "}
							<span style={{ fontWeight: "bold" }}>
								Clean and Safe
							</span>
							.
						</React.Fragment>
					}
					bgColorClass2="bg-grey"
					textColorClass2="text-black"
				></SquareSectionMobile>

				<SquareSection hideMobile={true} aos_settings={aos_settings}>
					<SquareElement
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-grey"
						textColorClass="text-black"
						backgroundImage={trees2Image}
						backgroundContain={false}
						isImageHalfWidth={true}
						text="Precisa de Ar Limpo e Seco para a sua linha de processo farmacêutico ou alimentar?"
						hasButton={true}
						btnText="Saiba Mais"
						buttonLink="/aplicacoes"
						aos_settings={aos_settings}
					/>
					<SquareElement
						alignRight={true}
						wrapperBgColorClass="bg-black"
						bgColorClass="bg-orange"
						textColorClass="text-black"
						// backgroundImage={IlustracaoPerfilConsumo}
						// backgroundImageBackgroundClass="bg-grey"
						// backgroundImageNoPadding={true}
						backgroundImage={solarpanels3Image}
						// hasButton={true}
						// backgroundContain={true}
						isImageHalfWidth={true}
						text="Conheça o seu perfil de consumo energético e como reduzi-lo."
						hasButton={true}
						btnText="Saber Mais"
						buttonLink="/consultoria"
						aos_settings={aos_settings}
					/>
				</SquareSection>
				<SquareSection hideMobile={true}>
					<SquareElement
						alignRight={false}
						wrapperBgColorClass="bg-grey"
						bgColorClass="bg-green"
						textColorClass="text-black"
						backgroundImage={abstract1Image}
						isImageHalfWidth={true}
						text={
							<React.Fragment>
								Garanta ar comprimido livre de contaminantes
								biológicos tais como a Covid-19.
								<br />
								Adquira o nosso selo{" "}
								<span style={{ fontWeight: "bold" }}>
									Clean and Safe
								</span>
								.
							</React.Fragment>
						}
						hasButton={true}
						btnText="Contacte-nos"
						buttonOnClick={openContact}
						btnBgHoverClass="bg-orange"
						aos_settings={aos_settings}
					/>
				</SquareSection>

				<div
					className="row pr-page pl-page flex-row-reverse row-custom-ilustration-margin"
					style={{
						marginTop: "10vw",
					}}
				>
					<div
						className="col-12 col-md-4 pt-page pl-md-page pr-md-page"
						{...aos_settings}
					>
						<h2 className="mb-5">
							Certifique o seu ar comprimido segundo os padrões
							internacionais
						</h2>
						<p style={{ maxWidth: "38ch" }}>
							Evite interrupções na sua linha de produção.
							<br />
							Um programa regular de testagem de ar comprimido é a
							sua melhor escolha e o principal objetivo dos nossos
							serviços de análise regulares e de apoio de
							consultoria.
						</p>
					</div>
				</div>
				<div
					className="row pr-page pb-page"
					style={{
						margin: "0",
						marginTop: "10vw",
						marginBottom:"-10vw"
					}}
				>
					<div className="col-12 col-md-12 d-flex justify-content-center pr-page ">
						<Ilustracao
							style={{
								width: "100%",
								maxWidth: "100%",
								height: "100%",
								maxHeight: "100vh",
							}}
						/>
					</div>
				</div>

				

				<SquareTitleSection
					id="home-square-title-section-1"
					title={
						<React.Fragment>
							Consultoria para
							<br />
							Ar Comprimido e Gases
						</React.Fragment>
					}
					titleColorClass="text-black"
					description={
						<React.Fragment>
							Descubra a sua realidade.
							<br />
							<br />
							Os nossos técnicos qualificados prestam um serviço
							diferenciado e especializado de consultoria em Ar
							Comprimido e Gases.
							<br />
							Além disto, temos ao dispor serviços de análise
							detalhada a fluidos sob pressão.
						</React.Fragment>
					}
					buttonText="Saber Mais"
					buttonBgClass="bg-black"
					buttonBgHoverClass="bg-light-blue"
					buttonTextClass="text-white"
					buttonRightText="Saber Mais"
					buttonLeftLink="/consultoria"
					backgroundImage={image2}
					bgColorClass="bg-light-blue"
					rightSmallTitle="Ar comprimido"
					rightBigTitle={
						<React.Fragment>
							Indústria 4.0
							<br />
							Como se adaptar!
						</React.Fragment>
					}
					rightDescription={
						<React.Fragment>
							Solucionamos questões relacionadas com a eficiência
							energética e a qualidade dos gases sob pressão
							aplicando técnicas inovadoras de produção e
							tratamento de gases sob-pressão, bem como de
							integração, gestão e controlo alinhados com a
							Indústria 4.0.
						</React.Fragment>
					}
					hasOverlay={false}
					hasButtonRight={false}
					aos_settings={aos_settings}
				/>

				<SquareSection aos_settings={aos_settings}>
					<SquareElement
						isSingleSquare={true}
						Icon={ConsultoriaIcon}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						bgColorClass="bg-on-hover-light-blue border-on-light-blue"
						textColorClass="text-black"
						backgroundImage={false}
						title="Consultoria"
						text="Garantimos a melhor exploração energética e qualidade de equipamentos, ao menor custo. Ajustamo-nos ao seu perfil, com o conhecimento técnico e tecnologia de ponta mais indicados."
						hasButton={true}
						btnText="Saber Mais"
						buttonBgHoverClass="bg-green"
						buttonLink="/consultoria"
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={AnaliseIcon}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-on-hover-pink border-on-hover-pink"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Análises"
						text="Conheça as diferentes classes de tratamento do ar comprimido a mais adequada para a sua empresa. Consulte os tipos de análise disponíveis."
						hasButton={true}
						btnText="Saber Mais"
						buttonLink="/analises"
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={InvestigacaoIcon}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-on-hover-orange border-on-hover-orange"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Investigação"
						text="Em conjunto com os nossos parceiros, analisamos as melhores estratégias. Saiba mais sobre os nossos projetos."
						hasButton={true}
						btnText="Saber Mais"
						buttonBgHoverClass="bg-orange"
						buttonLink="/investigacao"
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={AplicacoesIcon}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						bgColorClass="bg-on-hover-blue border-on-hover-blue"
						textColorClass="text-black"
						backgroundImage={false}
						title="Aplicações"
						text="Projetos beneficiados pelo nosso apoio especializado. Descubra quais."
						hasButton={true}
						btnText="Saber Mais"
						buttonLink="/aplicacoes"
						aos_settings={aos_settings}
					/>
				</SquareSection>

				<SquareTitleSection
					id="home-square-title-section-2"
					title={
						<React.Fragment>
							Energia e <br />
							Eficiência energética
						</React.Fragment>
					}
					titleColorClass="text-black"
					description={
						<React.Fragment>
							A Briskfil presta serviço de consultoria de compra
							de energia e de gás natural a grandes consumidores,
							através de uma análise detalhada do perfil de
							consumo do cliente e identificação dos métodos para
							melhoria a curto, médio e longo prazo.
						</React.Fragment>
					}
					buttonText="Saber Mais"
					buttonBgClass="bg-black"
					buttonBgHoverClass="bg-green"
					buttonTextClass="text-white"
					backgroundImage={image4}
					bgColorClass="bg-green"
					rightSmallTitle="Energia"
					rightBigTitle={
						<React.Fragment>
							Paineis Solares Fotovoltaicos!
							<br />
						</React.Fragment>
					}
					rightDescription={
						<React.Fragment>
							Produza e consuma a sua própria energia.
							<br />
							Economize enquanto valoriza o Ambiente.
							<br />
							<br />
							Garantimos qualidade do serviço de instalação e do
							equipamento de produção de energia com 25 anos de
							duração.
						</React.Fragment>
					}
					hasButtonRight={false}
					buttonLeftLink="/energia"
					aos_settings={aos_settings}
				/>

				<SquareSection alignRight={true} aos_settings={aos_settings}>
					<SquareElement
						isSingleSquare={true}
						Icon={ConsultoriaIcon}
						alignRight={true}
						wrapperBgColorClass={false}
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Consultoria"
						text="Com a análise do seu perfil de consumo, concluímos os recursos de compra de energia mais indicados. Incluímos ainda um relatório personalizado e detalhado sobre as diversas propostas disponíveis."
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={AnaliseIcon}
						alignRight={false}
						wrapperBgColorClass={false}
						// bgColorClass="bg-on-hover-orange border-on-hover-orange"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Análise"
						text="As parcerias da Briskfil proporcionam soluções adaptadas às suas necessidades de produção de energia solar fotovoltaica."
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Formas4}
						fullIcon={true}
						alignRight={true}
						noBorder={true}
						bgColorClass="bg-green"
					/>
				</SquareSection>

				<Footer marginTop={false} />
			</div>
		</PageWrapper>
	);
};

export default Home;
