import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
// import { useTranslation } from "react-i18next";
// import HeaderItemListItem from "./HeaderItemListItem";

const HeaderItem = ({
	children,
	index,
	title,
	link,
	activeItem,
	toggleItem,
	isDropdown = false,
	closeHeader = null,
	target_blank = false,
	customPadding = false,
}) => {
	// const { t } = useTranslation();

	return isDropdown ? (
		<li
			className={`${styles["global-header__item"]} ${
				activeItem === index ? styles["is-active"] : styles["is-hidden"]
			} ${activeItem === index ? styles["is-visible"] : ""}`}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				toggleItem(index);
			}}
		>
			<div
				className={`${styles["global-header__link"]} pointer-cursor`}
				data-global-header="link"
				aria-expanded="false"
			>
				{title}
				<span aria-hidden="true">
					<svg
						viewBox="0 0 15 8"
						fill="none"
						className="carat-down"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.728 1.364l-4.95 4.95a2 2 0 01-2.828 0L1 1.364"
							stroke="currentColor"
							strokeWidth="1.5"
						></path>
					</svg>
				</span>
			</div>

			<div
				className={`${styles["global-header__item-list"]} ${
					customPadding ? styles["custom-padding"] : ""
				}`}
				data-global-header="item-list"
				aria-hidden="true"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				<div
					className={`${styles["global-header__item-list-back-bar"]}`}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						toggleItem(index);
					}}
				>
					<p
						className={`${styles["global-header__item-list-back-trigger"]}`}
						data-global-header="back-trigger"
					>
						<span aria-hidden="true">
							<svg
								viewBox="0 0 15 8"
								fill="none"
								className="carat-down"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.728 1.364l-4.95 4.95a2 2 0 01-2.828 0L1 1.364"
									stroke="currentColor"
									strokeWidth="1.5"
								></path>
							</svg>
						</span>
						Voltar
					</p>

					<p
						className={`${styles["global-header__item-list-back-label"]}`}
					>
						{title}
					</p>
				</div>
				{children}
			</div>
		</li>
	) : (
		<li
			className={`${styles["global-header__item"]} ${
				activeItem === index ? styles["is-active"] : styles["is-hidden"]
			} ${activeItem === index ? styles["is-visible"] : ""}`}
			onClick={(e) => {
				e.preventDefault();
				toggleItem(index);
			}}
		>
			{target_blank ? (
				<a
					href={link}
					className={`${styles["global-header__link"]}`}
					target="_blank"
					onClick={(e) => {
						window.open(link, "_blank");
					}}
					rel="noreferrer"
				>
					{title}
				</a>
			) : (
				<Link
					to={link}
					className={`${styles["global-header__link"]}`}
					data-global-header="link"
					aria-expanded="false"
					onClick={closeHeader}
				>
					{title}
				</Link>
			)}
		</li>
	);
};

export default HeaderItem;
