import React from "react";
import styles from "./Doughnut.module.scss";

import { Doughnut as DoughnutChart } from "react-chartjs-2";

const Doughnut = ({
	textBgClass,
	lineBgClass,
	percentageText = "",
	firstPercentage = 20,
	secondPercentage = 0,
}) => {
	// const [currentPercentage, setCurrentPercentage] = React.useState(0);
	// const ref = React.useRef();

	// const calcPercentage = () => {
	// 	let temp = null;
	// 	if (currentPercentage <= 0.5) {
	// 		if (firstPercentage > 0.5) {
	// 			let distancePerc = Math.abs(
	// 				currentPercentage - firstPercentage
	// 			);
	// 			let rest = firstPercentage - 0.5;

	// 			temp = {
	// 				first: {
	// 					perc: 0.5,
	// 					duration: (0.5 - currentPercentage) / distancePerc,
	// 					delay: 0,
	// 				},
	// 				second: {
	// 					perc: rest,
	// 					duration: rest / distancePerc,
	// 					delay: (0.5 - currentPercentage) / distancePerc,
	// 				},
	// 			};
	// 			console.log(temp);
	// 		} else {
	// 			temp = {
	// 				first: { perc: firstPercentage, duration: 1, delay: 0 },
	// 				second: { perc: 0, duration: 0, delay: 0 },
	// 			};
	// 		}
	// 	} else {
	// 		if (firstPercentage > 0.5) {
	// 			let rest = firstPercentage - 0.5;
	// 			temp = {
	// 				first: { perc: 0.5, duration: 0, delay: 0 },
	// 				second: { perc: rest, duration: 1, delay: 0 },
	// 			};
	// 		} else {
	// 			let distancePerc = currentPercentage - firstPercentage;
	// 			let rest = currentPercentage - 0.5;

	// 			let firstTime = (0.5 - firstPercentage) / distancePerc;
	// 			let secondTime = (distancePerc - rest) / distancePerc;
	// 			temp = {
	// 				first: {
	// 					perc: 0.5,
	// 					duration: firstTime,
	// 					delay: secondTime,
	// 				},
	// 				second: {
	// 					perc: rest,
	// 					duration: secondTime,
	// 					delay: 0,
	// 				},
	// 			};
	// 		}
	// 	}
	// 	if (currentPercentage !== firstPercentage) {
	// 		setCurrentPercentage(firstPercentage);
	// 	}
	// 	return temp;
	// };

	const data = {
		labels: ["Total", "label"],
		datasets: [
			{
				label: "# of Votes",
				data: [firstPercentage, 1 - firstPercentage],
				backgroundColor: [
					"rgba(225, 113, 82, 1)",
					"rgba(178, 203, 216, 1)",
				],
				borderColor: [
					"rgba(255, 99, 132, 1)",
					"rgba(178, 203, 216, 1)",
				],
				hoverBackgroundColor: [
					"rgba(225, 113, 82, 1)",
					"rgba(178, 203, 216, 1)",
				],
				hoverBorderColor: [
					"rgba(255, 99, 132, 1)",
					"rgba(178, 203, 216, 1)",
				],
				borderWidth: 1,
			},
		],
	};

	return (
		<React.Fragment>
			<div className={`${styles["donut-chart"]} `}>
				<DoughnutChart
					data={data}
					options={{
						cutoutPercentage: "90",
						plugins: {
							legend: {
								display: false,
							},

							tooltip: { enabled: false },
						},
						animations: {
							duration: 5000,
						},
					}}
				/>

				<h2 className={`${styles["center"]}`}>
					<span className="text-black">{percentageText}</span>
					{secondPercentage > 0 ? (
						<React.Fragment>
							<span className="text-black">&nbsp;-&nbsp;</span>
							<span className="text-black">
								{`${secondPercentage * 100}%`}
							</span>
						</React.Fragment>
					) : (
						""
					)}
				</h2>
			</div>
		</React.Fragment>
	);
};

export default Doughnut;
