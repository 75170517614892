import React from "react";
import {Helmet} from "react-helmet";
import "./Hero.css";

import AOS from "aos";
import "aos/dist/aos.css";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

const getAnchor = () => {
	var currentUrl = document.URL,
		urlParts = currentUrl.split("#");

	return urlParts.length > 1 ? urlParts[1] : null;
};

const PageWrapper = ({ children, bgColorClass, seo }) => {
	React.useEffect(() => {
		AOS.init({
			once: true,
			mirror: false,
			anchorPlacement: "bottom-bottom",
			offset: 0,
		});
		AOS.refresh();

		//FIX OVERFLOW TO STICKY PROBLEM
		document
			.getElementById("main-wrapper")
			.style.removeProperty("overflow");
		document.querySelector("body").style.height = window.innerHeight;
		document.querySelector("html").style.height = window.innerHeight;

		//init gsap animations
		let revealContainers = document.querySelectorAll(
			`.container-img-effect .img-reveal`
		);

		revealContainers.forEach((container) => {
			let image = container.querySelector(".image-left-to-right");
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: container,
					// toggleActions: "restart none none reset",
				},
			});

			tl.set(container, { autoAlpha: 1 });
			tl.from(container, 1, {
				xPercent: -100,
				ease: "Power2.out",
			});
			tl.from(image, 1, {
				xPercent: 100,
				scale: 1.2,
				delay: -1,
				ease: "Power2.out",
			});
		});

		// BANNER ANIMATION
		var tl = gsap.timeline({ paused: true });
		// tl.to(".hero .banner.banner-effect", 1, { height: "40vh" });
		var tl2 = gsap.timeline({ paused: true });
		// tl2.to(".hero .banner.banner-effect .container-fluid", 1, {
		// 	backgroundColor: "rgba(0, 0, 0, 0.8)",
		// });
		var tl3 = gsap.timeline({ paused: true });
		// tl3.to(".banner-title", 1, { scale: 0.9 });
		var tl4 = gsap.timeline({ paused: true });
		// tl4.to(".EM-logo", 1, { scale: 0.9 });

		var maxDistance = window.innerHeight * 0.7;

		window.addEventListener("scroll", function () {
			var yPos = window.scrollY;
			tl.progress(yPos / maxDistance);
			tl2.progress(yPos / maxDistance);
			tl3.progress(yPos / maxDistance);
			tl4.progress(yPos / maxDistance);
		});

		//PARALLAX ANIMATION

		// let parallaxSections = document.querySelectorAll(".parallax-section");
		let innerHeight = window.innerHeight;
		gsap.utils.toArray(".parallax-section").forEach((section) => {
			section.bg = section.querySelector(".parrallax-bg-image-container");
			section.bg.style.backgroundPosition = `center ${
				-innerHeight / 2
			}px`;

			gsap.to(section.bg, {
				backgroundPosition: `center ${innerHeight / 2}px`,
				ease: "none",
				scrollTrigger: {
					trigger: section,
					scrub: true,
				},
			});
		});
	}, []);

	React.useEffect(() => {
		let anchor = getAnchor();
		if (anchor) {
			var anchor1 = document.getElementById(anchor);
			var posTop = anchor1.offsetTop;
			gsap.to(window, 1, { scrollTo: posTop });
		}
	});
	return (
		<>
		<Helmet>
			{/* <!-- SEO --> */}

			<title>{seo.title}</title>
			<meta charset="utf-8" />
			<meta name="description" content={seo.description} />
			<link rel="author" href="https://plus.google.com/{{googlePlusId}}" />
			<link rel="canonical" href={window.location.href} />

			{/* <!-- facebook --> */}

			<meta property="og:url" content={window.location.href} />
			<meta property="og:image" content={seo.image_url} />
			<meta property="og:description" content={seo.description} />
			<meta property="og:title" content={seo.title} />
			<meta property="og:site_name" content="Briskfil" />
			<meta property="og:see_also" content="https://www.briskfil.com" />

			{/* <!-- twitter --> */}

			<meta name="twitter:card" content="summary" />
			<meta name="twitter:url" content={window.location.href} />
			<meta name="twitter:title" content={seo.title} />
			<meta name="twitter:description" content={seo.description} />
			<meta name="twitter:image" content={seo.image_url} />
		</Helmet>
		<section
			className={`hero ${bgColorClass} h-100`}
			style={{ backgroundColor: "white", overflowX: "hidden" }}
		>
			{children}
		</section>
		</>
	);
};

export default PageWrapper;
