import React from "react";

const SquareSection = ({
	children,
	alignRight = false,
	hideMobile = false,
	hideDesktop = false,
	noMinHeight = false,
}) => {
	return (
		<React.Fragment>
			<div
				className={`row w-100 m-0 p-0 justify-content-end 
				${alignRight ? "" : "justify-content-md-center"} 
				${hideMobile ? "hide-mobile" : ""}
				${hideDesktop ? "hide-desktop" : ""}`}
				style={{
					minHeight: noMinHeight ? "0" : "25vw",
				}}
			>
				{children}
			</div>
		</React.Fragment>
	);
};

export default SquareSection;
