// import React, { useState } from "react";
import React from "react";
// import { ReactComponent as ReactLogo } from "../assets/images/logo_white.svg";

import { ReactComponent as Grafismo } from "../assets/fotos-briskfil/grafismos/analise/analise.svg";
import { ReactComponent as Icon1 } from "../assets/fotos-briskfil/grafismos/analise/ativo-1.svg";
import { ReactComponent as Icon2 } from "../assets/fotos-briskfil/grafismos/analise/ativo-2.svg";
import { ReactComponent as Icon3 } from "../assets/fotos-briskfil/grafismos/analise/ativo-3.svg";
import { ReactComponent as Icon4 } from "../assets/fotos-briskfil/grafismos/analise/ativo-4.svg";
import { ReactComponent as Icon5 } from "../assets/fotos-briskfil/grafismos/analise/ativo-5.svg";
import { ReactComponent as Icon6 } from "../assets/fotos-briskfil/grafismos/analise/ativo-6.svg";
import { ReactComponent as Icon7 } from "../assets/fotos-briskfil/grafismos/analise/ativo-7.svg";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";

import SquareSection from "../components/sections/squares/SquareSection";
import SquareElement from "../components/elements/squares/SquareElement";

// import { useTranslation } from "react-i18next";
import TitleSection from "../components/sections/TitleSection";
// import { Link } from "react-router-dom";
import Footer from "../components/footer/Footer";

import labImage from "../assets/fotos-briskfil/DSC06560.jpg";

const Analises = ({ children, aos_settings, seo }) => {
	return (
		<PageWrapper bgColorClass="bc-green" seo={seo}>
			<Banner
				isVideo={false}
				// bannerImage={bannerImage}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-grey"
				isHalfwidth={true}
				noLogo={true}
				isGrafismo={true}
				Grafismo={Grafismo}
				grafismoBgColorClass="bg-pink"
				extraText={
					<h3 className="text-black banner-title-home-3">
						Ar comprimido e
						<br />
						Gases industriais
					</h3>
				}
			>
				<h1
					data-splitting
					className="text-black pl-page banner-title-home-1"
				>
					Análises
				</h1>

				<h3 className="text-black pl-page banner-title-home-2">
					Para empresas que antecipam o futuro.
				</h3>
			</Banner>

			<div
				className=""
				style={{
					width: "100%",
					minHeight: "100vh",
					background: "#FFFFFF",
				}}
			>
				<TitleSection
					title={<React.Fragment>Serviços de Análise</React.Fragment>}
					titleColorClass="text-black"
					customContent={
						<React.Fragment>
							<p>
								A Briskfil disponibiliza serviços de análise
								detalhada da qualidade do ar comprimido da sua
								empresa, visando o cumprimento dos padrões
								internacionais para exportação e qualidade do
								produto.
							</p>
						</React.Fragment>
					}
					twoColumns={true}
					noPaddingRightCol={true}
					noMarginBottom={true}
					aos_settings={aos_settings}
				>
					<div className="w-100 h-100 m-0 p-0">
						<img
							className=""
							src={labImage}
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
								objectPosition: "center center",
							}}
							alt=""
						></img>
					</div>
				</TitleSection>

				<SquareSection alignRight={true}>
					<SquareElement
						isSingleSquare={false}
						Icon={Icon7}
						alignRight={false}
						wrapperBgColorClass="bg-pink"
						fullText={true}
						textColorClass="text-black"
						text="Sabia que o ar comprimido possui diferentes poluentes? E que, mesmo não estando em contacto direto com o produto, é criado depósito (biofilmes). Simplificando: inevitavelmente, encontrará sujidade acumulada ao longo do tempo."
						aos_settings={aos_settings}
					/>
				</SquareSection>

				<SquareSection>
					<SquareElement
						isSingleSquare={true}
						bgColorClass="bg-light-blue"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						noBorder={true}
						title={
							<React.Fragment>
								Conheça o nosso certificado{" "}
								<span
									style={{
										fontWeight: "bold",
										fontStyle: "italic",
									}}
								>
									Clean and Safe
								</span>{" "}
								segundo ISO 8573.1
							</React.Fragment>
						}
						text=""
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Icon7}
						alignRight={true}
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Ponto de Orvalho"
						text="Monitorização de processos de secagem por refrigeração, membrana e adsorção. Medição de pdp até -70ºC. Precisão +- 1% °C. Pode ser contínua ou por pontualmente."
						// hasButton={true}
						btnText="Download"
						btnTextHoverClass="text-hover-orange"
						btnUnderlineClass="orange"
						btnIsDownload={true}
						btnDownloadFileName="PontoDeOrvalho"
						buttonLink={labImage}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Icon6}
						alignRight={false}
						wrapperBgColorClass={false}
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Partículas"
						text="Recolha de amostra simplificada, sem visitas técnicas dispendiosas. Relatório sobre a quantidade/contagem de partículas, segundo as várias classes, e sua composição. "
						// hasButton={true}
						btnText="Download"
						btnTextHoverClass="text-hover-orange"
						btnUnderlineClass="orange"
						btnIsDownload={true}
						btnDownloadFileName="Particulas"
						buttonLink={labImage}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Icon5}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Óleo Resídual"
						text="Determinação de óleo residual e vapor de óleo para controlo de qualidade do ar e de hidrocarbonetos segundo a norma DIN ISO 8573-5."
						// hasButton={true}
						btnText="Download"
						btnTextHoverClass="text-hover-orange"
						btnUnderlineClass="orange"
						btnIsDownload={true}
						btnDownloadFileName="OleoResidual"
						buttonLink={labImage}
						aos_settings={aos_settings}
					/>
				</SquareSection>

				<SquareSection>
					<SquareElement
						isSingleSquare={true}
						Icon={Icon4}
						alignRight={false}
						wrapperBgColorClass={false}
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Microrganismos e Germes"
						text="Determinação da contagem total de germes presentes no ar comprimido, por amostra recolhida, para análise por laboratório acreditado para DIN ISO 8573-7."
						// hasButton={true}
						btnText="Download"
						btnTextHoverClass="text-hover-orange"
						btnUnderlineClass="orange"
						btnIsDownload={true}
						btnDownloadFileName="MicroorganismosGermes"
						buttonLink={labImage}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Icon3}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundImage={false}
						title={
							<React.Fragment>
								Pureza de N<sub>2</sub> e O<sub>2</sub>
							</React.Fragment>
						}
						text="Através da recolha de uma amostra de azoto ou oxigénio é possível fazer a determinação quantitativa e qualitativa de gases orgânicos presentes."
						// hasButton={true}
						btnText="Download"
						btnTextHoverClass="text-hover-orange"
						btnUnderlineClass="orange"
						btnIsDownload={true}
						btnDownloadFileName="PurezaN2O2"
						buttonLink={labImage}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={false}
						Icon={Icon6}
						alignRight={false}
						wrapperBgColorClass="bg-purple"
						fullText={true}
						textColorClass="text-white"
						text="Sabia que a classe Zero não significa que o seu ar comprimido é 100% estéril e livre de contaminantes, água, óleo e partículas?"
						aos_settings={aos_settings}
					/>
				</SquareSection>

				<SquareSection>
					<SquareElement
						isSingleSquare={true}
						Icon={Icon2}
						alignRight={false}
						wrapperBgColorClass={false}
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Caudal e Consumo Energético"
						text="Através de equipamentos de medição de caudal e consumo energético aferimos o custo da produção por cada m3 produzido, evitando o seu consumo desnecessário."
						// hasButton={true}
						btnText="Download"
						btnTextHoverClass="text-hover-orange"
						btnUnderlineClass="orange"
						btnIsDownload={true}
						btnDownloadFileName="CaudalConsummoEnergetico"
						buttonLink={labImage}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Icon1}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Deteção de Fugas"
						text="Análise e correção de fugas na rede de ar ou de gases sob pressão. Através de tecnologia ultrassónica, detetam-se fugas de diâmetros reduzidos a longas distâncias. "
						// hasButton={true}
						btnText="Download"
						btnTextHoverClass="text-hover-orange"
						btnUnderlineClass="orange"
						btnIsDownload={true}
						btnDownloadFileName="DetecaoFugas"
						buttonLink={labImage}
						aos_settings={aos_settings}
					/>
				</SquareSection>

				{/* other content */}

				<Footer marginTop={false} />
			</div>
		</PageWrapper>
	);
};

export default Analises;
