// import React, { useState } from "react";
import React from "react";
// import { ReactComponent as ReactLogo } from "../assets/images/logo_white.svg";
import bannerImage from "../assets/fotos-briskfil/image3.jpg";
import greenImage from "../assets/fotos-briskfil/green.jpg";

import filtersImage from "../assets/fotos-briskfil/filters.jpg";
import solarPanels2Image from "../assets/fotos-briskfil/solarpanels2.jpg";
import solarPanels5Image from "../assets/fotos-briskfil/solarpanels5.jpg";
import solarPanels6Image from "../assets/fotos-briskfil/solarpanels6.jpg";

import IPACImage from "../assets/fotos-briskfil/grafismos/energia/omie.jpg";
import BCASImage from "../assets/fotos-briskfil/grafismos/energia/omip.jpeg";
import IMPICImage from "../assets/fotos-briskfil/grafismos/energia/IMPIC_logo.jpg";

import { ReactComponent as EnergiaVerdeIcon } from "../assets/fotos-briskfil/grafismos/Icones/energiaverde.svg";
import { ReactComponent as ConhecimentoIcon } from "../assets/fotos-briskfil/grafismos/Icones/conhecimento.svg";
import { ReactComponent as PlanosIcon } from "../assets/fotos-briskfil/grafismos/Icones/planos.svg";
import { ReactComponent as Energia1Icon } from "../assets/fotos-briskfil/grafismos/energia/energia1.svg";
import { ReactComponent as Energia2Icon } from "../assets/fotos-briskfil/grafismos/energia/energia2.svg";
import { ReactComponent as Energia3Icon } from "../assets/fotos-briskfil/grafismos/energia/energia3.svg";
// import { ReactComponent as Formas4 } from "../assets/images/formas4.svg";

import { ReactComponent as Formas4 } from "../assets/fotos-briskfil/grafismos/formas/svg_anim_4.svg";

import { ReactComponent as Formas4_2 } from "../assets/fotos-briskfil/grafismos/formas/svg_anim_3.svg";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";

import SquareSection from "../components/sections/squares/SquareSection";
import SquareElement from "../components/elements/squares/SquareElement";

// import { useTranslation } from "react-i18next";
import TitleSection from "../components/sections/TitleSection";
import SquareSectionMobile from "../components/sections/squares/SquareSectionMobile";
// import { Link } from "react-router-dom";
import Footer from "../components/footer/Footer";

const Energia = ({ children, openContact, aos_settings, seo }) => {
	// const { t } = useTranslation();

	return (
		<PageWrapper bgColorClass="bc-green" seo={seo}>
			<Banner
				isVideo={false}
				bannerImage={bannerImage}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-grey"
				isHalfwidth={true}
				noLogo={true}
				extraText={
					<h3 className="text-black banner-title-home-3">
						Energia e
						<br />
						Eficiência Energética
					</h3>
				}
				overlayWhite={true}
			>
				<h1
					data-splitting
					className="text-black pl-page banner-title-home-1"
				>
					Energia
				</h1>
				<h3 className="text-black pl-page banner-title-home-2">
					A forma inteligente de reduzir a sua pegada ambiental.
				</h3>
			</Banner>

			<div
				className=""
				style={{
					width: "100%",
					minHeight: "100vh",
					background: "#FFFFFF",
				}}
			>
				<TitleSection
					title={
						<React.Fragment>
							Consultoria de
							<br />
							Energia
						</React.Fragment>
					}
					titleColorClass="text-black"
					twoColumns={true}
					hasButton={true}
					buttonText="Empresas"
					buttonTextClass="text-light-grey"
					buttonBgClass="bg-black"
					buttonBgHoverClass="bg-orange"
					button1Click={openContact}
					hasButton2={true}
					button2Text="Particulares"
					button2TextClass="text-light-grey"
					button2BgClass="bg-black"
					button2BgHoverClass="bg-orange"
					button2Click={openContact}
					aos_settings={aos_settings}
				>
					<p {...aos_settings}>
						Quer reduzir os custos com a energia? Comece por obtê-la
						ao menor preço possível. Depois da análise do seu
						perfil, a Briskfil consulta o mercado para os diversos
						níveis de tensão e para tarifas fixas ou variáveis.
					</p>

					<p {...aos_settings}>
						Somos especialistas na compra direta no mercado de
						energia OMIE e em mercados de futuro (OMIP),
						disponibilizando aos nossos clientes as soluções mais
						económicas, a curto, médio e longo prazo, consoante o
						perfil.
					</p>

					<p {...aos_settings}>
						A Briskfil dispõe de alvará para realização de obras particulares com número de registo 102048 - PAR
					</p>

					{/* <h2 className="pt-page">Certificações</h2> */}
					<br />
					<div className="row p-0 m-0 ">
						<div className="col-12 col-xl-6 pb-5">
							<img
								height="80px"
								src={IPACImage}
								alt="IPAC Logo"
								style={{ mixBlendMode: "multiply" }}
							/>
						</div>
						<div className="col-12 col-xl-6 pb-4">
							<img
								height="80px"
								src={BCASImage}
								alt="BCAS Logo"
								style={{ mixBlendMode: "multiply" }}
							/>
						</div>
						<div className="col-12 col-xl-6 pb-4">
							<img
								height="80px"
								src={IMPICImage}
								alt="IMPIC Logo"
								style={{ mixBlendMode: "multiply" }}
							/>
						</div>
					</div>
				</TitleSection>

				<SquareSection alignRight={true}>
					<div
						className="col-12 col-md-6 m-0 p-0 custom-image-wrapper-energia-height"
						// style={{ height: "25vw" }}
					>
						<div className="w-100 h-100 m-0 p-0">
							<img
								style={{
									objectFit: "cover",
									objectPosition: "top left",
									width: "100%",
									height: "100%",
									margin: "0",
									padding: "0",
								}}
								src={greenImage}
								alt=""
							/>
						</div>
					</div>
					<SquareElement
						isSingleSquare={true}
						Icon={Formas4}
						fullIcon={true}
						alignRight={true}
						noBorder={true}
						bgColorClass="bg-green"
					/>
				</SquareSection>

				<SquareSection alignRight={true} noMinHeight={true}>
					<SquareElement
						isSingleSquare={true}
						Icon={EnergiaVerdeIcon}
						bigIconNotCentered={true}
						alignRight={true}
						wrapperBgColorClass={false}
						bgColorClass="bg-green"
						textColorClass="text-black"
						backgroundImage={false}
						noBorder={true}
						title="Energia 100% Verde"
						text=""
						minThreeLines={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={ConhecimentoIcon}
						bigIconNotCentered={true}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-green"
						textColorClass="text-black"
						isImageHalfWidth={true}
						noBorder={true}
						title="Conhecimento de Mercados de Energia e Gás"
						text=""
						minThreeLines={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={PlanosIcon}
						bigIconNotCentered={true}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-green"
						textColorClass="text-black"
						isImageHalfWidth={true}
						noBorder={true}
						title="Planos adaptados ao seu perfil"
						text=""
						minThreeLines={true}
						aos_settings={aos_settings}
					/>
				</SquareSection>

				<TitleSection
					title={
						<React.Fragment>
							Eficiência
							<br />
							Energética
						</React.Fragment>
					}
					titleColorClass="text-black"
					smallTitle=""
					smallTitleColorClass="text-black"
					twoColumns={true}
					noMarginBottom={true}
					aos_settings={aos_settings}
				></TitleSection>

				<TitleSection
					title=""
					titleColorClass="text-black"
					smallTitle={
						<React.Fragment>
							Saiba como reduzir o seu consumo de energia em 80%
						</React.Fragment>
					}
					smallTitleColorClass="text-black"
					twoColumns={true}
					noMarginTop={true}
					aos_settings={aos_settings}
				>
					<p {...aos_settings}>
						Graças a uma extensa rede de parcerias, a Briskfil tem
						acesso às melhores ofertas do mercado, não só para a
						energia provinda de painéis solares fotovoltaicos, mas
						também para ar comprimido energeticamente mais
						eficiente.
					</p>
					<p {...aos_settings}>
						Retorno rápido do seu investimento com total garantia!
						Com o nosso suporte, obterá uma avaliação esclarecedora
						dos diferentes modelos de negócio, com ou sem
						investimento.
					</p>
				</TitleSection>

				<SquareSection alignRight={true} hideMobile={true}>
					<SquareElement
						isSingleSquare={true}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-green"
						textColorClass="text-white"
						backgroundImage={filtersImage}
						backgroundContain={true}
						isImageHalfWidth={true}
						noBorder={true}
						title="Substituição de coberturas em fibrocimento"
						text=""
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-black"
						textColorClass="text-light-grey"
						backgroundImage={solarPanels6Image}
						backgroundContain={true}
						isImageHalfWidth={true}
						noBorder={true}
						title="Carport Solar Fotovoltaica"
						text=""
						aos_settings={aos_settings}
					/>
				</SquareSection>

				<SquareSectionMobile
					id="section-square-mobile-1"
					fromRight={false}
					text1="Central Solar fotovoltaica"
					bgColorClass1="bg-green"
					textColorClass1="text-black"
					text2="Carport Solar Fotovoltaica"
					bgColorClass2="bg-black"
					textColorClass2="text-light-grey"
					hasOverlay={true}
				></SquareSectionMobile>
				<SquareSectionMobile
					id="section-square-mobile-2"
					fromRight={true}
					text1="Substituição de coberturas em fibrocimento"
					bgColorClass1="bg-grey"
					textColorClass1="text-black"
					text2="Autoconsumo com acumulação de energia"
					bgColorClass2="bg-light-blue"
					textColorClass2="text-black"
				></SquareSectionMobile>

				<SquareSection hideMobile={true}>
					<SquareElement
						alignRight={false}
						bgColorClass="bg-grey"
						wrapperBgColorClass="bg-grey"
						textColorClass="text-black"
						backgroundImage={solarPanels5Image}
						backgroundContain={false}
						isImageHalfWidth={true}
						text="Central Solar Fotovoltaica"
						aos_settings={aos_settings}
					/>
					<SquareElement
						alignRight={true}
						wrapperBgColorClass="bg-black"
						bgColorClass="bg-light-blue"
						textColorClass="text-black"
						backgroundImage={solarPanels2Image}
						backgroundContain={false}
						isImageHalfWidth={true}
						text="Autoconsumo com acumulação de energia"
						aos_settings={aos_settings}
					/>
				</SquareSection>

				<SquareSection>
					<SquareElement
						isSingleSquare={true}
						Icon={Energia1Icon}
						alignRight={false}
						wrapperBgColorClass={false}
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						text=""
						title="Acesso a financiamento para projetos de eficiência energética"
						minThreeLines={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Energia2Icon}
						alignRight={false}
						wrapperBgColorClass="bg-white"
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundImage={false}
						text=""
						title="Autoconsumo para PME"
						minThreeLines={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Energia3Icon}
						alignRight={false}
						wrapperBgColorClass={false}
						// bgColorClass="bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						text=""
						title="Candidate-se ao Fundo Ambiental!"
						minThreeLines={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Formas4_2}
						fullIcon={true}
						alignRight={true}
						noBorder={true}
						bgColorClass="bg-light-blue"
					/>
				</SquareSection>

				<Footer marginTop={false} />
			</div>
		</PageWrapper>
	);
};

export default Energia;
