import React from "react";
import "./Hero.css";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";
import Footer from "../components/footer/Footer";
// import { Link } from "react-router-dom";
// import { ReactComponent as Grafismo } from "../assets/fotos-briskfil/grafismos/analise/analise.svg";
// import { useTranslation } from "react-i18next";

const FichaProjeto = ({ children, aos_settings, seo }) => {
	// const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas" seo={seo}>
			<Banner
				isVideo={false}
				// bannerImage={bannerImage}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-grey"
				isHalfwidth={true}
				noLogo={true}
				isGrafismo={true}
				grafismoBgColorClass="bg-pink"
			>
				<h1
					data-splitting
					className="text-black pl-page banner-title-home-1"
				>
					Plano de Recuperação<br/>e Resiliência (PRR)
				</h1>
			</Banner>
			<div className="">
				<div class="mt-5 pt-5 container">
					<div class="row ">
						<div class="col-md-12 ">
							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}><strong></strong>Voucher para Startups</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}><strong>Designação do projeto:</strong> Green Efficiency Management 4.0</p>

								<p {...aos_settings}><strong>N.º do projeto:</strong> 1753</p>

								<p {...aos_settings}><strong>Objetivo principal:</strong> Promoção de modelos de negócio, produtos ou serviços digitais com contributo positivo para a transição climática através de elevada eficiência na utilização de recursos. Promoção de modelos de negócio, produtos ou serviços digitais que permitam a redução dos impactos da poluição.</p>

								<p {...aos_settings}><strong>Região de intervenção:</strong> Norte</p>

								<p {...aos_settings}><strong>Entidade Beneficiária:</strong> BRISKFIL, UNIPESSOAL LDA</p>

								

								<p><strong>Data de Aprovação:</strong> 26/10/2023</p>

								<p><strong>Data de início:</strong> 01/01/2023</p>

								<p><strong>Data de conclusão:</strong> 25/05/2025</p>

								<p><strong>Custo total elegível:</strong> 30.000,00 euros</p>

								<p><strong>Apoio financeiro da União Europeia:</strong> 30.000,00 euros</p>
							</div>
							<div class="mb-5"></div>

							<div
								class="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h1 {...aos_settings}>Objetivos, atividades e resultados esperados/atingidos</h1>

								<div class="mb-3"></div>
								<p {...aos_settings}>
									Desenvolvimento de um software na área de gestão e controlo de custos financeiros e ambientais decorrentes da utilização de equipamentos industriais.
								</p>
								<p {...aos_settings}>
									Projeto apoiado no âmbito do Plano de Recuperação e Resiliência {"("}<a href="https://recuperarportugal.gov.pt" target="_blank" rel="noreferrer">recuperarportugal.gov.pt</a>{")"}
								</p>
							</div>

							<div class="mb-5--sm"></div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</PageWrapper>
	);
};

export default FichaProjeto;



// Voucher para Startups

 

// Designação do projeto: Green Efficiency Management 4.0

// N.º do projeto: 1753

// Objetivo principal: Promoção de modelos de negócio, produtos ou serviços digitais com contributo positivo para a transição climática através de elevada eficiência na utilização de recursos. Promoção de modelos de negócio, produtos ou serviços digitais que permitam a redução dos impactos da poluição.

// Região de intervenção: Norte

// Entidade Beneficiária: BRISKFIL, UNIPESSOAL LDA

 

// Data de Aprovação: 26/10/2023

// Data de início: 01/01/2023

// Data de conclusão: 25/05/2025

// Custo total elegível: 30.000,00 euros

// Apoio financeiro da União Europeia: 30.000,00 euros

 

// Objetivos, atividades e resultados esperados/atingidos:

// Desenvolvimento de um software na área de gestão e controlo de custos financeiros e ambientais decorrentes da utilização de equipamentos industriais.

// Projeto apoiado no âmbito do Plano de Recuperação e Resiliência (recuperarportugal.gov.pt)