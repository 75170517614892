// import React, { useState } from "react";
import React from "react";
// import { ReactComponent as ReactLogo } from "../assets/images/logo_white.svg";
// import { Link } from "react-router-dom";

import trees1Image from "../assets/fotos-briskfil/trees3.jpg";
import worker2Image from "../assets/fotos-briskfil/worker3.jpg";
// import IPACImage from "../assets/fotos-briskfil/IPAC.jpg";
// import BCASImage from "../assets/fotos-briskfil/BCAS.jpg";
import IMPICImage from "../assets/fotos-briskfil/grafismos/energia/IMPIC_logo.jpg";

import { ReactComponent as IconVisao } from "../assets/fotos-briskfil/grafismos/Icones/visao.svg";
import { ReactComponent as IconValores } from "../assets/fotos-briskfil/grafismos/Icones/valores.svg";
import { ReactComponent as IconMissao } from "../assets/fotos-briskfil/grafismos/Icones/missao.svg";
// import { ReactComponent as ConsultoriaIcon } from "../assets/fotos-briskfil/grafismos/consultoria/consultoria.svg";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";

// import "../../node_modules/react-modal-video/css/modal-video.min.css";

// import ModalVideo from "react-modal-video";

// import { useTranslation } from "react-i18next";
import TitleSection from "../components/sections/TitleSection";
import { ReactComponent as Grafismo } from "../assets/fotos-briskfil/grafismos/Icones/logo_b.svg";
import Footer from "../components/footer/Footer";

const SobreNos = ({ children, openContact, aos_settings, seo }) => {
	// const { t } = useTranslation();

	return (
		<PageWrapper bgColorClass="bc-green" seo={seo}>
			<Banner
				isVideo={false}
				// bannerImage={bannerImage}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-grey"
				isHalfwidth={true}
				noLogo={true}
				isGrafismo={true}
				Grafismo={Grafismo}
				grafismoBgColorClass="bg-yellow"
				extraText={
					<h3 className="text-black banner-title-home-3">
						Sobre Nós
					</h3>
				}
			>
				<h1
					data-splitting
					className="text-black pl-page banner-title-home-1"
				>
					Experiência que
					<br />
					fala por nós
				</h1>
			</Banner>

			<div
				className=""
				style={{
					width: "100%",
					minHeight: "100vh",
					background: "#FFFFFF",
				}}
			>
				<TitleSection
					title={<React.Fragment>Nova Geração</React.Fragment>}
					titleColorClass="text-black"
					hasButton={true}
					buttonText="Contacte-nos"
					buttonBgClass="bg-black"
					buttonBgHoverClass="bg-orange"
					buttonTextClass="text-white"
					button1Click={openContact}
					twoColumns={true}
					aos_settings={aos_settings}
				>
					<h2>
						A Briskfil serve o futuro e serve-o a si: o cliente que
						procura o melhor para a sua empresa.
						<br />
						<br />
						Prestamos serviços de consultoria especializada nas
						áreas de Energia e Eficiência Energética, com
						diferenciação na área do Ar Comprimido e Gases
						Industriais.
					</h2>
				</TitleSection>
				<div
					className="row m-0 p-0"
					style={{
						minHeight: "25vw",
						margin: "0",
					}}
				>
					<div
						className="col-12 col-md-6 m-0 no-padding-right-mobile pr-page pb-md-page"
						style={{
							backgroundColor: "#FFFFFF",
							paddingTop: "0",
							paddingLeft: 0,
						}}
					>
						<img
							src={trees1Image}
							alt=""
							className="p-0 m-0 w-100 h-100"
							style={{
								objectFit: "cover",
							}}
						/>
					</div>
					<div className="col-12 col-md-6 pr-page pl-md-page pb-page">
						<p {...aos_settings}>
							A Briskfil é pioneira em Portugal. Oferecemos um
							serviço de consultoria especializada exclusivamente
							em energia, painéis solares e gases industriais,
							complementado com análise a fluidos sob pressão.
							Solucionamos questões relacionadas com a eficiência
							energética e a qualidade dos gases, aplicando
							técnicas inovadoras de produção e tratamento de
							gases sob pressão.
						</p>
						<p {...aos_settings}>
							Nascemos para conferir a visão técnica, isenta e
							qualificada de que o cliente necessita. Somos uma
							entidade capaz de transmitir às empresas um suporte
							na análise categórica da sua situação atual e na
							adaptação às melhorias a implementar.
						</p>
						<p {...aos_settings}>
							Os nossos técnicos altamente qualificados, e com
							décadas de experiência na área, contribuem
							diariamente com o seu know-how, de forma a otimizar
							os custos e a qualidade do ar e dos gases nas suas
							diversas aplicações.
						</p>
						<p {...aos_settings}>
							Tudo isto com o objetivo de introduzir no mercado
							métodos inovadores e state-of-the-art na área do ar
							comprimido e gases. Porque acreditamos num Portugal
							mais próximo da média europeia no que diz respeito à
							implementação de tecnologia e procedimentos da
							indústria 4.0.
						</p>
						<p {...aos_settings}>
							Além de consultoria, disponibilizamos um
							departamento de construção, PSR Engenharia, para
							clientes que pretendem acompanhamento e eficiência
							desde a pedra basilar até ao último remate.
						</p>
					</div>
				</div>

				<div
					className="row bg-light-blue pt-0"
					style={{
						minHeight: "25vw",
						margin: "0",
					}}
				>
					<div className="col-12 col-md-4 pt-page pl-page pr-page pb-page">
						<IconMissao
							className="mb-5"
							style={{ maxWidth: "75px" }}
						/>
						<h2 className="mt-4 mt-md-5" {...aos_settings}>
							Missão
						</h2>
						<p {...aos_settings}>
							A pedra basilar da Briskfil é a sua missão de
							otimizar empresas e particulares nos seus consumos
							energéticos, dando-lhes acesso às melhores práticas
							e tecnologias do momento, reduzindo o consumo de
							energia alinhados com a indústria 4.0 e
							auxiliando-as na descarbonização.
						</p>
					</div>
					<div className="col-12 col-md-4 pt-page pl-page pr-page pb-page">
						<IconValores
							className="mb-5"
							style={{ maxWidth: "75px" }}
						/>
						<h2 className="mt-4 mt-md-5" {...aos_settings}>
							Valores
						</h2>
						<p {...aos_settings}>
							A Briskfil rege-se por valores inegociáveis de
							respeito, transparência e responsabilidade para com
							todos os seus colaboradores, parceiros, clientes,
							para todas as soluções que propõe e compromissos.
						</p>
					</div>
					<div className="col-12 col-md-4 pt-page pl-page pr-page pb-page">
						<IconVisao
							className="mb-5"
							style={{ maxWidth: "75px" }}
						/>
						<h2 className="mt-4 mt-md-5" {...aos_settings}>
							Visão
						</h2>
						<p {...aos_settings}>
							A Briskfil pretende tornar-se um interveniente de
							referência no mercado do ar comprimido e gases
							industriais, bem como no mercado da energia e
							medidas de eficiência energética. Queremos alcançar
							cada vez mais entidades e particulares, para que
							possam beneficiar de conhecimentos qualificado,
							orientado para um futuro mais sustentável.
						</p>
					</div>
				</div>

				<div
					className="row"
					style={{
						minHeight: "25vw",
						margin: "0",
					}}
				>
					<div className="col-12 col-md-6 pt-page pb-page pr-page pl-page d-flex flex-column justify-content-center">
						<h2 {...aos_settings}>
							35 Anos de
							<br />
							Experiência
						</h2>
						<p {...aos_settings}>
							Prestamos serviço de consultoria de compra de
							energia e gás natural a grandes consumidores de modo
							a permitir uma profunda análise do seu perfil de
							consumo e identificação de métodos de melhoria de
							curto, médio e longo prazo. Aliado à consultoria de
							compra de energia, a Briskfil também disponibiliza
							aos seus clientes a possibilidade de aceder a vários
							modelos de negócio para instalação de centrais
							fotovoltaicas como solução integrada do seu serviço.
						</p>
					</div>
					<div className="col-12 col-md-6 p-0 m-0">
						<img
							src={worker2Image}
							alt=""
							className="p-0 m-0 w-100 h-100"
							style={{
								objectFit: "cover",
							}}
						/>
					</div>
				</div>

				<div
					className="row bg-grey pt-mb-page"
					style={{
						margin: "0",
					}}
				>
					<div className="col-12">
						<h1
							{...aos_settings}
							className="mt-4 mt-md-0 pt-page pl-page pr-page "
						>
							Certificações
						</h1>
					</div>
				</div>
				<div
					className="row bg-grey pt-0 "
					style={{
						minHeight: "25vw",
						margin: "0",
					}}
				>
					<div
						className="col-12 col-md-6 pt-page pl-page pr-page pb-page border-medium-grey"
						style={{ borderWidth: "0px", borderStyle: "solid" }}
					>
						<img
							className="mb-5"
							height="80px"
							src={IMPICImage}
							alt="IMPIC Logo"
							style={{ mixBlendMode: "multiply" }}
						/>
						<h2 className="mt-3 mt-md-5" {...aos_settings}>
							IMPIC
						</h2>
						<p {...aos_settings}>
							A Briskfil dispõe de alvará para realização de obras particulares com número de registo 102048 - PAR
						</p>
					</div>
					{/* <div
						className="col-12 col-md-6 pt-page pl-page pr-page pb-page border-medium-grey"
						style={{ borderWidth: "1px", borderStyle: "solid" }}
					>
						<img
							className="mb-5"
							height="80px"
							src={BCASImage}
							alt="BCAS Logo"
							style={{ mixBlendMode: "multiply" }}
						/>
						<h2 className="mt-3 mt-md-5" {...aos_settings}>
							BCAS
						</h2>
						<p {...aos_settings}>
							A Briskfil é membro do BCAS — British Compressed Air
							Society —, como entidade independente, de forma a
							assegurar uma fonte de aconselhamento técnico. Desta
							forma, garante que os equipamentos de ar comprimido,
							a sua instalação e manutenção são energeticamente
							eficientes, dentro das normas internacionais e
							seguros.
						</p>
					</div>
					<div
						className="col-12 col-md-6 pt-page pl-page pr-page pb-page border-medium-grey"
						style={{ borderWidth: "1px", borderStyle: "solid" }}
					>
						<img
							className="mb-5"
							height="80px"
							src={IPACImage}
							alt="IPAC Logo"
							style={{ mixBlendMode: "multiply" }}
						/>
						<h2 className="mt-3 mt-md-5" {...aos_settings}>
							IPAC
						</h2>
						<p {...aos_settings}>
							Atualmente, encontra-se em processo de acreditação
							IPAC, no âmbito das análises ao ar comprimido e
							gases.
						</p>
					</div> */}
				</div>

				<Footer marginTop={false} />
			</div>
		</PageWrapper>
	);
};

export default SobreNos;
