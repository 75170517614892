import React from "react";
// import { autofill } from "redux-form";
import styles from "./SquareElement.module.scss";
import { Link } from "react-router-dom";

const SquareElement = ({
	open,
	isSingleSquare = false,
	Icon = false,
	bigIcon = false,
	bigIconNotCentered = false,
	fullIcon = false,
	title = "",
	wrapperBgColorClass,
	bgColorClass,
	text = "",
	textColorClass,
	backgroundImage = false,
	isImageHalfWidth = false,
	alignRight = false,
	hasButton = false,
	btnText = false,
	btnBgHoverClass = "bg-green",
	btnTextHoverClass = "",
	buttonLink = "",
	backgroundContain = false,
	backgroundImageBackgroundClass = "",
	backgroundImageNoPadding = false,
	buttonOnClick = null,
	noBorder = false,
	hasOverlay = false,
	hideMobile = false,
	isImage = false,
	isMultiply = false,
	mobileCol = false,
	alignSelfLeft = false,
	fullText = false,
	forceImageCenter = false,
	minThreeLines = false,
	textNoMaxWidth = false,
	btnIsDownload = false,
	btnDownloadFileName= "Download",
	btnUnderlineClass="white",
	aos_settings = null,
}) => {
	const singleSquareHtml = () => {
		return fullIcon ? (
			<div
				className={`col-6 col-md-3 m-0 
				${styles["single-square-container"]} 
				${bgColorClass} 
				${noBorder ? "" : styles["single-square-border"]}
				${hideMobile ? "hide-mobile" : ""}
				pl-md-page pr-md-page`}
			>
				<Icon width="100%" height="100%" fill="black" />
			</div>
		) : (
			<div
				className={`col-12 col-md-3 m-0 
				${styles["single-square-container"]} 
				${bgColorClass} 
				${noBorder ? "" : styles["single-square-border"]}
				${hideMobile ? "hide-mobile" : ""}`}
			>
				{backgroundImage ? (
					<div
						className={`${styles["square-image-wrapper"]} transition-custom-easing ${backgroundImageBackgroundClass}`}
						style={{
							position: "absolute",
							top: "0",
							left: "0",
							width: "100%",
							height: "100%",
							zIndex: "1",
						}}
					>
						<img
							className={`${styles["square-image"]}`}
							src={backgroundImage}
							alt=""
							style={{
								position: "absolute",
								top: "0",
								left: "0",
								margin: "0 auto",
								width: mobileCol ? "100%" : "auto",
								height: "100%",
								objectFit: "cover",
								objectPosition: "center center",
								mixBlendMode: isMultiply
									? "multiply"
									: "normal",
							}}
						/>
					</div>
				) : (
					""
				)}
				{Icon ? (
					<div
						style={{
							zIndex: "2",
							justifySelf: "flex-end",
						}}
					>
						<Icon
							className={`${styles["single-square-icon"]} 
								${bigIcon ? styles["single-square-icon-big"] : ""}
								${bigIconNotCentered ? styles["single-square-icon-big-not-centered"] : ""} `}
						/>
					</div>
				) : (
					""
				)}
				<div
					style={{
						textAlign: bigIcon ? "center" : "left",
						zIndex: "2",
						justifySelf: "flex-start",
					}}
				>
					{title !== "" ? (
						<h3
							className={`${textColorClass}`}
							style={{
								minHeight: minThreeLines ? "72px" : "0",
								maxWidth: bigIcon ? "100%" : "80%",
							}}
							{...aos_settings}
						>
							{title}
						</h3>
					) : (
						""
					)}
					{text !== "" ? (
						<p
							className={`light small ${textColorClass}`}
							style={{
								maxWidth: textNoMaxWidth ? "100%" : "80%",
								textAlign: textNoMaxWidth ? "left" : "inherit",
							}}
							{...aos_settings}
						>
							{text}
						</p>
					) : (
						""
					)}
				</div>

				{hasButton ? (
					<div
						style={{
							textAlign: bigIcon ? "center" : "left",
							zIndex: "2",
							justifySelf: "flex-end",
							marginTop: "auto",
						}}
					>
						<Link
							className={`link mt-3 mt-md-5 ${btnTextHoverClass}`}
							to={buttonLink}
							{...(btnIsDownload
								? { target: "_black", download: btnDownloadFileName }
								: { target:""})}
							{...aos_settings}
						>
							<span className="link__text">
								<span className="black">
									<span className={`${btnUnderlineClass}`}>{btnText}</span>
								</span>
							</span>
						</Link>
					</div>
				) : (
					""
				)}
			</div>
		);
	};

	const imageSquareHtml = () => {
		return (
			<div
				className={`${mobileCol ? mobileCol : "col-6"} col-md-3 m-0 p-0
				${styles["square-image-container"]}
				${bgColorClass} 
				${forceImageCenter ? styles["force-center"] : ""} 
				${noBorder ? "" : styles["single-square-border"]}
				${hideMobile ? "hide-mobile" : ""}
				${alignSelfLeft ? styles["align-self-left"] : ""}
				${mobileCol ? styles["mobile-col"] : ""}`}
				// style={{
				// 	marginRight: alignSelfLeft ? "auto" : "auto",
				// 	maxHeight: mobileCol ? "" : "25vw",
				// 	maxWidth: mobileCol ? "" : "25vw",
				// }}
			>
				<div style={{ width: "100%", height: "100%" }}>
					<img
						className={`${styles["square-image"]}`}
						src={backgroundImage}
						alt=""
						style={{
							margin: "0 auto",
							width: mobileCol ? "100%" : "auto",
							height: "100%",
							maxWidth: "100%",
							objectFit: "cover",
							objectPosition: "center",
							mixBlendMode: isMultiply ? "multiply" : "normal",
						}}
					/>
				</div>
			</div>
		);
	};

	return isSingleSquare ? (
		isImage ? (
			imageSquareHtml()
		) : (
			singleSquareHtml()
		)
	) : (
		<React.Fragment>
			<div
				className={`col-12 col-md-6 m-0 p-0 
				${hideMobile ? "hide-mobile" : ""}`}
			>
				<div className={`${styles["square-element-container"]}`}>
					<div
						className={`${styles["square-element-wrapper"]} 
						${alignRight ? styles["align-right"] : ""}
						${wrapperBgColorClass ?? ""}`}
					>
						<div
							className={`${styles["square-element-background"]} 
							${bgColorClass ?? ""} transition-custom-easing`}
						></div>
						{backgroundImage ? (
							<div
								className={`${
									styles[
										"square-element-background-image-wrapper"
									]
								}
								${isImageHalfWidth ? styles["half-width"] : ""}
								 transition-custom-easing ${backgroundImageBackgroundClass}`}
							>
								<img
									className={`${
										styles[
											"square-element-background-image"
										]
									} ${
										backgroundContain
											? styles["background-contain"]
											: ""
									} 
									${backgroundImageNoPadding ? "p-0" : ""}
									transition-custom-easing`}
									src={backgroundImage}
									alt=""
								/>
								{hasOverlay ? (
									<div
										className={`${styles["square-element-background-image-overlay"]}`}
									></div>
								) : (
									""
								)}
							</div>
						) : (
							""
						)}
						<div
							className={`${styles["square-element-text"]}
							${textColorClass ?? ""} 
							${fullText ? styles["full-text"] : ""}
							transition-custom-easing `}
							style={{}}
						>
							{fullText ? (
								<h2
									className={`${textColorClass}`}
									style={{ maxWidth: "80%" }}
									{...aos_settings}
								>
									{text}
								</h2>
							) : (
								<p
									className={`${textColorClass}`}
									style={{
										maxWidth: textNoMaxWidth
											? "100%"
											: "80%",
									}}
									{...aos_settings}
								>
									{text}
								</p>
							)}

							{hasButton
								? [
										buttonOnClick ? (
											<button key="1"
												className={`${styles["square-element-hover-btn"]} text-white btn btn-briskfil small bg-black`}
												onClick={buttonOnClick}
											>
												<div className={`btn-briskfil-background-effect ${btnBgHoverClass}`}></div>
												<span>{btnText}</span>
											</button>
										) : (
											<Link
												className="link mt-3 mt-md-5"
												to={buttonLink}
												target=""
												key="1"
												{...aos_settings}
											>
												<button
													className={`${styles["square-element-hover-btn"]} text-white btn btn-briskfil small bg-black`}
												>
													<div className={`btn-briskfil-background-effect ${btnBgHoverClass}`}></div>
													<span>{btnText}</span>
												</button>
											</Link>
										),
								  ]
								: ""}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SquareElement;
