// import React, { useState } from "react";
import React from "react";
// import { ReactComponent as ReactLogo } from "../assets/images/logo_white.svg";

// import redSmokeImage from "../assets/fotos-briskfil/redSmoke.jpg";
// import swepImage from "../assets/fotos-briskfil/swep.jpg";
// import vents1Image from "../assets/fotos-briskfil/vents1.jpg";

// import vents2Image from "../assets/fotos-briskfil/vents3.jpg";
import factory2Image from "../assets/fotos-briskfil/factory2.jpg";
import car1Image from "../assets/fotos-briskfil/car1.jpg";
import cutting3Image from "../assets/fotos-briskfil/cutting3.jpg";
// import fishtankImage from "../assets/fotos-briskfil/fishtank.jpg";
// import bottlesImage from "../assets/fotos-briskfil/bottles.jpg";
// import workers1Image from "../assets/fotos-briskfil/workers1.jpg";

import bannerImage from "../assets/fotos-briskfil/DSC06535.jpg";
import bannerImage2 from "../assets/fotos-briskfil/DSC06574.jpg";
import bannerImage3 from "../assets/fotos-briskfil/DSC06554.jpg";
// import facility2Image from "../assets/fotos-briskfil/facility2.jpg";
import labImage from "../assets/fotos-briskfil/lab.jpg";

import image2 from "../assets/fotos-briskfil/image004.jpeg";

import { ReactComponent as Filter } from "../assets/fotos-briskfil/grafismos/animacao/filtro.svg";
import { ReactComponent as Grafismo } from "../assets/fotos-briskfil/grafismos/consultoria/consultoria.svg";
// import { ReactComponent as Consultoria1Icon } from "../assets/fotos-briskfil/grafismos/consultoria/consult1.svg";
// import { ReactComponent as Consultoria2Icon } from "../assets/fotos-briskfil/grafismos/consultoria/consult2.svg";
// import { ReactComponent as Consultoria3Icon } from "../assets/fotos-briskfil/grafismos/consultoria/consult3.svg";
// import { ReactComponent as Consultoria4Icon } from "../assets/fotos-briskfil/grafismos/consultoria/consult4.svg";
import { ReactComponent as Consultoria1Icon } from "../assets/fotos-briskfil/grafismos/consultoria/serv1.svg";
import { ReactComponent as Consultoria2Icon } from "../assets/fotos-briskfil/grafismos/consultoria/serv2.svg";
import { ReactComponent as Consultoria3Icon } from "../assets/fotos-briskfil/grafismos/consultoria/serv3.svg";
import { ReactComponent as Consultoria4Icon } from "../assets/fotos-briskfil/grafismos/consultoria/serv4.svg";
// import { ReactComponent as Formas4 } from "../assets/images/formas4-2.svg";

import { ReactComponent as Ilustracao } from "../assets/fotos-briskfil/grafismos/animacao/perfil_ilustr.svg";

import { ReactComponent as Formas4 } from "../assets/fotos-briskfil/grafismos/formas/svg_anim_1.svg";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";

import SquareSection from "../components/sections/squares/SquareSection";
import SquareElement from "../components/elements/squares/SquareElement";

import TitleSection from "../components/sections/TitleSection";
import SquareTitleSection from "../components/sections/squares/TitleSection";
import GraphSection from "../components/sections/GraphSection";

// import { Link } from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import Footer from "../components/footer/Footer";

const Consultoria = ({ children, openContact, aos_settings, seo }) => {
	// const { t } = useTranslation();

	const rand = (min, max) => {
		return Math.random() * (max - min + 1) + min;
	};

	React.useEffect(() => {
		gsap.registerPlugin(MotionPathPlugin);
		// gsap.registerPlugin(MotionPathHelper);
		gsap.registerPlugin(ScrollTrigger);
		// gsap.set("#run1", { rotation: -5 });
		let timeline = new gsap.timeline({ repeat: -1 });
		//INITIAL VALUES
		let gParticles1 = document.querySelectorAll("#poeira_2 > circle");
		let gParticles2 = document.querySelectorAll("#poeira_1 > circle");
		let gSquares = document.querySelectorAll("#quadrados > rect");
		gParticles1.forEach((item) => {
			timeline.set(item, {
				translateX: `${rand(-300, -200)}px`, //"-700px",
				translateY: `${rand(-40, 40)}px`, //"-200px",
				scale: rand(0.01, 0.2),
				opacity: 0,
			});
		});

		gParticles2.forEach((item) => {
			timeline.set(item, {
				translateX: `0px`, //"-700px",
				translateY: `${rand(-10, 10)}px`, //"-200px",
				scale: rand(0.01, 0.2),
				opacity: 1,
				fill: "#B2CBD8",
			});
		});

		gSquares.forEach((item) => {
			timeline.set(item, {
				fill: "#FFFFFF",
			});
		});

		// START ANIMATION

		// timeline.to("#poeira_2 > circle", {
		// 	duration: 1,
		// 	opacity: 1,
		// });

		// timeline.to("#poeira_1 > circle", {
		// 	delay: -1,
		// 	duration: 0.5,
		// 	opacity: 1,
		// });

		timeline.to("#poeira_2 > circle", {
			// delay: -1,
			duration: 2.5,
			translateX: `0px`,
			translateY: `${rand(-10, 10)}px`,
			opacity: 1,
			stagger: 0.006,
		});

		gSquares.forEach((item, index) => {
			if (index % Math.floor(rand(4, 6)) === 0) {
				timeline.to(item, {
					delay: index === 0 ? -0.7 : -1.094,
					duration: 0.5,
					fill: "#D75439",
				});
			}
		});

		gParticles2.forEach((item, index) => {
			timeline.to(item, {
				delay: index === 0 ? 0 : -3.494,
				duration: 2.5,
				translateX: `${rand(200, 300)}px`,
				translateY: `${rand(-40, 40)}px`,
				opacity: 0,
			});
		});
		// timeline.to("#poeira_1 > circle", {
		// 	duration: 2.5,
		// 	translateX: `${rand(200, 300)}px`,
		// 	translateY: `${rand(-100, 100)}px`,
		// 	opacity: 0,
		// 	stagger: 0.006,
		// });
	}, []);
	return (
		<PageWrapper bgColorClass="bc-green" seo={seo}>
			<Banner
				isVideo={false}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-grey"
				isHalfwidth={true}
				noLogo={true}
				isGrafismo={true}
				Grafismo={Grafismo}
				grafismoBgColorClass="bg-light-blue"
				extraText={
					<h3 className="text-black banner-title-home-3">
						Ar comprimido e
						<br />
						Gases industriais
					</h3>
				}
			>
				<h1
					data-splitting
					className="text-black pl-page banner-title-home-1"
				>
					Consultoria
				</h1>

				<h3 className="text-black pl-page banner-title-home-2">
					Para empresas que antecipam o futuro.
				</h3>
			</Banner>

			<div
				className=""
				style={{
					width: "100%",
					minHeight: "100vh",
					background: "#FFFFFF",
				}}
			>
				<TitleSection
					title={<React.Fragment>Ar Comprimido</React.Fragment>}
					titleColorClass="text-black"
					smallTitle=""
					smallTitleColorClass="text-black"
					twoColumns={true}
					noMarginBottom={true}
					aos_settings={aos_settings}
				></TitleSection>

				<TitleSection
					title=""
					titleColorClass="text-black"
					smallTitle={
						<React.Fragment>
							Ar Comprimido para indústrias da nova geração
						</React.Fragment>
					}
					smallTitleColorClass="text-black"
					twoColumns={true}
					noMarginTop={true}
					aos_settings={aos_settings}
				>
					<p {...aos_settings}>
						A aplicação industrial de ar comprimido é uma exigência.
						Regulados por normas internacionais, os setores de
						indústria instruídos a seguir as normas formam uma lista
						abrangente: indústria eletrónica, automóvel, química, de
						moldes, metalomecânica de ponta, alimentar, farmacêutica
						e hospitalar.
					</p>
					<p {...aos_settings}>
						Atualmente, a indústria nacional é sensível a estas
						novas regulamentações e procura aperfeiçoar os seus
						processos a nível energético e de qualidade, onde o ar
						comprimido e os gases desempenham um papel de grande
						relevância.
					</p>
					<p {...aos_settings}>
						Além deste motivo, a exportação crescente obriga à
						implementação de sistemas rigorosos, orientados por
						padrões internacionais rigorosos.
					</p>
				</TitleSection>

				<div
					className="row w-100 bg-grey"
					style={{
						margin: "0",
					}}
				>
					<div
						className="col-12 pr-page pl-page"
						style={{
							paddingBottom: "77px",
							paddingTop: "77px",
						}}
						{...aos_settings}
					>
						<h2 className="text-grey">Serviços</h2>
						<p style={{ maxWidth: "40ch" }}>
							Conte com a nossa gama de serviços de consultoria
							especializada na área do ar comprimido e gases
						</p>
					</div>
				</div>

				<SquareSection noMinHeight={true}>
					<SquareElement
						isSingleSquare={true}
						Icon={Consultoria1Icon}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-grey"
						// bgColorClass="bg-grey bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Consulta de Mercado"
						minThreeLines={true}
						text=""
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Consultoria2Icon}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						bgColorClass="bg-grey"
						// bgColorClass="bg-grey bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Projeto"
						minThreeLines={true}
						text=""
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Consultoria3Icon}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-grey"
						// bgColorClass="bg-grey bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Acompanhamento de execução, comissionamento e certificação"
						minThreeLines={true}
						text=""
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Consultoria4Icon}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						bgColorClass="bg-grey"
						// bgColorClass="bg-grey bg-on-hover-green border-on-hover-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Manutenção e cumprimento de padrões"
						minThreeLines={true}
						text=""
						aos_settings={aos_settings}
					/>
				</SquareSection>

				{/* <SquareTitleSection
					id="consultoria-square-title-section-1"
					title={<React.Fragment>Perfil de Consumo</React.Fragment>}
					titleColorClass="text-black"
					description={
						<React.Fragment>
							Estudamos o seu perfil de consumo de ar e gases
							quanto ao seu caudal e consumo energético para
							aferir potenciais ajustes à sua central de ar e
							gases ou para projectar uma nova central de raiz.
							<br /> <br />
							Com estes dados, temos as ferramentas para auxiliar
							os nossos clientes a aferir os seus métodos de
							controlo e análise contínuos para registo permanente
							dos consumos energéticos integrados na indústria
							4.0.
						</React.Fragment>
					}
					hasButtonRight={true}
					buttonRightText="Análises"
					buttonRightLink="/analises"
					buttonBgClass="bg-light-blue outline border-black border-hover-black"
					buttonBgHoverClass="bg-black"
					buttonTextClass="text-black text-hover-light-grey"
					backgroundImage={facility2Image}
					bgColorClass="bg-light-blue"
					rightSmallTitle="Ar Comprimido"
					rightBigTitle={
						<React.Fragment>
							35 anos de
							<br />
							Experiência
						</React.Fragment>
					}
					rightDescription={
						<React.Fragment>
							Conheça também os nossos serviços de Análises ao Ar
							Comprimido!
						</React.Fragment>
					}
					hasButtonLeft={true}
					buttonText="Contacte-nos"
					buttonClick={openContact}
					aos_settings={aos_settings}
				/> */}

				<div
					className="row w-100"
					style={{
						minHeight: "25vw",
						margin: "0",
						marginTop: "10vw",
					}}
				>
					<div
						className="col-12 col-md-6 pr-page pl-page"
						style={{
							backgroundColor: "#FFFFFF",
							paddingBottom: "77px",
							paddingTop: "0",
						}}
						{...aos_settings}
					>
						<h1 className="text-grey">Perfil de Consumo</h1>
						<p style={{ maxWidth: "60ch" }}>
							Se quer ajustar a central de ar e gases da sua
							empresa, ou projetar uma nova central de raiz, o seu
							perfil de consumo é o princípio da mudança.
							<br /> <br />
							Com os dados relativos ao caudal de consumo de ar e
							gases e níveis energéticos do cliente, poderemos
							aferir os métodos de controlo e análise contínuos
							para registo permanente da utilização energética
							integrada na indústria 4.0.
						</p>

						<div>
							<button
								className="btn btn-briskfil btn-dark "
								style={{
									marginRight: "40px",
								}}
								onClick={openContact}
							>
								<div className="btn-briskfil-background-effect bg-orange"></div>
								<span>SAIBA O SEU PERFIL</span>
							</button>
						</div>
					</div>
					<div
						className="col-12 col-md-6 pr-page pl-page"
						style={{
							backgroundColor: "#FFFFFF",
							paddingBottom: "77px",
							paddingTop: "0",
						}}
					>
						<Ilustracao />
					</div>
				</div>

				<div
					className="row w-100 bg-grey"
					style={{
						minHeight: "25vw",
						margin: "0",
						paddingTop: "10vh",
					}}
				>
					<div
						className="col-12 col-md-6 pr-page pl-page"
						style={{
							paddingBottom: "77px",
							paddingTop: "0",
						}}
						{...aos_settings}
					>
						<h2 className="text-grey">
							Recuperação de <br />
							Energia Calorífica
						</h2>

						<p style={{ maxWidth: "40ch" }}>
							Nada é desperdiçado. Equacionamos as melhores opções
							para recuperação da energia calorífica dissipada
							pelos seus compressores para:
						</p>

						<div>
							<ul>
								<li>Aquecimento de Águas</li>
								<li>Vapor</li>
								<li>Ar de Aquecimento</li>
							</ul>
						</div>
						{/* <div>
							<button
								className="btn btn-briskfil text-black outline border-black btn-briskfil-mr default-cursor"
								style={{
									marginRight: "40px",
								}}
							>
								<span>Aquecimento de Águas</span>
							</button>
							<button
								className="btn btn-briskfil text-black outline border-black btn-briskfil-mr btn-briskfil-no-min-width default-cursor"
								style={{
									marginRight: "40px",
									minWidth: 0,
								}}
							>
								<span>Vapor</span>
							</button>
							<button
								className="btn btn-briskfil text-black outline border-black default-cursor"
								style={{
									marginRight: "20px",
								}}
							>
								<span>Ar de Aquecimento</span>
							</button>
						</div> */}
					</div>
					{/* <SquareElement
						hideMobile={true}
						isSingleSquare={true}
						alignRight={true}
						wrapperBgColorClass={false}
						bgColorClass="bg-black"
						textColorClass="text-light-grey"
						isImage={true}
						backgroundImage={redSmokeImage}
						hasOverlay={false}
						noBorder={true}
					/> */}
				</div>

				<GraphSection id="consultoria-graph-1"></GraphSection>

				{/* <SquareSection alignRight={true} hideMobile={true}>
					<SquareElement
						isSingleSquare={true}
						bgColorClass="bg-grey"
						forceImageCenter={true}
						hideMobile={true}
						isImage={true}
						backgroundImage={swepImage}
						isMultiply={true}
						noBorder={true}
					/>
					<SquareElement
						isSingleSquare={true}
						hideMobile={true}
						alignRight={true}
						wrapperBgColorClass={false}
						bgColorClass="bg-black"
						textColorClass="text-light-grey"
						isImage={true}
						backgroundImage={vents1Image}
						hasOverlay={false}
						noBorder={true}
					/>
				</SquareSection>

				<SquareSection alignRight={true} hideDesktop={true}>
					<SquareElement
						mobileCol="col-4"
						isSingleSquare={true}
						bgColorClass="bg-grey"
						forceImageCenter={true}
						isImage={true}
						backgroundImage={swepImage}
						isMultiply={true}
						noBorder={true}
					/>
					<SquareElement
						mobileCol="col-4"
						isSingleSquare={true}
						alignRight={true}
						wrapperBgColorClass={false}
						bgColorClass="bg-black"
						textColorClass="text-light-grey"
						isImage={true}
						backgroundImage={vents1Image}
						hasOverlay={false}
						noBorder={true}
					/>

					<SquareElement
						mobileCol="col-4"
						isSingleSquare={true}
						alignRight={true}
						wrapperBgColorClass={false}
						bgColorClass="bg-black"
						textColorClass="text-light-grey"
						isImage={true}
						backgroundImage={redSmokeImage}
						hasOverlay={false}
						noBorder={true}
					/>
				</SquareSection> */}

				<SquareTitleSection
					id="consultoria-square-title-section-2"
					title={
						<React.Fragment>
							A Inovação é feita <br />
							de Ar e Planeamento
						</React.Fragment>
					}
					titleColorClass="text-black"
					description={
						<React.Fragment>
							O tempo de exploração e utilização de uma central de
							ar e gases nunca é inferior a 10 anos. Trata-se de
							uma aquisição que deve ser bem ponderada e estudada
							tendo em conta todos os fatores de custo energético,
							de manutenção, de aquisição; e parâmetros técnicos
							de construção, qualidade, localização da instalação,
							entre outros.
							<br />
							<br />
							Se pretende beneficiar de um apoio qualificado com
							mais décadas de experiência e aceder a informação
							mais atualizada nesta fase de avaliação, confie na
							Briskfil.
						</React.Fragment>
					}
					hasButtonRight={false}
					backgroundImage={bannerImage}
					bgColorClass=""
					rightSmallTitle=""
					rightBigTitle=""
					rightDescription=""
					hasButtonLeft={true}
					buttonText="PRETENDO UMA AVALIAÇÃO"
					buttonLeftClick={openContact}
					aos_settings={aos_settings}
				/>

				<SquareSection alignRight={true} hideMobile={true}>
					<SquareElement
						alignSelfLeft={true}
						isSingleSquare={true}
						bgColorClass="bg-grey"
						hideMobile={true}
						isImage={true}
						backgroundImage={factory2Image}
						isMultiply={true}
						noBorder={true}
					/>
					<SquareElement
						isSingleSquare={true}
						bgColorClass="bg-grey"
						hideMobile={true}
						isImage={true}
						backgroundImage={bannerImage2}
						isMultiply={true}
						noBorder={true}
					/>
					<SquareElement
						isSingleSquare={true}
						hideMobile={true}
						alignRight={true}
						wrapperBgColorClass={false}
						bgColorClass="bg-black"
						textColorClass="text-light-grey"
						isImage={true}
						backgroundImage={car1Image}
						hasOverlay={false}
						noBorder={true}
					/>
				</SquareSection>
				<SquareSection alignRight={false} hideMobile={true}>
					<SquareElement
						isSingleSquare={true}
						bgColorClass="bg-grey"
						hideMobile={true}
						isImage={true}
						backgroundImage={image2}
						isMultiply={false}
						noBorder={true}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={Formas4}
						fullIcon={true}
						alignRight={true}
						noBorder={true}
						bgColorClass="bg-light-blue"
					/>
				</SquareSection>

				<div
					className="row w-100"
					style={{
						margin: "0",
					}}
				>
					<SquareElement
						isSingleSquare={true}
						bgColorClass="bg-grey"
						hideMobile={true}
						isImage={true}
						backgroundImage={cutting3Image}
						isMultiply={false}
						noBorder={true}
					/>
					<SquareElement
						isSingleSquare={true}
						bgColorClass="bg-grey"
						hideMobile={true}
						isImage={true}
						backgroundImage={bannerImage3}
						isMultiply={false}
						noBorder={true}
					/>
					<div
						className="col-12 col-md-6 pr-page pl-page pb-page"
						style={{
							backgroundColor: "#FFFFFF",
							paddingBottom: "77px",
							paddingTop: "77px",
						}}
					>
						<h2 className="text-grey" {...aos_settings}>
							Produção Interna de Azoto e Oxigénio
						</h2>
						<p {...aos_settings}>
							Porquê comprar azoto e oxigénio, quando estes gases
							se encontram disponíveis na atmosfera?
						</p>
						<p {...aos_settings}>
							Aconselhe-se junto da Briskfil para saber qual o
							melhor processo. Existem diferentes tipos de
							geradores, desde PSA, VPSA, membrana, alta, média ou
							baixa pressão, podendo-se optar também por uma
							combinação entre sistemas para melhor economia na
							produção. Obtenha mais informação, contacte os
							nossos serviços.
						</p>
						<button
							{...aos_settings}
							className={`btn btn-briskfil text-light-grey bg-black mr mb-5`}
							onClick={openContact}
						>
							<div
								className={`btn-briskfil-background-effect bg-orange`}
							></div>
							<span>Contacte-nos</span>
						</button>
					</div>
				</div>

				<TitleSection
					title="Purificar e Esterilizar o Ar"
					titleColorClass="text-black"
					smallTitle={
						<React.Fragment>
							O ar comprimido não é tão limpo quanto parece.  
						</React.Fragment>
					}
					smallTitleColorClass="text-black"
					twoColumns={true}
					noMarginTop={false}
					customContent={
						<React.Fragment>
							<p {...aos_settings}>
								Este contém contaminantes microbianos
								potencialmente nocivos para a saúde, bem como
								humidade, partículas e vapor de óleo. A
								introdução de poluentes e contaminantes em
								algumas indústrias é muito arriscada, mas saber
								como integrar o tratamento de ar numa instalação
								ajudará a evitar esse risco potencial.
							</p>
							<p {...aos_settings}>
								Conte com a assistência da Briskfil para cumprir
								com os padrões internacionais de segurança.
							</p>
							<button
								className={`btn btn-briskfil text-light-grey bg-black mr mb-5`}
								onClick={openContact}
								{...aos_settings}
							>
								<div
									className={`btn-briskfil-background-effect bg-orange`}
								></div>
								<span>QUERO MAIS INFORMAÇÃO</span>
							</button>
						</React.Fragment>
					}
					noPaddingRightCol={true}
					aos_settings={aos_settings}
					noAOSRight={true}
				>
					<div className="w-100 h-100 p-5 d-flex justify-content-center align-items-center mt-5 mt-md-0">
						<Filter
							style={{
								width: "100%",
								maxWidth: "90%",
								height: "100%",
								maxHeight: "50vh",
							}}
						/>
					</div>
				</TitleSection>

				<TitleSection
					title="Ar Medicinal"
					titleColorClass="text-black"
					smallTitle=""
					smallTitleColorClass="text-black"
					twoColumns={true}
					noMarginTop={true}
					customContent={
						<React.Fragment>
							<p {...aos_settings}>
								A Organização Internacional de Normalização
								(ISO), a NFPA (líder mundial na proteção contra
								incêndio) e a Farmacopeia determinam que o ar
								comprimido usado para o fornecimento de ar
								medicinal nunca deve entrar em contacto com
								contaminantes.
							</p>
							<p {...aos_settings}>
								Mas com a Briskfil a projetar a sua central de
								produção de ar medicinal, o cliente terá a
								redundância necessária e monitorização contínua,
								com o objetivo de controlar os valores de
								poluentes aceitáveis.
							</p>
							<button
								className={`btn btn-briskfil text-light-grey bg-black mr mb-5`}
								onClick={openContact}
								{...aos_settings}
							>
								<div
									className={`btn-briskfil-background-effect bg-orange`}
								></div>
								<span>QUERO SABER COMO</span>
							</button>
						</React.Fragment>
					}
					noPaddingRightCol={true}
					isReverse={true}
					aos_settings={aos_settings}
					noAOSRight={true}
				>
					<div className="w-100 h-100 m-0 p-0">
						<img
							className=""
							src={labImage}
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
								objectPosition: "center center",
							}}
							alt=""
						></img>
					</div>
				</TitleSection>

				<Footer marginTop={false} />
			</div>
		</PageWrapper>
	);
};

export default Consultoria;
