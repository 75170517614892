import React from "react";
import Slider from "react-slick";
import { gsap } from "gsap";
import "./SimpleSlider.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SimpleSlider = React.forwardRef((
	{
	children,
	refToOtherSlider = null,
	// ref=null,
	isFade = false,
	images = null,
	hasVideo = false,
	isText = false,
}, ref) => {
	// const {ref, refToOtherSlider} = refs;
	const settings = {
		dots: false,
		infinite: true,
		arrows: false,
		draggable: false,
		speed: 1000,
		fade: isFade,

		autoplay: isText ? true : false,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: refToOtherSlider.current ? refToOtherSlider.current : '',
		beforeChange: (current, next) => {
			console.log("current", current)
			console.log("next", next)
			if (isFade && !hasVideo) {
				gsap.set(
					document.querySelector(`#banner-text-slider-slide-${next} .banner-title-home-1`),
					{
						autoAlpha: 0,
						transform: "translateY(-30px)",
					}
				);

				gsap.set(
					document.querySelector(`#banner-text-slider-slide-${next} .banner-title-home-2`),
					{
						autoAlpha: 0,
						transform: "translateY(-30px)",
					}
				);

				gsap.set(
					document.querySelector(`#banner-text-slider-slide-${next} .banner-button-home`),
					{
						autoAlpha: 0,
						transform: "translateY(-30px)",
					}
				);

				// CURRENT
				gsap.set(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-title-home-1`),
					{
						autoAlpha: 1,
						transform: "none",
					}
				);

				gsap.set(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-title-home-2`),
					{
						autoAlpha: 1,
						transform: "none",
					}
				);

				gsap.set(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-button-home`),
					{
						autoAlpha: 1,
						transform: "none",
					}
				);

				gsap.to(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-button-home`),
					{
						duration: 0.4,
						autoAlpha: 0,
						transform: "translateY(-30px)",
					}
				);

				gsap.to(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-title-home-1`),
					{
						duration: 0.4,
						autoAlpha: 0,
						transform: "translateY(-30px)",
					}
				);

				gsap.to(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-title-home-2`),
					{
						duration: 0.4,
						autoAlpha: 0,
						transform: "translateY(-30px)",
					}
				);
			}
		},
		afterChange: (current, next) => {
			if (isFade && !hasVideo) {
				gsap.set(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-title-home-1`),
					{
						autoAlpha: 0,
						transform: "translateY(-30px)",
					}
				);

				gsap.set(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-title-home-2`),
					{
						autoAlpha: 0,
						transform: "translateY(-30px)",
					}
				);

				gsap.set(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-button-home`),
					{
						autoAlpha: 0,
						transform: "translateY(-30px)",
					}
				);

				gsap.to(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-button-home`),
					{
						duration: 0.5,
						autoAlpha: 1,
						transform: "none",
					}
				);

				gsap.to(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-title-home-1`),
					{
						duration: 0.5,
						autoAlpha: 1,
						transform: "none",
					}
				);

				gsap.to(
					document.querySelector(`#banner-text-slider-slide-${current} .banner-title-home-2`),
					{
						duration: 0.5,
						autoAlpha: 1,
						transform: "none",
					}
				);
			}
		},
		onInit: () => {
			ref.current?.slickPause();
			setTimeout(() => {
				ref.current?.slickPlay();
			}, 5000);
		},
	};

	const renderSlides = () => {
		if(!isText){
			if (hasVideo) {
				return images
					? images.map((item, i) =>
							item.type === "img" ? (
								<div className="h-100 w-100" key={i}>
									<img
										src={item.src}
										className="img-remove-blend h-100 w-100"
										style={{
											objectFit: "cover",
											objectPosition: "center",
										}}
										alt=""
									/>
								</div>
							) : (
								<div className="h-100" key={i}>
									<video
										// src={item.src}
										className="img-remove-blend h-100 "
										style={{
											objectFit: "cover",
											objectPosition: "center",
										}}
										autoPlay
										muted
										loop
										alt=""
									>
										<source src={item.src} type="video/mp4" />
									</video>
								</div>
							)
					)
					: "";
			} else {
				return images
					? images.map((img, i) => (
							<div className="h-100" key={i}>
								<img
									src={img}
									className="img-remove-blend h-100 "
									style={{
										objectFit: "cover",
										objectPosition: "center",
									}}
									alt=""
								/>
							</div>
					))
					: "";
			}
		}
		return null
	};
	return (
		<React.Fragment>
			<div className="container-img-effect w-100 h-100">
				<div className="img-reveal w-100 h-100">
					<Slider
						className="slideshow--custom-arrow image-left-to-right w-100 h-100"
						ref={ref}
						{...settings}
					>
						{renderSlides()}
						{children}
					</Slider>
				</div>
			</div>
		</React.Fragment>
	);
});

export default SimpleSlider;
