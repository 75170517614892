import React from "react";
import { Link } from "react-router-dom";
import styles from "./TitleSection.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TitleSection = ({
	children,
	id,
	title,
	titleColorClass,
	description = false,
	backgroundImage,
	hasButtonLeft = true,
	hasButtonRight = true,
	buttonLink,
	buttonText,
	rightBigTitle,
	rightSmallTitle,
	rightDescription,
	bgColorClass,
	buttonTextClass,
	buttonBgClass,
	buttonBgHoverClass,
	buttonRightText = "falta texto",
	buttonLeftLink = "",
	buttonLeftClick = null,
	buttonRightLink = "",
	hasOverlay = true,
	aos_settings = null,
}) => {
	const backgroundRef = React.useRef(null);
	const titleRef = React.useRef(null);
	const descriptionRef = React.useRef(null);
	const buttonRef = React.useRef(null);

	React.useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: backgroundRef.current,
				// toggleActions: "restart none none reset",
			},
		});

		tl.set(backgroundRef.current, { height: "0" });
		tl.set(titleRef.current, { opacity: "0", translateX: "-60px" });
		tl.set(descriptionRef.current, { opacity: "0", translateX: "-60px" });
		tl.set(buttonRef.current, { opacity: "0", translateX: "-60px" });

		tl.to(backgroundRef.current, {
			duration: 0.7,
			height: "100%",
			ease: "Circ.easeIn",
		});
		tl.to(titleRef.current, {
			duration: 1,
			opacity: 1,
			translateX: 0,
		});
		tl.to(descriptionRef.current, {
			duration: 1,
			delay: -0.7,
			opacity: 1,
			translateX: 0,
		});
		tl.to(buttonRef.current, {
			duration: 1,
			delay: -0.5,
			opacity: 1,
			translateX: 0,
		});
	}, []);
	return (
		<React.Fragment>
			<div
				className="row w-100"
				style={{
					margin: "0",
					marginTop: "15vh",
				}}
			>
				<div
					className="col-12 col-md-6 pr-page pl-page"
					style={{
						paddingBottom: "80px",
						paddingTop: "0",
					}}
				>
					<div className="h-100 d-flex flex-column justify-content-center">
						<h1
							className={`${titleColorClass}`}
							data-splitting
							{...aos_settings}
						>
							{title}
						</h1>
						<p
							className="mb-5"
							style={{
								maxWidth: "60ch",
							}}
							{...aos_settings}
						>
							{description}
						</p>
						{hasButtonLeft
							? [
									buttonLeftClick ? (
										<button
											key="1"
											className="btn btn-briskfil btn-dark"
											onClick={buttonLeftClick}
											{...aos_settings}
											style={{alignSelf:"start"}}
										>
											<div className="btn-briskfil-background-effect bg-orange"></div>
											<span>{buttonText}</span>
										</button>
									) : (
										<Link
											to={buttonLeftLink}
											key="1"
											{...aos_settings}
										>
											<button
												className="btn btn-briskfil btn-dark"
												onClick={buttonLeftClick}
											>
												<div className="btn-briskfil-background-effect bg-orange"></div>
												<span>{buttonText}</span>
											</button>
										</Link>
									),
							  ]
							: ""}
					</div>
				</div>
				<div
					className={`col-12 col-md-6 m-0 p-0 ${styles["right-col"]}`}
				>
					<div
						className={`${styles["background-image-wrapper"]} m-0 p-0 h-100 w-100`}
					>
						<img
							className={`${styles["background-image"]}`}
							src={backgroundImage}
							alt=""
						/>
						{hasOverlay ? (
							<div
								className={`${styles["background-image-overlay"]}`}
							></div>
						) : (
							""
						)}
					</div>

					<div className={`${styles["text-container"]} w-100`}>
						<div
							ref={backgroundRef}
							className={`${styles["background-solid"]} ${bgColorClass} w-100`}
						></div>
						<div className={`${styles["text-wrapper"]} w-100`}>
							{/* <p
								className={`text-light-grey ${styles["custom-margin"]}`}
							>
								{rightSmallTitle}
							</p> */}
							<h3 ref={titleRef} className="text-black">
								{rightBigTitle}
							</h3>

							<p ref={descriptionRef}>{rightDescription}</p>

							{hasButtonRight ? (
								<Link ref={buttonRef} to={buttonRightLink}>
									<button
										className={`btn btn-briskfil ${buttonTextClass} ${buttonBgClass} hide-mobile`}
									>
										<div
											className={`btn-briskfil-background-effect ${buttonBgHoverClass}`}
										></div>
										<span>{buttonRightText}</span>
									</button>
								</Link>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default TitleSection;
