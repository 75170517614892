// import React, { useState } from "react";
import React from "react";
// import { ReactComponent as ReactLogo } from "../assets/images/logo_white.svg";

import { ReactComponent as Grafismo } from "../assets/fotos-briskfil/grafismos/aplicacoes/aplicacoes.svg";
import { ReactComponent as AlimentarIcon } from "../assets/fotos-briskfil/grafismos/Icones/alimentar.svg";
import { ReactComponent as FarmaceuticaIcon } from "../assets/fotos-briskfil/grafismos/Icones/farmaceutica.svg";
import { ReactComponent as TextilIcon } from "../assets/fotos-briskfil/grafismos/Icones/textil.svg";
import { ReactComponent as MetalomecanicaIcon } from "../assets/fotos-briskfil/grafismos/Icones/metalomecanica.svg";
import { ReactComponent as QuimicaIcon } from "../assets/fotos-briskfil/grafismos/Icones/quimica.svg";
import { ReactComponent as MedicinaIcon } from "../assets/fotos-briskfil/grafismos/Icones/medicina.svg";
import { ReactComponent as AguaIcon } from "../assets/fotos-briskfil/grafismos/Icones/agua.svg";
import { ReactComponent as EletronicaIcon } from "../assets/fotos-briskfil/grafismos/Icones/electronica.svg";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";

import SquareSection from "../components/sections/squares/SquareSection";
import SquareElement from "../components/elements/squares/SquareElement";

// import { useTranslation } from "react-i18next";
import TitleSection from "../components/sections/TitleSection";
// import { Link } from "react-router-dom";
import Footer from "../components/footer/Footer";

// const Grafismo = () => {
// 	return <img src={grafismo} style={{ mixBlendMode: "multiply" }} alt="" />;
// };

const Aplicacoes = ({ children, openContact, aos_settings, seo }) => {
	// const { t } = useTranslation();

	return (
		<PageWrapper bgColorClass="bc-green" seo={seo}>
			<Banner
				isVideo={false}
				// bannerImage={bannerImage}
				videoSrc={false}
				logoFill="white"
				bgColorClass="bg-grey"
				isHalfwidth={true}
				noLogo={true}
				isGrafismo={true}
				Grafismo={Grafismo}
				grafismoBgColorClass="bg-blue"
				extraText={
					<h3 className="text-black banner-title-home-3">
						Ar comprimido e
						<br />
						Gases industriais
					</h3>
				}
			>
				<h1
					data
					splitting
					className="text-black pl-page banner-title-home-1"
				>
					Aplicações e
					<br />
					Case Studies
				</h1>

				<h3 className="text-black pl-page banner-title-home-2">
					Para empresas que antecipam o futuro.
				</h3>
			</Banner>

			<div
				className=""
				style={{
					width: "100%",
					minHeight: "100vh",
					background: "#FFFFFF",
				}}
			>
				<TitleSection
					title={
						<React.Fragment>
							Aplicações e
							<br />
							Case Studies
						</React.Fragment>
					}
					titleColorClass="text-black"
					smallTitle="Gás e ar diferentes para cada tipo de produto e utilização."
					smallTitleColorClass="text-black mb-5"
					twoColumns={true}
					hasButton={true}
					buttonText="Contacte-nos"
					buttonTextClass="text-light-grey"
					buttonBgClass="bg-black"
					buttonBgHoverClass="bg-orange"
					aos_settings={aos_settings}
					button1Click={openContact}
					titleMarginTop={false}
				>
					<p {...aos_settings}>
						Existem várias aplicações para gases industriais tais
						como azoto, oxigénio, CO<sub>2</sub>, ar comprimido
						avançado, ar sem vestígios de óleo (oil-free) e
						desidratado e estéril, ar apto para alimentos
						(food-grade), ar respirável, entre outros. No entanto,
						as suas características de qualidade, caudal, pressão e
						consumo energético só serão atingidas através de métodos
						e tecnologias bem definidas.
					</p>
					<p {...aos_settings}>
						Para tal, é necessário conhecer as diferentes ofertas do
						mercado e combiná-las de forma eficiente para fazer
						frente aos elevados padrões que o produto exige.
					</p>
				</TitleSection>

				<TitleSection
					title={<React.Fragment>Indústrias</React.Fragment>}
					smallTitle={
						<React.Fragment>
							Os nossos técnicos especializados estão preparados
							para trabalhar com os seguintes tipos de indústria:
						</React.Fragment>
					}
					titleColorClass="text-black"
					twoColumns={true}
					bgColorClass="bg-grey"
					aos_settings={aos_settings}
				></TitleSection>

				<SquareSection bgColorClass="bg-grey">
					<SquareElement
						isSingleSquare={true}
						Icon={AlimentarIcon}
						bigIcon={true}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-grey bg-on-hover-light-green border-on-hover-light-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Alimentar"
						text="Eliminação de hidrocarbonetos (ISO 8573.1:2010). Evita a deterioração e a oxidação do produto. A 
						produção interna reduz a pegada de carbono e despesas. "
						textNoMaxWidth={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={FarmaceuticaIcon}
						bigIcon={true}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						bgColorClass="bg-grey bg-on-hover-light-green border-on-hover-light-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Farmacêutica"
						text="Garantia de um produto final livre de partículas, microrganismos, água e óleo. Visa respeitar as normas 
						de Farmacopeia para ar medicinal ou de clean rooms. "
						textNoMaxWidth={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={TextilIcon}
						bigIcon={true}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-grey bg-on-hover-light-green border-on-hover-light-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Têxtil"
						text="Varia entre ar generalista e o qualificado para sistemas robóticos, serigrafia, air jet loom, etc. Pressupõe 
						eficiência energética e recuperação de energia calorífica."
						textNoMaxWidth={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={MetalomecanicaIcon}
						bigIcon={true}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						bgColorClass="bg-grey bg-on-hover-light-green border-on-hover-light-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Metalomecânica"
						text="Os processos deste sector são muito abrangentes, embora se destaque aplicações para pinturas 
						eletrostáticas, lacagens, robótica industrial, química, entre outras."
						textNoMaxWidth={true}
						aos_settings={aos_settings}
					/>
				</SquareSection>
				<SquareSection>
					<SquareElement
						isSingleSquare={true}
						Icon={QuimicaIcon}
						bigIcon={true}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-grey bg-on-hover-light-green border-on-hover-light-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Química"
						text="Ar de elevados padrões de qualidade, principalmente na instrumentação. Todas as classes estão em 
						aberto para este sector."
						textNoMaxWidth={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={AguaIcon}
						bigIcon={true}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						bgColorClass="bg-grey bg-on-hover-light-green border-on-hover-light-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Águas"
						text="A ionização de águas requer fluidos de padrão exigente, seja de ar sob pressão para produção de 
						oxigénio, seja para comando e movimento."
						textNoMaxWidth={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={EletronicaIcon}
						bigIcon={true}
						alignRight={false}
						wrapperBgColorClass={false}
						bgColorClass="bg-grey bg-on-hover-light-green border-on-hover-light-green"
						textColorClass="text-black"
						backgroundContain={true}
						isImageHalfWidth={true}
						title="Eletrónica"
						text="Ar de elevado padrão, incluindo frequentemente a classe 1.1.1 segundo a norma ISO 8573.1: 2010."
						textNoMaxWidth={true}
						aos_settings={aos_settings}
					/>
					<SquareElement
						isSingleSquare={true}
						Icon={MedicinaIcon}
						bigIcon={true}
						alignRight={true}
						wrapperBgColorClass="bg-white"
						bgColorClass="bg-grey bg-on-hover-light-green border-on-hover-light-green"
						textColorClass="text-black"
						backgroundImage={false}
						title="Médica / Hospitalar"
						text="Segue a Farmacopeia Europeia, que por sua vez reporta as classes recomendadas pela ISO 8573.1:2010. 
						Engloba o Ar Zero ou ar sintético, aplicados nos blocos operatórios. "
						textNoMaxWidth={true}
						aos_settings={aos_settings}
					/>
				</SquareSection>

				{/* <TitleSection
					title={<React.Fragment>Case Studies</React.Fragment>}
					titleColorClass="text-black"
					twoColumns={true}
					aos_settings={aos_settings}
				></TitleSection> */}

				<Footer marginTop={false} />
			</div>
		</PageWrapper>
	);
};

export default Aplicacoes;
